























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import Modals from '@/constants/Modals';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import Event from '@/typings/backend/jsonApiTranslations/event';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import EventsStoreActions from '@/constants/store/modules/events/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import EventDialogPeopleRow from './CreateEventDialog/EventDialogPeopleRow.vue';

const eventStoreModule = namespace('event');

@Component({
  components: {
    EventDialogPeopleRow,
  },
})
export default class ManageEventInvitees extends Vue {
  @eventStoreModule.Getter(EventsStoreGetters.MODAL_IS_OPEN)
  isModalOpen!: (modal: Modals) => boolean;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_MODAL]: (
    data: { modal: Modals, value: boolean },
  ) => void;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_EVENT]: (
    event: Event,
  ) => void;

  @eventStoreModule.Getter(EventsStoreGetters.GET_ACTIVE_EVENT)
  activeEvent!: Event;

  @eventStoreModule.Action[EventsStoreActions.EDIT_EVENT]: (
    { event, params }: { event: Event, params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  public readonly modal = Object.freeze(Modals.ManageInviteesEvent);

  public loaders = {
    isSaving: false,
  }

  get isOpen() {
    return this.isModalOpen(this.modal);
  }

  get activityGroupsGuests() {
    return this.activeEvent.activityGroupGuests ? this.activeEvent.activityGroupGuests : [];
  }

  set activityGroupsGuests(activityGroupGuests: ActivityGroup[]) {
    this[EventsStoreMutations.SET_ACTIVE_EVENT]({
      ...this.activeEvent,
      activityGroupGuests,
    });
  }

  get attendeesGuests() {
    return this.activeEvent.attendeeGuests ? this.activeEvent.attendeeGuests : [];
  }

  set attendeesGuests(attendeeGuests: Attendee[]) {
    this[EventsStoreMutations.SET_ACTIVE_EVENT]({
      ...this.activeEvent,
      attendeeGuests,
    });
  }

  public async onSave() {
    try {
      this.loaders.isSaving = true;
      await this[EventsStoreActions.EDIT_EVENT]({
        event: this.activeEvent,
        params: { include: ['definition', 'activityGroupGuests', 'attendeeGuests'] },
      });
      this.loaders.isSaving = false;
      this.closeModal();
    } catch (e) {
      this.loaders.isSaving = false;
    }
  }

  private closeModal() {
    this[EventsStoreMutations.SET_MODAL]({
      modal: this.modal,
      value: false,
    });
  }
}
