import cloneDeep from 'lodash.clonedeep';

// eslint-disable-next-line arrow-parens
const EnumerationForHumans = <T extends {}>(enumeration: T): T => {
  const cEnumeration = cloneDeep(enumeration);

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const entry in cEnumeration) {
    // @ts-ignore
    const isNumber = parseInt(entry, 10) >= 0;

    if (isNumber) {
      // @ts-ignore
      cEnumeration[entry] = (
        (<unknown>cEnumeration[entry] as string)
          .replace(/([A-Z])/g, ' $1')
          .trim()
      );
    }
  }

  return cEnumeration;
};

export default EnumerationForHumans;
