




























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { AxiosResponse } from 'axios';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import ActivityGroupsStoreMutations from '@/constants/store/modules/activityGroups/mutations';

const gymsStoreModule = namespace('gyms');
const activityGroupStoreModule = namespace('activityGroups');

@Component
export default class ScheduleGroupViewFilters extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @activityGroupStoreModule.Action [
    ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS
  ]: Function;

  @activityGroupStoreModule.Mutation[ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS]: (
    activityGroups: ActivityGroup[]
  ) => void;

  public readonly TIMEOUT_INPUT: number = 800;

  public inputTimeoutHandler: number | undefined = -1;

  public filters: {[key: string]: string | number | string[] | number[]} = {
    activityGroup: '',
    gym: '',
  };

  public activityGroups: ActivityGroup[] = [];

  public created(): void {
    const params: JsonApiConfiguration = {
      filter: {
        gym: this.activeGym.id,
      },
    };

    this[ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]({ params })
      .then((response: AxiosResponse<ActivityGroup[]>) => {
        this.activityGroups = response.data;
      });
  }

  public destroyed(): void {
    this[ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS]([]);
  }

  public handleNameInput(input: string, filterName: string): void {
    clearTimeout(this.inputTimeoutHandler);

    this.inputTimeoutHandler = setTimeout(() => {
      if (typeof input === 'undefined') {
        const filters = { ...this.filters };
        delete filters[filterName];
        this.filters = filters;
      } else {
        this.filters[filterName] = input;
      }


      this.$emit('filters:input', this.filters);
    }, this.TIMEOUT_INPUT);
  }
}
