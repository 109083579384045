import Users from '@/views/attendees/users/Attendees.vue';
import UsersNew from '@/views/attendees/AttendeesNew.vue';
import AttendeeView from '@/views/attendees/AttendeeView.vue';

export default [
  {
    path: 'list',
    name: 'usersList',
    component: Users,
    meta: {
      hasLeftFilters: true,
    },
  },
  {
    path: 'new',
    name: 'usersNew',
    component: UsersNew,
  },
  {
    path: ':attendeeId',
    name: 'attendeeView',
    component: AttendeeView,
  },
];
