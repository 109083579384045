

























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ThirdPartyPayingCard from '@/components/thirdPartyPaying/ThirdPartyPayingCard.vue';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';
import ThirdPartyPayingApiService from '@/services/api/ThirdPartyPayingApiService';
import filters from './configuration/Filters';

const thirdPartyPayingStoreModule = namespace('thirdPartyPaying');
const gymStoreModule = namespace('gyms');

@Component({
  components: {
    ThirdPartyPayingCard,
    ListableItems,
  },
})
export default class ThirdPartyPayingListView extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public listableItem = Object.freeze(ListableItem);

  public filters = filters;

  public filtersRendererType = Object.freeze(FilterRenderers);

  public service = Object.freeze(ThirdPartyPayingApiService);

  get params(): JsonApiConfiguration {
    return {
      include: ['createdBy', 'contacts', 'gym'],
      filter: {
        gym: this.activeGym.id,
      },
    };
  }
}
