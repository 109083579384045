import BaseRepository from '@/repository/BaseRepository';
import NewThirdPartyPayingContact from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPayingContact';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import { AxiosResponse } from 'axios';
import ThirdPartyPayingContact from '@/typings/backend/jsonApiTranslations/thirdPartyPayingContact';
import ParametersMap from '@/typings/repository/parametersMap';
import PhoneNumberRepository from '@/repository/PhoneNumber';
import PhoneNumber from '@/typings/nativeEntities/phoneNumbers/PhoneNumber';
import ThirdPartyPayingContactService from '@/services/api/ThirdPartyPayingContactService';

class ThirdPartyPayingContactRepository extends BaseRepository {
  private phoneNumberRepository: PhoneNumberRepository;

  constructor(params?: ParametersMap) {
    super(params);

    this.phoneNumberRepository = new PhoneNumberRepository(params);
  }

  async create(
    { contact, thirdPartyPaying }: {
      contact: NewThirdPartyPayingContact,
      thirdPartyPaying: ThirdPartyPaying,
    },
  ): Promise<AxiosResponse<ThirdPartyPayingContact>> {
    const response = (
      await ThirdPartyPayingContactService
        .postThirdPartyPayingContact(
          contact,
          thirdPartyPaying,
          this.getParameters('createThirdPartyPayingContact'),
        )
    );

    const phoneNumber: PhoneNumber = {
      number: contact.phoneNumber,
      country: contact.country!,
      owner: {
        type: 'paying-group-contacts',
        id: response.data.id,
      },
    };

    const phoneNumberResponse = (
      await this.phoneNumberRepository.create({ phoneNumber, country: contact.country! })
    );

    // We need to inject the phone number created after
    // into the response of thirdPartyPayingContact
    response.data.contactPhone = phoneNumberResponse.data;

    return response;
  }

  async edit(
    { thirdPartyPayingContact }: { thirdPartyPayingContact: ThirdPartyPayingContact },
  ): Promise<AxiosResponse<ThirdPartyPayingContact>> {
    await this.phoneNumberRepository.edit({
      phoneNumber: thirdPartyPayingContact.contactPhone,
    });

    return ThirdPartyPayingContactService
      .editThirdPartyPayingContact(
        thirdPartyPayingContact,
        this.getParameters('editThirdPartyPayingContact'),
      );
  }
}

export default ThirdPartyPayingContactRepository;
