import ErrorBag from '@/typings/errors/ErrorBag';

abstract class BaseErrorHandler<ERRORS_TYPE> {
  protected errorsBag: ErrorBag[];

  public constructor(errors: ERRORS_TYPE) {
    this.errorsBag = this.normalizeError(errors);
  }

  protected abstract normalizeError(errors: ERRORS_TYPE): ErrorBag[];

  public abstract treatErrors(bailOnFirst?: boolean): void;
}

export default BaseErrorHandler;
