
























import { Component, Vue } from 'vue-property-decorator';
import ViewInformationRow from '@/components/attendee/attendeeRead/informationRow/ViewInformationRow.vue';
import EditInformationRow from '@/components/attendee/attendeeRead/informationRow/EditInformationRow.vue';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import SendMessage from '@/components/users/SendMessage.vue';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';

const gymStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    SendMessage,
    ViewInformationRow,
    EditInformationRow,
  },
})
export default class InformationRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE)
  activeAttendee!: Attendee;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_IS_EDITING_ATTENDEE]: (
    isEditing: boolean
  ) => void;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.IS_EDITING_ATTENDEE)
  isEditingStore!: boolean;

  public isEditing = false;

  public isSendingMessage = false;

  get isEditingGetter() {
    return this.isEditingStore;
  }

  set isEditingGetter(value: boolean) {
    this.isEditing = value;
    this[AttendeeStoreMutations.SET_IS_EDITING_ATTENDEE](value);
  }
}
