import CountryStoreState from '@/typings/storeStates/country';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: CountryStoreState = {
  countries: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
