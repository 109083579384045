

















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Loaders } from '@/typings/loaders';
import ThirdPartyPayingContactRepository from '@/repository/ThirdPartyPayingContact';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import ThirdPartyPayingContactService from '@/services/api/ThirdPartyPayingContactService';
import ThirdPartyPayingContact from '../../typings/backend/jsonApiTranslations/thirdPartyPayingContact';
import EditContactDialogComponent from './EditContactDialog.vue';

@Component({
  components: {
    'edit-contact-dialog': EditContactDialogComponent,
    'confirmation-dialog': ConfirmationDialog,
  },
})
export default class ThirdPartyPayingContactComponent extends Vue {
  @Prop({ required: true, type: Object as () => ThirdPartyPayingContact })
  thirdPartyPayingContact!: ThirdPartyPayingContact;

  public loaders: Loaders = {
    updatingContact: false,
    deletingContact: false,
  };

  public modals: {[key: string]: boolean} = {
    editContact: false,
    deletingContact: false,
  };

  public handleEditContact(
    { contact }: { contact: ThirdPartyPayingContact },
  ): void {
    this.loaders.updatingContact = true;
    const thirdPartyPayingContactRepository: ThirdPartyPayingContactRepository = (
      new ThirdPartyPayingContactRepository({})
    );
    thirdPartyPayingContactRepository.edit({
      thirdPartyPayingContact: contact,
    })
      .then(() => {
        this.modals.editContact = false;
        this.$toasted.show('Contact has been updated successfully');
        this.$emit('contact:update', { contact });
      })
      .finally(() => {
        this.loaders.updatingContact = false;
      });
  }

  public handleDeleteContact(): void {
    this.loaders.deletingContact = true;
    ThirdPartyPayingContactService
      .deleteThirdPartyPayingContact(this.thirdPartyPayingContact)
      .then(() => {
        this.$toasted.show('Contact has been deleted successfully');
        this.$emit('contact:delete', { contact: this.thirdPartyPayingContact });
      })
      .finally(() => {
        this.loaders.deletingContact = false;
      });
  }
}
