var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"10","cols":"12"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-card-text',[_c('h1',{staticClass:"text-uppercase text-center my-10"},[_vm._v(" You have been invited to join a gym as employee ")]),_c('ValidationObserver',{ref:"validationObserver"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newMember.firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name *","outlined":"","error-messages":errors},model:{value:(_vm.newMember.firstName),callback:function ($$v) {_vm.$set(_vm.newMember, "firstName", $$v)},expression:"newMember.firstName"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newMember.lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name *","outlined":"","error-messages":errors},model:{value:(_vm.newMember.lastName),callback:function ($$v) {_vm.$set(_vm.newMember, "lastName", $$v)},expression:"newMember.lastName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"2","sm":"4","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newMember.country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"return-object":"","item-text":"name","item-value":"id","outlined":"","placeholder":"Country *","items":_vm.countries,"error-messages":errors},model:{value:(_vm.newMember.country),callback:function ($$v) {_vm.$set(_vm.newMember, "country", $$v)},expression:"newMember.country"}})]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"8","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newMember.phoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Phone Number *","outlined":"","error-messages":errors},model:{value:(_vm.newMember.phoneNumber),callback:function ($$v) {_vm.$set(_vm.newMember, "phoneNumber", $$v)},expression:"newMember.phoneNumber"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newMember.password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password *","outlined":"","type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.newMember.password),callback:function ($$v) {_vm.$set(_vm.newMember, "password", $$v)},expression:"newMember.password"}})]}}])})],1)],1),_c('v-layout',{attrs:{"align-end":"","justify-end":""}},[_c('v-btn',{staticClass:"mt-8",attrs:{"text":"","loading":_vm.loader.creatingEmployee},on:{"click":_vm.handleCreateAccount}},[_vm._v(" Create Account ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }