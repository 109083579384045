
import { Component, Prop, Vue } from 'vue-property-decorator';
import IFilterRow from '@/typings/filters/layout/FilterRow';
import { CreateElement } from 'vue';
import FilterColumn from '@/components/filters/layout/FilterColumn.vue';
import { VRow } from 'vuetify/lib';
import FilterRenderers from '@/constants/filters/FilterRenderers';

@Component({
  components: {
    FilterColumn,
  },
})
export default class FilterRow extends Vue {
  @Prop({ required: true, type: Object as () => IFilterRow })
  row!: IFilterRow;

  @Prop({ required: true, type: Number })
  rowIndex!: number;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  public render(h: CreateElement) {
    return h(
      VRow,
      {
        props: {
          ...this.row.props,
          columns: this.row.columns,
          rowIndex: this.rowIndex,
        },
      },
      this.row.columns.map((column, columnIndex) => h(FilterColumn, {
        props: {
          column,
          columnIndex,
          rowIndex: this.rowIndex,
          filtersRendererType: this.filtersRendererType,
        },
      })),
    );
  }
}
