var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-8"},[_c('div',{staticClass:"attendee-read__divider"},[_vm._v("Plan Log")]),_c('v-card',[_c('v-card-text',[_c('listable-items',{attrs:{"hide-page-title":"","listable-item":_vm.listableItem,"service":_vm.service,"json-api-configuration":_vm.jsonParameters},scopedSlots:_vm._u([{key:"results-section",fn:function(ref){
var items = ref.items;
var loading = ref.loading;
return [_c('v-data-table',{staticClass:"v-data-table--transparent",attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":items,"loading":loading},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD/MM/YYYY kk:mm'))+" ")]}},{key:"item.kind",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.attendeePlanLogTranslation[item.kind])+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.gymPlanPriceService.getRealPrice(item.plan.price))+" ")]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }