import AxiosConstructorContract from '@/typings/filters/axiosConstructor/AxiosConstructorContract';
import FilterRendererInput from '@/typings/filters/types/FilterRendererInput';
import AxiosFiltersObject from '@/typings/filters/axiosConstructor/AxiosFiltersObject';
import BaseFilterAxiosConstructor from '@/services/filters/axiosConstructor/BaseFilterAxiosConstructor';

class FilterAxiosConstructorInput
  extends BaseFilterAxiosConstructor
  implements AxiosConstructorContract<FilterRendererInput> {
  public build(filter: FilterRendererInput, axiosFilters: AxiosFiltersObject) {
    if (this.returnOriginalAxiosObject(filter)) return axiosFilters;

    const filters = this.cloneFilters(axiosFilters);

    filters[filter.name] = filter.data;

    return filters;
  }
}

export default FilterAxiosConstructorInput;
