





























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';

const gymPlanStoreModule = namespace('gymPlans');
const gymStoreModule = namespace('gyms');

@Component({})
export default class GymPlanDeleteModal extends Vue {
  @gymPlanStoreModule.Mutation [GymPlanStoreMutations.SET_ACTIVE_DELETE_PLAN]: (
    gymPlan: GymPlan | null,
  ) => void;

  @gymPlanStoreModule.Getter(GymPlansStoreGetters.GET_ACTIVE_DELETE_PLAN) gymPlan!: GymPlan | null;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @gymPlanStoreModule.Action [GymPlansStoreActions.DELETE_GYM_PLAN]: (
    { gymPlan }: { gymPlan: GymPlan },
  ) => Promise<AxiosResponse>;

  @Watch('gymPlan')
  public watchGymPlan(gymPlan: GymPlan | null): void {
    if (gymPlan) this.fetchAttendeesOnPlan();
  }

  public totalNumberAttendeesAssociatedToPlan: number = 0;

  public deletingPlan: boolean = false;

  get active(): boolean {
    return this.gymPlan !== null;
  }

  get deleteMessage(): string {
    const higherThanOne: boolean = this.totalNumberAttendeesAssociatedToPlan > 1;

    return `Are you sure you would like to delete this plan?
      ${this.totalNumberAttendeesAssociatedToPlan}
      attendee${higherThanOne ? 's' : ''} ${higherThanOne ? 'are' : 'is'} associated with this plan`;
  }

  public closeModal(): void {
    this[GymPlanStoreMutations.SET_ACTIVE_DELETE_PLAN](null);
  }

  public async onDeletePlan(): Promise<void> {
    this.deletingPlan = true;
    await this[GymPlansStoreActions.DELETE_GYM_PLAN]({ gymPlan: this.gymPlan! });
    this.deletingPlan = false;

    this.$emit('delete', this.gymPlan);
    this.closeModal();
  }

  private async fetchAttendeesOnPlan(): Promise<void> {
    const params: JsonApiConfiguration = {
      filter: { gym: this.activeGym.id, plan: this.gymPlan!.id },
    };

    const response: AxiosResponse = await AttendeeApiService.list(params);

    this.totalNumberAttendeesAssociatedToPlan = response.meta!.page!.total;
  }
}
