
import { Component, Prop, Vue } from 'vue-property-decorator';
import IFilterColumn from '@/typings/filters/layout/FilterColumn';
import { CreateElement } from 'vue';
import EFilterRendererType from '@/typings/filters/types/EFilterRendererType';
import { VCol } from 'vuetify/lib';
import FilterRenderers from '@/constants/filters/FilterRenderers';

@Component({
  components: {
    [EFilterRendererType.Input]: () => import('@/components/filters/FilterInput.vue'),
    [EFilterRendererType.Select]: () => import('@/components/filters/FilterSelect.vue'),
    [EFilterRendererType.NumberRange]: () => import('@/components/filters/FilterNumberRange.vue'),
  },
})
export default class FilterColumn extends Vue {
  @Prop({ required: true })
  column!: IFilterColumn;

  @Prop({ required: true, type: Number })
  columnIndex!: number;

  @Prop({ required: true, type: Number })
  rowIndex!: number;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  public render(h: CreateElement) {
    return h(
      VCol,
      {
        props: {
          ...this.column.props,
        },
      },
      this.column.filters.map((filter, filterIndex) => h(filter.type, {
        props: {
          filter,
          filterIndex,
          rowIndex: this.rowIndex,
          columnIndex: this.columnIndex,
          filtersRendererType: this.filtersRendererType,
        },
      })),
    );
  }
}
