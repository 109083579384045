








































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import NewThirdPartyPaying
  from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPaying';
import Country from '@/typings/backend/jsonApiTranslations/country';
import NewThirdPartyPayingContact
  from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPayingContact';
import { namespace } from 'vuex-class';
import CountryStoreActions from '@/constants/store/modules/country/actions';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ThirdPartyPayingRepository from '@/repository/ThirdPartyPaying';
import { Loaders } from '@/typings/loaders';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';

const countryStoreModule = namespace('countries');
const gymsStoreModule = namespace('gyms');
const userStoreModule = namespace('users');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateThirdPartyPayingView extends Vue {
  @countryStoreModule.Action [CountryStoreActions.GET_COUNTRIES]: Function;

  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @gymsStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @gymsStoreModule.Action [GymsStoreActions.GET_GYMS]: Function;

  public thirdPartyPaying: NewThirdPartyPaying = {
    name: '',
    contacts: [],
    gyms: [],
  };

  public newContact: NewThirdPartyPayingContact = {
    personName: '',
    country: null,
    phoneNumber: '',
    email: '',
  };

  public $refs!: {
    newContactValidatorObserver: InstanceType<typeof ValidationObserver>,
    newThirdPartyPayingValidatorObserver: InstanceType<typeof ValidationObserver>,
  };

  public loaders: Loaders = {
    creatingThirdPartyPaying: false,
  };

  public created(): void {
    this[GymsStoreActions.GET_GYMS]({
      params: { filter: { userAssociation: this.activeUser.id } },
    });
    this.newContact.country = this.countries.find(c => c.code === 'HR') as Country;
    this.thirdPartyPaying.gyms.push(this.activeGym);
    this[CountryStoreActions.GET_COUNTRIES]({});
  }

  public handleClickAddContact(): void {
    this
      .$refs
      .newContactValidatorObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.thirdPartyPaying.contacts.push({ ...this.newContact });
        this.newContact = {
          personName: '',
          country: {} as Country,
          phoneNumber: '',
          email: '',
        };

        this
          .$nextTick()
          .then(() => {
            this.$refs.newContactValidatorObserver.reset();
          });
      });
  }

  public handleClickRemoveContact(contact: NewThirdPartyPayingContact, index: number): void {
    this.thirdPartyPaying.contacts.splice(index, 1);
  }

  public handleClickCreateNewThirdPartyPaying(): void {
    this
      .$refs
      .newThirdPartyPayingValidatorObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        const thirdPartyPayingRepository: ThirdPartyPayingRepository = (
          new ThirdPartyPayingRepository()
        );

        const promises = this.thirdPartyPaying.gyms.map(
          (gym: Gym) => thirdPartyPayingRepository.create({
            thirdPartyPaying: this.thirdPartyPaying,
            gym,
          }),
        );

        this.loaders.creatingThirdPartyPaying = true;
        Promise
          .all(promises)
          .then(() => {
            this.$router.push({ name: 'thirdPartyPayingList', params: this.$route.params });
            this.$toasted.show('Paying group(s) was created successfully');
          })
          .finally(() => {
            this.loaders.creatingThirdPartyPaying = false;
          });
      });
  }
}
