import CompanyStoreMutations from '@/constants/store/modules/company/mutations';
import CompanyStoreState from '@/typings/storeStates/company';
import Company from '@/typings/backend/jsonApiTranslations/company';

export default {
  [CompanyStoreMutations.SET_COMPANIES](state: CompanyStoreState, companies: Company[]) {
    state.companies = companies;
  },
  [CompanyStoreMutations.SET_ACTIVE_COMPANY](state: CompanyStoreState, company: Company | null) {
    state.activeCompany = company;
  },
};
