import CompanyStoreState from '@/typings/storeStates/company';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: CompanyStoreState = {
  companies: [],
  activeCompany: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
