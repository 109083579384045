










































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Loaders } from '@/typings/loaders';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import ResetPasswordApiService from '@/services/api/ResetPasswordApiService';
import { AxiosResponse } from 'axios';

const payloadBuilder = new PayloadBuilder('password-resets');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ResetPassword extends Vue {
  public resetPassword = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  public requestedPasswordReset: boolean = false;

  public loaders: Loaders = {
    sendingRequest: false,
  }

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
  }

  public requestResetPassword(): void {
    this
      .$refs
      .observer
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.loaders.sendingRequest = true;

        this.sendRequestResetPassword()
          .then(() => {
            this.requestedPasswordReset = true;
          })
          .finally(() => { this.loaders.sendingRequest = false; });
      });
  }

  private async sendRequestResetPassword(): Promise<AxiosResponse> {
    const payload = payloadBuilder
      .setAttributes({
        email: this.resetPassword.email,
        password: this.resetPassword.password,
      })
      .resolvePayload();

    return ResetPasswordApiService.resetPassword(payload);
  }
}
