import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymStoreMutations from '@/constants/store/modules/gyms/mutations';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreState from '@/typings/storeStates/gyms';
import NewGym from '@/typings/nativeEntities/gyms/newGym';
import Company from '@/typings/backend/jsonApiTranslations/company';
import Address from '@/typings/backend/jsonApiTranslations/address';
import RootStoreState from '@/typings/storeStates/rootState';
import GymApiService from '@/services/api/GymApiService';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import cloneDeep from 'lodash.clonedeep';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import User from '@/typings/backend/jsonApiTranslations/user';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import axiosJsonApi from '@/plugins/axios/jsonApi';
import axiosGeneric from '@/plugins/axios/generic';
import AttendanceLogApiService from '@/services/api/AttendanceLogApiService';

export default {
  async [GymsStoreActions.GET_GYMS](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return GymApiService
      .list(params)
      .then((response: AxiosResponse<Gym[]>) => {
        commit(GymStoreMutations.SET_GYMS, response.data);

        return response;
      });
  },

  async [GymsStoreActions.DELETE_GYM](
    { commit, state }: { commit: Function, state: GymStoreState },
    { gym }: { gym: Gym },
  ): Promise<AxiosResponse<null>> {
    return GymApiService
      .delete(gym.id)
      .then((response: AxiosResponse<null>) => {
        const gyms = [...state.gyms];

        gyms.splice(
          gyms.findIndex((g: Gym) => g.id === gym.id),
          1,
        );

        commit(GymStoreMutations.SET_GYMS, gyms);

        return response;
      });
  },

  async [GymsStoreActions.EDIT_GYM](
    { commit, state }: { commit: Function, state: GymStoreState },
    { gym, params }: { gym: Gym, params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    const payload = {
      data: {
        type: 'gyms',
        id: gym.id,
        attributes: {
          name: gym.name,
        },
      },
    };

    return GymApiService
      .edit(gym.id, payload, params)
      .then((response: AxiosResponse<Gym>) => {
        const gyms: Gym[] = [...state.gyms];

        gyms.splice(
          gyms.findIndex(gym_ => gym_.id === gym.id),
          1,
          response.data,
        );

        commit(GymStoreMutations.SET_GYMS, gyms);

        return response;
      });
  },

  async [GymsStoreActions.POST_GYM](
    { commit, state, rootState }: {
      commit: Function,
      state: GymStoreState,
      rootState: RootStoreState
    },
    {
      gym, company, address, params,
    }: {
      gym: NewGym,
      company: Company,
      params: JsonApiConfiguration,
      address: Address
    },
  ): Promise<AxiosResponse> {
    const payload = {
      data: {
        type: 'gyms',
        attributes: {
          name: gym.name,
          email: gym.email === '' ? null : gym.email,
        },
        relationships: {
          company: {
            data: { type: 'companies', id: company.id },
          },
          address: {
            data: { type: 'addresses', id: address.id },
          },
          // Only the logged user can create a gym for now so this is why its hardcoded
          createdBy: {
            data: { type: 'users', id: rootState.users.user.id },
          },
        },
      },
    };

    return GymApiService
      .create(payload, params)
      .then((response: AxiosResponse<Gym>) => {
        commit(GymStoreMutations.SET_GYMS, [response.data, ...state.gyms]);

        return response;
      });
  },

  async [GymsStoreActions.GET_GYM](
    { commit }: { commit: Function },
    { gymId, params }: { gymId: string, params: JsonApiConfiguration },
  ): Promise<AxiosResponse<Gym>> {
    return GymApiService
      .read(gymId, params)
      .then((response: AxiosResponse<Gym>) => response);
  },

  async [GymsStoreActions.UNLINK_ATTENDEE_FROM_GYM](
    { commit, rootState }: { commit: Function, rootState: RootStoreState },
    {
      payload, gymId, params, attendee,
    }: {
      payload: any, gymId: string, params: JsonApiConfiguration, attendee: Attendee,
    },
  ) {
    return GymApiService.deleteAttendeeRelationship(gymId, payload, params);
  },

  async [GymsStoreActions.UNLINK_EMPLOYEE_FROM_GYM](
    { commit, rootState }: { commit: Function, rootState: RootStoreState },
    {
      payload, gym, params, employee,
    }: {
      payload: any, gym: Gym, params: JsonApiConfiguration, attendee: Attendee, employee: User,
    },
  ): Promise<AxiosResponse<null>> {
    return GymApiService
      .deleteEmployeeRelationship(gym.id, payload, params)
      .then((response) => {
        const employees: User[] = cloneDeep(rootState.users.userList);

        employees.splice(
          employees.findIndex(e => e.id === employee.id),
          1,
        );

        commit(`users/${UserStoreMutations.SET_USER_LIST}`, employees, { root: true });

        return response;
      });
  },

  async [GymsStoreActions.SET_ACTIVE_GYM](
    { commit }: { commit: Function },
    { gym }: { gym: Gym },
  ): Promise<void> {
    axiosJsonApi.defaults.headers['X-Active-Gym'] = gym.id;
    axiosGeneric.defaults.headers['X-Active-Gym'] = gym.id;
    commit(GymStoreMutations.SET_ACTIVE_GYM, gym);

    return Promise.resolve();
  },

  async [GymsStoreActions.GET_CHECK_INS](
    { commit, state }: { commit: Function, state: GymStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return AttendanceLogApiService
      .list(params)
      .then((response) => {
        commit(GymStoreMutations.SET_CHECK_INS, response.data);
        commit(GymStoreMutations.SET_CHECK_IN_PAGINATION, response.meta!.page);

        return response;
      });
  },
};
