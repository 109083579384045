import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymsPlanStoreState from '@/typings/storeStates/gymsPlan';

export default {
  [GymPlansStoreGetters.GET_GYM_PLANS]: (state: GymsPlanStoreState) => state.gymPlans,
  [GymPlansStoreGetters.GET_ACTIVE_GYM_PLAN]: (state: GymsPlanStoreState) => state.activeGymPlan,
  [GymPlansStoreGetters.GET_ACTIVE_DELETE_PLAN]: (state: GymsPlanStoreState) => state.deletePlan,
  [GymPlansStoreGetters.GET_ACTIVE_USER_PLANS]: (
    state: GymsPlanStoreState,
  ) => state.activeAttendeeGymPlans,
};
