


































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import NewInvitation from '@/typings/nativeEntities/employeeInvitation/NewInvitation';
import { Loaders } from '@/typings/loaders';
import EmployeeInvitationApiService from '@/services/api/EmployeeInvitationApiService';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import Company from '@/typings/backend/jsonApiTranslations/company';

const gymStoreModule = namespace('gyms');
const usersStoreModule = namespace('users');
const companyStoreModule = namespace('company');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class StaffMemberNew extends Vue {
  @gymStoreModule.Action[GymsStoreActions.GET_GYMS]: (
    { params }: { params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse<Gym[]>>;

  @gymStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @usersStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @companyStoreModule.Getter(CompanyStoreGetters.GET_ACTIVE_COMPANY) activeCompany!: Company;

  public newInvitation: NewInvitation = {
    gym: null,
    email: '',
  };

  public loaders: Loaders = {
    inviting: false,
  };

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  public created(): void {
    this.newInvitation.gym = this.activeGym;
    this[GymsStoreActions.GET_GYMS]({
      params: { filter: { company: this.activeCompany.id } },
    });
  }

  public handleInviteButtonClick(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.loaders.inviting = true;
        EmployeeInvitationApiService.postNewInvite(this.newInvitation)
          .then(() => {
            this.$toasted.show('Invitation has been sent');
            this.$router.push({ name: 'staffMembersList', params: { ...this.$route.params } });
          })
          .finally(() => {
            this.loaders.inviting = false;
          });
      });
  }
}
