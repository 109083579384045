import CoreStoreActions from '@/constants/store/modules/core/actions';
import CoreStoreMutations from '@/constants/store/modules/core/mutations';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import ListableItem from '@/constants/listableItems/ListableItem';
import CoreStoreState from '@/typings/storeStates/CoreStoreState';

export default {
  async [CoreStoreActions.GET_ITEMS](
    { commit }: { commit: (mutation: CoreStoreMutations, payload: any) => void },
    { service, params, listableItem }: {
      service: { list: (params: JsonApiConfiguration) => AxiosResponse<any[]> },
      params: JsonApiConfiguration,
      listableItem: ListableItem,
    },
  ) {
    const response = await service.list(params);

    commit(CoreStoreMutations.SET_LISTABLE_ITEMS_RESPONSE, {
      response,
      listableItem,
    });

    return response;
  },
  async [CoreStoreActions.UPDATE_RESOURCE](
    // eslint-disable-next-line max-len
    { commit, state }: { commit: (mutation: CoreStoreMutations, payload: any) => void, state: CoreStoreState },
    { item, listableItem }: { item: {id: string}, listableItem: ListableItem, },
  ) {
    const response: AxiosResponse = state.listableItemsResponse[listableItem]!;
    const items = [...response.data];

    items.splice(
      items.findIndex(i => i.id === item.id),
      1,
      item,
    );

    commit(CoreStoreMutations.SET_LISTABLE_ITEMS_RESPONSE, {
      response: { ...response, data: items },
      listableItem,
    });
  },
  async [CoreStoreActions.DELETE_RESOURCE](
    // eslint-disable-next-line max-len
    { commit, state }: { commit: (mutation: CoreStoreMutations, payload: any) => void, state: CoreStoreState },
    { item, listableItem }: { item: {id: string}, listableItem: ListableItem, },
  ) {
    const response: AxiosResponse = state.listableItemsResponse[listableItem]!;
    const items = [...response.data];

    items.splice(items.findIndex(i => i.id === item.id), 1);

    commit(CoreStoreMutations.SET_LISTABLE_ITEMS_RESPONSE, {
      response: { ...response, data: items },
      listableItem,
    });
  },
};
