import BaseRepository from '@/repository/BaseRepository';
import { AxiosResponse } from 'axios';
import NewPlan from '@/typings/nativeEntities/plans/newPlan';
import store from '@/store/index';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import TimeRestriction from '@/typings/nativeEntities/plans/timeRestriction';
import GymPlanTimeRestrictionService from '@/services/api/GymPlanTimeRestrictionService';

class GymPlanRepository extends BaseRepository {
  public async create({ newPlan }: { newPlan: NewPlan }): Promise<AxiosResponse> {
    const gymPlanData: AxiosResponse = (
      await store.dispatch(`gymPlans/${GymPlansStoreActions.POST_GYM_PLAN}`, {
        newPlan,
        gym: newPlan.gym,
        params: this.getParameters('createGymPlans'),
      })
    );

    const promises = newPlan.timeRestriction.map(
      (timeRestriction: TimeRestriction) => (
        GymPlanTimeRestrictionService.postGymPlanTimeRestriction(
          timeRestriction,
          gymPlanData.data,
        )
      ),
    );


    await Promise.all(promises);

    return gymPlanData;
  }

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this,no-empty-function
  public async edit(): Promise<AxiosResponse> {

  }
}

export default GymPlanRepository;
