import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import EventInvitationStatus from '@/constants/event/EventInvitationStatus';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import Event from '@/typings/backend/jsonApiTranslations/event';
import GetFullNameAttendees from '@/services/attendee/GetFullNameAttendees';

const BASE_URL = 'events';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<Event[]>> {
    return axios.get(BASE_URL, { params });
  },
  delete(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
  read(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse> {
    return axios.get(`${BASE_URL}/${id}`, { params });
  },
  edit(id: string, payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<Event>> {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
  deleteFutureEvents(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}/sequence`, { params });
  },
  getAttendees(
    eventId: string,
    status: EventInvitationStatus = EventInvitationStatus.CONFIRMED,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<Attendee[]>> {
    return axios
      .get(`${BASE_URL}/${eventId}/${status}`, { params })
      .then(response => ({
        ...response,
        data: response.data.map((a: Attendee) => ({ ...a, fullName: GetFullNameAttendees(a) })),
      }));
  },
};
