var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-img',{staticClass:"mb-2",attrs:{"height":"auto","width":"100px","src":require("@/assets/logo-fitalic.svg")}})],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.requestResetPassword($event)}}},[_c('v-card',[_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","name":"email","type":"email","outlined":"","error-messages":errors},model:{value:(_vm.resetPassword.email),callback:function ($$v) {_vm.$set(_vm.resetPassword, "email", $$v)},expression:"resetPassword.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password:@confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","name":"password","type":"password","outlined":"","error-messages":errors},model:{value:(_vm.resetPassword.password),callback:function ($$v) {_vm.$set(_vm.resetPassword, "password", $$v)},expression:"resetPassword.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Confirm Password","name":"confirmPassword","type":"password","outlined":"","error-messages":errors},model:{value:(_vm.resetPassword.confirmPassword),callback:function ($$v) {_vm.$set(_vm.resetPassword, "confirmPassword", $$v)},expression:"resetPassword.confirmPassword"}})]}}],null,true)}),_c('v-slide-y-transition',[(_vm.requestedPasswordReset)?_c('v-alert',{attrs:{"color":"green","text":""}},[_vm._v(" If the email exists in our database, you will receive an email with a link with further instructions ")]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"text":"","color":"primary","to":{ name: 'login' }}},[_vm._v(" Login ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","type":"submit","color":"primary","loading":_vm.loaders.sendingRequest}},[_vm._v(" Reset password ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }