var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","hide-default-footer":"","item-key":"id","disable-sort":"","headers":_vm.headers,"items":_vm.users,"single-select":false,"items-per-page":100,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address ? item.address.name : '')+" ")]}},{key:"item.phoneNumbers",fn:function(ref){
var item = ref.item;
return _vm._l((item.phoneNumbers),function(phoneNumber){return _c('v-chip',{key:phoneNumber.id},[_c('a',{attrs:{"href":("tel:" + (phoneNumber.number))},domProps:{"innerHTML":_vm._s(phoneNumber.number)}})])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"min-width":"300px"}},[_c('v-list-item',{on:{"click":function($event){return _vm.handleUnlinkEmployee(item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s("Delete Employee")}})],1)],1)],1)]}}])}),_c('confirmation-dialog',{attrs:{"message":"Are you sure you would like to delete this employee from your gym?","loading":_vm.loaders.unlinkingUser},on:{"confirm:accept":_vm.handleUnlinkEmployeeConfirmation},model:{value:(_vm.dialogs.unlinkUser),callback:function ($$v) {_vm.$set(_vm.dialogs, "unlinkUser", $$v)},expression:"dialogs.unlinkUser"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }