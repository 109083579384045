import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import AttendeePlanPause from '@/typings/backend/jsonApiTranslations/AttendeePlanPause';

const BASE_URL = 'attendee-plan-pauses';

export default {
  post(payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<AttendeePlanPause>> {
    return axios.post(BASE_URL, payload, { params });
  },
  delete(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
};
