






























































import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Prop, Component } from 'vue-property-decorator';
import User from '@/typings/backend/jsonApiTranslations/user';
import NewEmail from '@/typings/nativeEntities/emails/NewEmail';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { Loaders } from '@/typings/loaders';
import EmailApiService from '@/services/api/EmailApiService';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class SendMessageUsers extends Vue {
  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ required: true })
  users!: User[];

  @Prop({ required: true })
  gym!: Gym;

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  public loaders: Loaders = {
    creatingEmail: false,
  }

  public newMessage: NewEmail<User> = {
    entities: [],
    entityType: 'attendees',
    body: '',
    subject: '',
    gym: null,
  }

  get isVisible(): boolean {
    return this.value;
  }

  get numberUsers(): number {
    return this.users.length;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public handleClickSendMessageButton(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) {
          return;
        }

        this.createMessage();
      });
  }

  private createMessage(): void {
    this.loaders.creatingEmail = true;

    EmailApiService.postNewEmail({
      ...this.newMessage,
      gym: this.gym,
      entities: this.users,
    })
      .then(() => {
        this.$toasted.show('Email have been sent');
        this.isVisible = false;
        this.resetFields();
      })
      .finally(() => {
        this.loaders.creatingEmail = false;
      });
  }

  private resetFields(): void {
    this.newMessage = {
      body: '',
      subject: '',
      entityType: 'attendees',
      gym: this.gym,
      entities: this.users,
    };

    this.$nextTick(() => {
      this.$refs.validationObserver.reset();
    });
  }
}
