


























import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import SearchAttendee from '@/components/attendee/SearchAttendee.vue';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';

@Component({
  components: {
    SearchAttendee,
  },
})
export default class AddAttendeeToGroup extends Vue {
  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public selectedAttendee: Attendee | null = null;

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.selectedAttendee = null;
    this.$emit('input', newValue);
  }
}
