import {
  NewResourceObject, Relationship, ResourceObject,
} from 'ts-json-api';

type Attributes = any;
type INewResource = any;

class PayloadBuilder {
  private payload: INewResource;

  public constructor(type: string) {
    this.payload = { type };
  }

  public setResourceType(type: string, resetPayload: boolean = false): PayloadBuilder {
    this.payload.type = type;

    if (resetPayload) this.resetPayload();

    return this;
  }

  public setId(id: string): PayloadBuilder {
    this.payload.id = id;

    return this;
  }

  public setAttributes(attributes: Attributes): PayloadBuilder {
    this.payload.attributes = attributes;

    return this;
  }

  public setRelationship(
    name: string,
    relationship: ResourceObject | ResourceObject[],
  ): PayloadBuilder {
    if (!Object.prototype.hasOwnProperty.call(this.payload, 'relationships')) {
      this.payload.relationships = {};
    }

    if (!Object.prototype.hasOwnProperty.call(this.payload.relationships, name)) {
      this.payload.relationships![name] = {} as Relationship;
      this.payload.relationships![name].data = {} as ResourceObject;
    }

    this.payload.relationships![name].data = relationship;

    return this;
  }

  public resetPayload(type: string = this.payload.type): PayloadBuilder {
    this.payload = { type };

    return this;
  }

  public resolvePayload(resetPayload: boolean = true): {data: NewResourceObject} {
    const payload = { data: this.payload };

    if (resetPayload) this.resetPayload();

    return payload;
  }
}

export default PayloadBuilder;
