import TableHeader from '@/typings/tableHeaders';

const PlanLogTableHeaders: TableHeader[] = [
  {
    text: 'Plan',
    value: 'plan.name',
  },
  {
    text: 'Created at',
    value: 'createdAt',
  },
  {
    text: 'Type',
    value: 'kind',
  },
  {
    text: 'Price',
    value: 'price',
  },
];

export default PlanLogTableHeaders;
