

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import AttendeeStatusIndicator from '@/components/attendee/AttendeeStatusIndicator.vue';
import IconText from '@/components/icons/IconText.vue';
import { namespace } from 'vuex-class';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import PhoneNumber from '@/typings/backend/jsonApiTranslations/phoneNumber';
import MarketingRow from '@/components/attendee/attendeeRead/MarketingRow.vue';

const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    AttendeeStatusIndicator,
    IconText,
    MarketingRow,
  },
})
export default class ViewInformationRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @Prop({ required: true, type: Boolean })
  isEditing!: boolean;

  get attendeePhoneNumbers(): PhoneNumber[] {
    return this.activeAttendee.phoneNumbers
      ? this.activeAttendee.phoneNumbers
      : [];
  }
}
