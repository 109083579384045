import TableHeader from '@/typings/tableHeaders';

const tableHeaders: TableHeader[] = [
  {
    text: 'Check in',
    value: 'checkIn',
  },
  {
    text: 'Check out',
    value: 'checkOut',
  },
  {
    text: 'Plan',
    value: 'plan.name',
  },
];

export default tableHeaders;
