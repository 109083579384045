import BaseRepository from '@/repository/BaseRepository';
import NewActivityGroup from '@/typings/nativeEntities/activityGroups/newActivityGroup';
import { AxiosResponse } from 'axios';
import store from '@/store';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import GymTranslated from '@/typings/backend/jsonApiTranslations/gym';
import ActivityGroupTranslated from '@/typings/backend/jsonApiTranslations/activityGroup';

class ActivityGroupRepository extends BaseRepository {
  async create(
    { newGroupActivity, gym }: {
      newGroupActivity: NewActivityGroup,
      gym: GymTranslated,
    },
  ): Promise<AxiosResponse> {
    return store.dispatch(`activityGroups/${ActivityGroupsStoreActions.POST_NEW_ACTIVITY_GROUP}`, {
      params: this.getParameters('createActivityGroups'),
      newGroupActivity,
      gym,
    });
  }

  async edit(
    { activityGroup }: {activityGroup: ActivityGroupTranslated},
  ): Promise<AxiosResponse<ActivityGroupTranslated>> {
    return store.dispatch(`activityGroups/${ActivityGroupsStoreActions.EDIT_ACTIVITY_GROUP}`, {
      params: this.getParameters('editActivityGroups'),
      activityGroup,
    });
  }
}

export default ActivityGroupRepository;
