import Company from '@/typings/nativeEntities/company/company';
import { AxiosResponse } from 'axios';
import AddressTranslated from '@/typings/backend/jsonApiTranslations/address';
import store from '@/store/index';
import CompanyStoreActions from '@/constants/store/modules/company/actions';
import BaseRepository from '@/repository/BaseRepository';
import AddressRepository from '@/repository/Address';
import ParametersMap from '@/typings/repository/parametersMap';
import CompanyTranslated from '@/typings/backend/jsonApiTranslations/company';

class CompanyRepository extends BaseRepository {
  private addressRepository: AddressRepository;

  constructor(params?: ParametersMap) {
    super(params);

    this.addressRepository = new AddressRepository(params);
  }

  public async create({ company }: { company: Company }): Promise<AxiosResponse> {
    const { data: address }: { data: AddressTranslated } = (
      await this.addressRepository.create({ address: company.address })
    );

    return store.dispatch(`company/${CompanyStoreActions.POST_NEW_COMPANY}`, {
      company,
      address,
      params: this.getParameters('createCompany'),
    });
  }

  public async edit(
    { company }: {company: CompanyTranslated},
  ): Promise<AxiosResponse<CompanyTranslated>> {
    const { data: address }: { data: AddressTranslated } = (
      await this.addressRepository.edit({ address: company.address })
    );

    return store.dispatch(`company/${CompanyStoreActions.EDIT_COMPANY}`, {
      company,
      params: this.getParameters('editCompany'),
    });
  }
}

export default CompanyRepository;
