














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CoreStoreGetters from '@/constants/store/modules/core/getters';
import FilterRow from '@/components/filters/layout/FilterRow.vue';
import FilterRenderers from '@/constants/filters/FilterRenderers';

const coreStoreModule = namespace('core');

@Component({
  components: {
    FilterRow,
  },
})
export default class Filters extends Vue {
  @coreStoreModule.Getter(CoreStoreGetters.FILTERS_LAYOUT_TO_RENDER) getRows!: (
    filterRenderer: FilterRenderers
  ) => FilterRow[];

  @Prop({ required: true, type: String })
  pageTitle!: string;

  @Prop({ default: false })
  createResourceRoute!: object;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  public showFilters = false;

  get rows() {
    return this.getRows(this.filtersRendererType);
  }
}
