import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import AttendanceLog from '@/typings/backend/jsonApiTranslations/attendanceLog';

export default {
  // The check ins must be sorted by check in
  getCurrentCheckedInPlan(checkIns: CheckIn[] | AttendanceLog[]): null | AttendanceLog | CheckIn {
    if (checkIns.length < 1) return null;

    if (checkIns[0].checkOut !== null) return null;

    return checkIns[0];
  },
};
