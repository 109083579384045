import CreateThirdPartyPayingView from '@/views/thirdPartyPaying/thirdPartyPayingNew.vue';
import ThirdPartyPayingList from '@/views/thirdPartyPaying/thirdPartyPayingList/thirdPartyPayingList.vue';
import ThirdPartyPayingView from '@/views/thirdPartyPaying/thirdPartyPayingView/thirdPartyPayingView.vue';

export default [
  {
    path: 'new',
    component: CreateThirdPartyPayingView,
    name: 'thirdPartyPayingNew',
  },
  {
    path: 'list',
    component: ThirdPartyPayingList,
    name: 'thirdPartyPayingList',
  },
  {
    path: ':thirdPartyPayingId',
    component: ThirdPartyPayingView,
    name: 'thirdPartyPayingView',
  },
];
