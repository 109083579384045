


















































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import cloneDeep from 'lodash.clonedeep';
import TimePeriods from '@/constants/gymPlans/timePeriods';
import GymPlanPriceService from '@/services/gymPlan/GymPlanPriceService';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import { Loaders } from '@/typings/loaders';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

const gymPlanStoreModule = namespace('gymPlans');
const payloadBuilder = new PayloadBuilder('gym-plans');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditGymPlanDialog extends Vue {
  @gymPlanStoreModule.Mutation[GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN]: (
    gymPlan: GymPlan | null,
  ) => void;

  @gymPlanStoreModule.Getter(GymPlansStoreGetters.GET_ACTIVE_GYM_PLAN) gymPlan!: GymPlan | null;

  @gymPlanStoreModule.Action [GymPlansStoreActions.EDIT_GYM_PLAN]: (
    { payload, params, gymPlan }: { payload: any, params: JsonApiConfiguration, gymPlan: GymPlan },
  ) => Promise<AxiosResponse>

  @Watch('gymPlan')
  public watchGymPlan(gymPlan: GymPlan | null): void {
    this.internalGymPlan = cloneDeep(gymPlan);

    if (gymPlan) {
      this.hasInfinityAttendance = gymPlan.participationNumber === null;
    }
  }

  @Watch('hasInfinityAttendance')
  public watchHasInfinityAttendance(hasInfinityAttendance: boolean): void {
    if (hasInfinityAttendance) this.internalGymPlan!.participationNumber = null;
  }

  public loading: Loaders = {
    editingPlan: false,
  }

  public internalGymPlan: GymPlan | null = null;

  public timePeriods: typeof TimePeriods = TimePeriods;

  public hasInfinityAttendance: boolean = false;

  get isVisible(): boolean {
    return this.gymPlan !== null;
  }

  get realPrice(): number {
    if (this.internalGymPlan === null) return 0;

    return GymPlanPriceService.getRealPrice(this.internalGymPlan.price);
  }

  set realPrice(price: number) {
    this.internalGymPlan!.price = GymPlanPriceService.setRealPrice(price);
  }

  set isVisible(value: boolean) {
    if (!value) {
      this[GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN](null);
    }
  }

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  }

  public savePlan(): void {
    this
      .$refs
      .validator
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        const payload = payloadBuilder
          .setAttributes({
            name: this.internalGymPlan!.name,
            // @ts-ignore
            'participation-number': this.internalGymPlan!.participationNumber,
            'plan-type': this.internalGymPlan!.planType,
            price: this.internalGymPlan!.price,
            quantity: this.internalGymPlan!.quantity,
          })
          .setId(this.internalGymPlan!.id)
          .resolvePayload();

        this.loading.editingPlan = true;
        this[GymPlansStoreActions.EDIT_GYM_PLAN]({
          payload,
          gymPlan: this.internalGymPlan!,
          params: { include: ['timeRestrictions', 'gym'] },
        })
          .then(() => {
            this.$toasted.show('Plan have been updated');
            this[GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN](null);
            this.$emit('update', this.internalGymPlan);
          })
          .finally(() => {
            this.loading.editingPlan = false;
          });
      });
  }
}
