































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Company from '@/typings/nativeEntities/company/company';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TokenStripe from '@/typings/thirdParties/stripe/tokenStripe';
import Address from '@/typings/backend/jsonApiTranslations/address';
import Step1 from './steps/Step1.vue';
import Step2 from './steps/Step2.vue';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    'step-1': Step1,
    'step-2': Step2,
  },
})
export default class CreateCompanyComponent extends Vue {
  @Prop({ required: true, type: Boolean })
  loading!: boolean;

  public step: number = 1;

  public handleFirstStepCompleted(
    { company, address }: { company: Company, address: Address },
  ): void {
    this.$emit('company:create', { company, address });

    // Whenever we need to complete the second step we can uncomment this line
    // this.company = company;
    // this.step = 2;
  }

  // public handleCompanyCreation({ stripeToken }: { stripeToken: TokenStripe }): void {
  //   this.$emit('company:create', {
  //     company: this.company,
  //     stripeToken,
  //   });
  // }
}
