




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import filters from '@/components/pages/scheduleGroups/filters.vue';
import Event from '@/typings/backend/jsonApiTranslations/event';
import { namespace } from 'vuex-class';
import EventsStoreActions from '@/constants/store/modules/events/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import { Loaders } from '@/typings/loaders';
import { CalendarTimestamp } from 'vuetify';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import ViewEventDialog from '@/components/events/ViewEventDialog.vue';
import EventDialog from '@/components/events/CreateEventDialog/CreateEventDialog.vue';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
import Modals from '@/constants/Modals';
import PrivateGymTraining from '@/typings/backend/jsonApiTranslations/PrivateGymTraining';
import EventSource from '@/constants/event/EventSource';

const eventStoreModule = namespace('event');
const gymStoreModule = namespace('gyms');

@Component({
  components: {
    filters,
    EventDialog,
    ViewEventDialog,
  },
})
export default class ScheduleGroupView extends Vue {
  @eventStoreModule.Action [EventsStoreActions.GET_EVENTS]: (
    { params }: { params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse<Event[]>>;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_EDIT_CREATE_EVENT]: (
    event: Event | CreateEventFactory | null,
  ) => void;

  @eventStoreModule.Getter(EventsStoreGetters.GET_EVENTS)
  storeEvents!: Event[];

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM)
  activeGym!: Gym;

  @eventStoreModule.Getter(EventsStoreGetters.GET_GYM_PRIVATE_TRAININGS)
  privateTrainings!: PrivateGymTraining[];

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_EVENT]: (
    event: Event | null,
  ) => void;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_PRIVATE_TRAINING]: (
    privateTraining: PrivateGymTraining | null,
  ) => void;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_MODAL]: (
    data: { modal: Modals, value: boolean },
  ) => void;

  @eventStoreModule.Action[EventsStoreActions.GET_PRIVATE_GYM_TRAININGS]: (
    { params }: { params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  public focus = this.$moment().toISOString();

  public type: string = 'month';

  public modals: {[key: string]: boolean} = {
    creatingNewEvent: false,
    viewingEvent: false,
  };

  public loaders: Loaders = {
    gettingEvents: false,
    creatingEvent: false,
    deletingEvent: false,
  };

  public filters: {[key: string]: string | string[]} = {
    gym: '',
    activityGroup: '',
    start: this.$moment().endOf('month').format('YYYY-MM-DD'),
    end: this.$moment().endOf('month').format('YYYY-MM-DD'),
  };

  public $refs!: {
    calendar: any,
  };

  get events() {
    return [
      ...this.storeEvents,
      ...this.privateTrainings.map(p => ({ ...p, start: p.from, end: p.to })),
    ];
  }

  get focusFormatted(): string {
    return this.$moment(this.focus).format('MMMM YYYY');
  }

  get params(): JsonApiConfiguration {
    return {
      filter: {
        gym: this.activeGym.id,
      },
    };
  }

  public prev(): void {
    this.$refs.calendar.prev();
  }

  public next(): void {
    this.$refs.calendar.next();
  }

  public handleDateChange(
    { start, end }: { start: CalendarTimestamp, end: CalendarTimestamp },
  ): void {
    this.filters.start = start.date;
    this.filters.end = end.date;

    const params: JsonApiConfiguration = {
      filter: {
        dateRange: `${this.filters.start}@${this.filters.end}`,
        gym: this.filters.gym,
        activityGroup: this.filters.activityGroup,
      },
    };

    this.getEvents(params);
  }

  public setToday(): void {
    this.focus = this.$moment().toISOString();
  }

  public handleFiltersUpdate(filter: {[key: string]: string | string[]}): void {
    this.filters = {
      ...this.filters,
      gym: filter.gym,
      activityGroup: filter.activityGroup,
    };

    const params: JsonApiConfiguration = {
      filter: {
        dateRange: `${this.filters.start}@${this.filters.end}`,
        gym: this.filters.gym,
        activityGroup: this.filters.activityGroup,
      },
    };

    this.getEvents(params);
  }

  // eslint-disable-next-line class-methods-use-this
  public getColorEvent(event: Event): string {
    if (event.source === EventSource.GymApplication) return (event as Event).color;

    if (!event.acceptedByGymAt) return 'v-event__private-training--declined';

    return 'v-event__private-training--pending';
  }

  public showEvent(
    { nativeEvent, event }: { nativeEvent: MouseEvent, event: Event | PrivateGymTraining },
  ) {
    if (ScheduleGroupView.isEvent(event)) {
      this[EventsStoreMutations.SET_ACTIVE_EVENT](event as Event);
      this[EventsStoreMutations.SET_EDIT_CREATE_EVENT](event as Event);
    } else {
      this[EventsStoreMutations.SET_ACTIVE_PRIVATE_TRAINING](event as PrivateGymTraining);
      this[EventsStoreMutations.SET_MODAL]({
        modal: Modals.ViewActivePrivateTraining,
        value: true,
      });
    }

    nativeEvent.stopPropagation();
  }

  public viewCalendarDayType({ date }: { date: string }): void {
    this.focus = date;
    this.type = 'day';
  }

  public onCreateEventClick() {
    this[EventsStoreMutations.SET_EDIT_CREATE_EVENT]({
      ...new CreateEventFactory(),
      gym: this.activeGym,
    });
    this[EventsStoreMutations.SET_MODAL]({
      modal: Modals.CreateEvent,
      value: true,
    });
  }

  // Upon creation of the Calendar component it emits an event
  // telling the date range it is rendering. We listen to
  // that event and then make the request to get all
  // the events on that time range. That's the
  // reason there's no "created" method
  private getEvents(params: JsonApiConfiguration): void {
    const paramsCopy: JsonApiConfiguration = {
      ...params,
      include: ['definition', 'activityGroupGuests', 'attendeeGuests'],
      filter: {
        ...params.filter,
        ...this.params.filter,
      },
    };

    this.loaders.gettingEvents = true;
    this.getPrivateTrainings(params);
    this[EventsStoreActions.GET_EVENTS]({ params: paramsCopy })
      .finally(() => {
        this.loaders.gettingEvents = false;
      });
  }

  private getPrivateTrainings(params: JsonApiConfiguration) {
    this[EventsStoreActions.GET_PRIVATE_GYM_TRAININGS]({
      params: {
        include: ['attendee'],
        filter: {
          ...params.filter,
          gym: this.activeGym.id,
        },
      },
    });
  }

  private static isEvent(object: {[k: string]: any, color?: string}) {
    return Object.prototype.hasOwnProperty.call(object, 'color');
  }
}
