import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';

const BASE_URL = 'gym-plans';

export default {
  post(payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<GymPlan>> {
    return axios
      .post(BASE_URL, payload, { params });
  },
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<GymPlan[]>> {
    return axios
      .get(BASE_URL, { params });
  },
  edit(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<GymPlan>> {
    return axios
      .patch(`${BASE_URL}/${id}`, payload, { params });
  },
  delete(id: string, params?: JsonApiConfiguration): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
};
