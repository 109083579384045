import BaseRepository from '@/repository/BaseRepository';
import NewThirdPartyPaying from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPaying';
import { AxiosResponse } from 'axios';
import store from '@/store';
import ThirdPartyPayingStoreActions from '@/constants/store/modules/thirdPartyPaying/actions';
import ParametersMap from '@/typings/repository/parametersMap';
import PhoneNumberRepository from '@/repository/PhoneNumber';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import ThirdPartyPayingContactRepository from '@/repository/ThirdPartyPayingContact';

class ThirdPartyPayingRepository extends BaseRepository {
  private phoneNumberRepository: PhoneNumberRepository;

  private thirdPartyPayingContactRepository: ThirdPartyPayingContactRepository;

  constructor(params?: ParametersMap) {
    super(params);

    this.phoneNumberRepository = new PhoneNumberRepository(params);
    this.thirdPartyPayingContactRepository = new ThirdPartyPayingContactRepository(params);
  }

  async create(
    { thirdPartyPaying, gym }: { thirdPartyPaying: NewThirdPartyPaying, gym: Gym },
  ): Promise<AxiosResponse> {
    const thirdPartyPayingResponse: AxiosResponse<ThirdPartyPaying> = (
      await store.dispatch(`thirdPartyPaying/${ThirdPartyPayingStoreActions.POST_THIRD_PARTY_PAYING}`, {
        thirdPartyPaying,
        gym,
        params: this.getParameters('createThirdPartyPaying'),
      })
    );

    const contacts = thirdPartyPaying.contacts.map(contact => this
      .thirdPartyPayingContactRepository
      .create({
        thirdPartyPaying: thirdPartyPayingResponse.data,
        contact,
      }));

    await Promise.all(contacts);

    return thirdPartyPayingResponse;
  }

  async edit(
    { thirdPartyPaying }: { thirdPartyPaying: ThirdPartyPaying },
  ): Promise<any> {
    return store.dispatch(`thirdPartyPaying/${ThirdPartyPayingStoreActions.EDIT_THIRD_PARTY_PAYING}`, {
      thirdPartyPaying,
      params: this.getParameters('editThirdPartyPaying'),
    });
  }
}

export default ThirdPartyPayingRepository;
