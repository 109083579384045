import TableHeader from '@/typings/tableHeaders';

const headers: TableHeader[] = [
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    value: 'name',
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
  },
  {
    text: 'Actions',
    align: 'start',
    sortable: false,
    value: 'actions',
  },
];

export default headers;
