import store from '@/store';
import UsersStoreGetters from '@/constants/store/modules/users/getters';

class CheckUserLoggedInService {
  // eslint-disable-next-line class-methods-use-this
  public isLoggedIn(): boolean {
    // check the expiration time
    return store.getters[`users/${UsersStoreGetters.GET_ACTIVE_TOKEN}`] !== null;
  }
}

export default CheckUserLoggedInService;
