import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';

const BASE_URL = 'attendee-plan-logs';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse> {
    return axios
      .get(BASE_URL, { params });
  },
};
