enum AttendeeStoreGetters {
  GET_ALL_ATTENDEES = 'GET_ALL_ATTENDEES',
  ACTIVE_ATTENDEE = 'ACTIVE_ATTENDEE',
  ATTENDEE_GYM_RELATIONSHIP = 'ATTENDEE_GYM_RELATIONSHIP',
  GET_ATTENDEE_CHECK_INS = 'GET_ATTENDEE_CHECK_INS',
  GET_ATTENDEE_CHECK_INS_PAGINATION = 'GET_ATTENDEE_CHECK_INS_PAGINATION',
  ATTENDEE_NOTES = 'ATTENDEE_NOTES',
  ACTIVE_FILTERS = 'ACTIVE_FILTERS',
  GET_ATTENDEE_GYM_PLANS = 'GET_ATTENDEE_GYM_PLANS',
  ACTIVE_ATTENDEE_IS_CHECKED_IN = 'ACTIVE_ATTENDEE_IS_CHECKED_IN',
  ACTIVE_ATTENDEE_CHECKED_IN_PLAN = 'ACTIVE_ATTENDEE_CHECKED_IN_PLAN',
  IS_EDITING_ATTENDEE = 'IS_EDITING_ATTENDEE',
}

export default AttendeeStoreGetters;
