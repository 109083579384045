import { extend } from 'vee-validate';
import {
  email, max, numeric, required, min,
} from 'vee-validate/dist/rules';

extend('min', min);

extend('required', {
  ...required,
  message: 'Field can not be empty',
});

extend('max', {
  ...max,
  message: 'The name field may not be greater than {length} characters',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('positive', (value) => {
  if (value >= 0) {
    return true;
  }

  return 'This field must be a positive number';
});

extend('numeric', {
  ...numeric,
  message: 'This field only accepts digits',
});

extend('password', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});
