import CoreStoreState from '@/typings/storeStates/CoreStoreState';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: CoreStoreState = {
  filtersRender: {},
  currentResponseListableItems: null,
  listableItemsResponse: {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
