
























































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Company from '@/typings/nativeEntities/company/company';
import { namespace } from 'vuex-class';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Country from '@/typings/backend/jsonApiTranslations/country';

const countryStoreModule = namespace('countries');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateCompanyStep1Components extends Vue {
  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  };

  public company: Company = {
    name: '',
    email: '',
    personalIdentificationNumber: '',
    address: {
      name: '',
      country: null,
    },
  };

  public handleClickCreateCompanyButton(): void {
    this.$refs
      .validator
      .validate()
      .then(async (isValid: boolean) => {
        if (!isValid) {
          return;
        }

        this.$emit('first-step:completed', { company: this.company });
      });
  }
}
