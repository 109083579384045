import FilterRow from '@/typings/filters/layout/FilterRow';
import EFilterRendererType from '@/typings/filters/types/EFilterRendererType';

const filters: FilterRow[] = [
  {
    props: {},
    columns: [
      {
        props: {
          md: '4',
          sm: '12',
          cols: 'sm',
        },
        filters: [
          {
            type: EFilterRendererType.Input,
            label: 'Contact Person Email',
            data: '',
            name: 'contactsEmail',
            componentOptions: {
              dense: true,
              color: 'white',
            },
          },
        ],
      },
      {
        props: {
          md: '4',
          sm: '12',
          cols: 'sm',
        },
        filters: [
          {
            type: EFilterRendererType.Input,
            label: 'Contact Person Name',
            data: '',
            name: 'contactsName',
            componentOptions: {
              dense: true,
              color: 'white',
            },
          },
        ],
      },
      {
        props: {
          md: '4',
          sm: '12',
          cols: 'sm',
        },
        filters: [
          {
            type: EFilterRendererType.Input,
            label: 'Third Party Name',
            data: '',
            name: 'name',
            componentOptions: {
              dense: true,
              color: 'white',
            },
          },
        ],
      },
    ],
  },
];

export default filters;
