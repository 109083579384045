import axios from '@/plugins/axios/jsonApi';
import NewTimeRestriction from '@/typings/nativeEntities/plans/timeRestriction';
import GymPlanTranslated from '@/typings/backend/jsonApiTranslations/gymPlan';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymPlanTimeRestriction from '@/typings/backend/jsonApiTranslations/gymPlanTimeRestriction';
import { AxiosResponse } from 'axios';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import store from '@/store';

const BASE_URL = 'gym-plan-time-restrictions';

export default {
  postGymPlanTimeRestriction(
    timeRestriction: NewTimeRestriction,
    gymPlan: GymPlanTranslated,
    params: JsonApiConfiguration = {},
  ) {
    const payload = {
      data: {
        type: 'gym-plan-time-restrictions',
        attributes: {
          from: timeRestriction.from,
          to: timeRestriction.to,
        },
        relationships: {
          gymPlan: {
            data: { type: 'gym-plans', id: gymPlan.id },
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params });
  },

  deleteGymPlanTimeRestriction(timeRestriction: GymPlanTimeRestriction) {
    return axios
      .delete(`${BASE_URL}/${timeRestriction.id}`)
      .then((response: AxiosResponse<null>) => response);
  },

  updateGymPlanTimeRestriction(
    timeRestriction: GymPlanTimeRestriction,
    gymPlan: GymPlanTranslated,
    params: JsonApiConfiguration = {},
  ) {
    const payload = {
      data: {
        type: 'gym-plan-time-restrictions',
        id: timeRestriction.id,
        attributes: {
          from: timeRestriction.from,
          to: timeRestriction.to,
        },
        relationships: {
          gymPlan: {
            data: { type: 'gym-plans', id: gymPlan.id },
          },
        },
      },
    };

    return axios.patch(`${BASE_URL}/${timeRestriction.id}`, payload, { params });
  },
};
