enum EventsStoreGetters {
  GET_EVENTS = 'GET_EVENTS',
  GET_ACTIVE_EVENT = 'ACTIVE_EVENT',
  GET_ACTIVE_EVENT_ATTENDEES = 'GET_ACTIVE_EVENT_ATTENDEES',
  GET_EDIT_CREATE_EVENT = 'GET_EDIT_CREATE_EVENT',
  MODAL_IS_OPEN = 'MODAL_IS_OPEN',
  GET_GYM_PRIVATE_TRAININGS = 'GET_GYM_PRIVATE_TRAININGS',
  GET_ACTIVE_PRIVATE_TRAINING = 'GET_ACTIVE_PRIVATE_TRAINING',
}

export default EventsStoreGetters;
