import TableHeader from '@/typings/tableHeaders';

const AttendeePlanTableHeaders: TableHeader[] = [
  {
    text: 'Plan',
    value: 'plan.name',
  },
  {
    text: 'Expiring Date',
    value: 'endedAt',
  },
  {
    text: 'Attendance',
    value: 'attendance',
  },
  {
    text: 'Actions',
    value: 'actions',
  },
];

export default AttendeePlanTableHeaders;
