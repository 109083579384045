import axios from '@/plugins/axios/jsonApi';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeesStoreState from '@/typings/storeStates/attendees';
import Address from '@/typings/backend/jsonApiTranslations/address';
import AttendeeNativeEntity from '@/typings/nativeEntities/users/Attendee';
import RootStoreState from '@/typings/storeStates/rootState';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import AttendeeGymPlansApiService from '@/services/api/AttendeeGymPlansApiService';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';
import cloneDeep from 'lodash.clonedeep';
import AttendanceLogApiService from '@/services/api/AttendanceLogApiService';
import AttendeeNote from '@/typings/backend/jsonApiTranslations/attendeeNote';
import AttendeeNotesApiService from '@/services/api/AttendeeNotesApiService';
import PhoneNumberService from '@/services/api/PhoneNumberService';
import PhoneNumber from '@/typings/backend/jsonApiTranslations/phoneNumber';
import ActivityGroupApiService from '@/services/api/ActivityGroupApiService';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import GetCurrentCheckedInPlanService from '@/services/checkIn/GetCurrentCheckedInPlanService';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import GymAttendeeApiService from '@/services/api/GymAttendeeApiService';
import AttendeePlanPauseApiService from '@/services/api/AttendeePlanPauseApiService';

const BASE_URL = 'attendees';

export default {
  async [AttendeesStoreActions.EDIT_ATTENDEE](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { params, attendee, payload }: {
      params?: JsonApiConfiguration,
      attendee: Attendee,
      payload: any
    },
  ) {
    return AttendeeApiService
      .edit(payload, attendee.id, params)
      .then((response) => {
        commit(
          AttendeeStoreMutations.SET_ACTIVE_ATTENDEE,
          { ...state.activeAttendee, ...response.data },
        );

        return response;
      });
  },

  async [AttendeesStoreActions.DELETE_ATTENDEE](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { attendee }: { attendee: Attendee },
  ): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${attendee.id}`);
  },

  async [AttendeesStoreActions.POST_ATTENDEE](
    { commit, state, rootState }: {
      commit: Function,
      state: AttendeesStoreState,
      rootState: RootStoreState
    },
    { address, attendee, params }: {
      address: Address,
      attendee: AttendeeNativeEntity,
      params: JsonApiConfiguration
    },
  ): Promise<AxiosResponse<Attendee>> {
    const payload = {
      data: {
        type: 'attendees',
        attributes: {
          'first-name': attendee.firstName,
          'last-name': attendee.lastName,
          email: attendee.email,
          'date-of-birth': attendee.dateOfBirth,
          locale: attendee.locale,
          personalIdentificationNumber: attendee.personalIdentificationNumber,
          'send-emails': attendee.sendEmails,
        },
        relationships: {
          address: {
            data: { type: 'addresses', id: address.id },
          },
          createdBy: {
            data: { type: 'users', id: rootState.users.user.id },
          },
        },
      },
    };

    return axios.post(BASE_URL, payload, { params });
  },

  async [AttendeesStoreActions.DELETE_PLAN_FROM_ATTENDEE](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { attendeeGymPlan, params }: { attendeeGymPlan: AttendeeGymPlan, params: JsonApiConfiguration },
  ): Promise<AxiosResponse<null>> {
    return AttendeeGymPlansApiService
      .deleteAttendeeGymPlan(attendeeGymPlan.id, params)
      .then((response) => {
        const { activeAttendee }: { activeAttendee: Attendee | null } = state;

        // Delete the plan from the active attendee
        if (activeAttendee && activeAttendee.plans) {
          activeAttendee.plans.splice(
            activeAttendee.plans.findIndex(p => p.id === attendeeGymPlan.plan!.id),
            1,
          );

          commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE, activeAttendee);
        }

        commit(
          AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS,
          state.attendeeGymPlans.filter(agp => agp.id !== attendeeGymPlan.id),
        );

        return response;
      });
  },

  async [AttendeesStoreActions.GET_ATTENDEE_CHECK_INS](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return AttendanceLogApiService
      .list(params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ATTENDEE_CHECK_INS, response.data);
        commit(AttendeeStoreMutations.SET_ATTENDEE_CHECK_INS_PAGINATION, response.meta!.page);

        return response;
      });
  },
  async [AttendeesStoreActions.DELETE_ATTENDEE_NOTE](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { params, note }: { params: JsonApiConfiguration, note: AttendeeNote },
  ) {
    return AttendeeNotesApiService
      .delete(note.id, params)
      .then(() => {
        const notes = [...state.attendeeNotes];

        notes.splice(notes.findIndex(n => n.id === note.id), 1);

        commit(AttendeeStoreMutations.SET_ATTENDEE_NOTES, notes);
      });
  },
  async [AttendeesStoreActions.CREATE_NEW_PHONE_NUMBER](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { params, payload }: { params?: JsonApiConfiguration, payload: any },
  ) {
    return PhoneNumberService
      .postPhoneNumber(payload, params)
      .then((response) => {
        const phoneNumbers = state.activeAttendee!.phoneNumbers
          ? [...state.activeAttendee!.phoneNumbers, response.data]
          : [response.data];

        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_PROPERTY, { name: 'phoneNumbers', value: phoneNumbers });

        return response;
      });
  },
  async [AttendeesStoreActions.DELETE_PHONE_NUMBER](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { params, phoneNumber }: { params?: JsonApiConfiguration, phoneNumber: PhoneNumber },
  ) {
    return PhoneNumberService
      .deletePhoneNumber(phoneNumber.id, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_PROPERTY, {
          name: 'phoneNumbers',
          value: state.activeAttendee!.phoneNumbers.filter(pn => pn.id !== phoneNumber.id),
        });

        return response;
      });
  },
  async [AttendeesStoreActions.ADD_NEW_ACTIVITY_GROUP](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { activityGroup, payload }: { payload: any, activityGroup: ActivityGroup },
  ) {
    return ActivityGroupApiService
      .addAttendee(activityGroup.id, payload)
      .then((response) => {
        const activityGroups = state.activeAttendee!.activityGroups
          ? state.activeAttendee!.activityGroups
          : [];

        const value = state.activeAttendee!.activityGroups
          ? [...activityGroups, cloneDeep(activityGroup)]
          : [cloneDeep(activityGroup)];

        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_PROPERTY, {
          value,
          name: 'activityGroups',
        });

        return response;
      });
  },
  async [AttendeesStoreActions.DELETE_ACTIVITY_GROUP](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { activityGroup, payload }: { payload: any, activityGroup: ActivityGroup },
  ) {
    return ActivityGroupApiService
      .removeAttendee(activityGroup.id, payload)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_PROPERTY, {
          value: state.activeAttendee!.activityGroups!.filter(ag => ag.id !== activityGroup.id),
          name: 'activityGroups',
        });

        return response;
      });
  },
  async [AttendeesStoreActions.ADD_ATTENDEE_GYM_PLAN](
    { commit, state }: { commit: Function, state: AttendeesStoreState },
    { payload, params }: { payload: any, params?: JsonApiConfiguration },
  ) {
    return AttendeeGymPlansApiService
      .postAttendeeGymPlan(payload, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS, [
          ...state.attendeeGymPlans,
          response.data,
        ]);

        return response;
      });
  },
  async [AttendeesStoreActions.GET_ATTENDEE](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params, id }: { params: JsonApiConfiguration, id: string },
  ) {
    return AttendeeApiService
      .read(id, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE, {
          ...response.data,
          fullName: `${response.data.firstName} ${response.data.lastName}`,
        });

        return response;
      });
  },
  async [AttendeesStoreActions.GET_ATTENDEE_GYM_PLANS](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params }: { params: JsonApiConfiguration },
  ) {
    return AttendeeGymPlansApiService
      .getAttendeeGymPlans(params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS, response.data);

        return response;
      });
  },
  async [AttendeesStoreActions.GET_CURRENT_CHECKED_IN_PLAN](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params }: { params: JsonApiConfiguration },
  ) {
    return AttendanceLogApiService
      .list(params)
      .then((response) => {
        const currentCheckinPlan = (
          GetCurrentCheckedInPlanService.getCurrentCheckedInPlan(response.data)
        );

        commit(AttendeeStoreMutations.SET_CURRENT_CHECKED_IN_PLAN, currentCheckinPlan);

        return response;
      });
  },

  async [AttendeesStoreActions.CHECK_IN_ATTENDEE](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params, payload, service }: {
      params?: JsonApiConfiguration,
      payload: any,
      service: {
        checkIn: (payload: any, params?: JsonApiConfiguration) => Promise<AxiosResponse<CheckIn>>
      }
    },
  ) {
    return service
      .checkIn(payload, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_CURRENT_CHECKED_IN_PLAN, response.data);

        return response;
      });
  },

  async [AttendeesStoreActions.CHECK_OUT_ATTENDEE](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    {
      params, payload, id, service,
    }: {
      params: JsonApiConfiguration,
      payload: any, id: string,
      service: {
        checkout: (
          id: string, payload: any, params: JsonApiConfiguration
        ) => Promise<AxiosResponse<CheckIn>>
      }
    },
  ) {
    return service
      .checkout(id, payload, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_CURRENT_CHECKED_IN_PLAN, null);

        return response;
      });
  },
  async [AttendeesStoreActions.GET_GYM_ATTENDEE](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params }: { params: JsonApiConfiguration },
  ) {
    return GymAttendeeApiService
      .list(params)
      .then((response) => {
        if (response.data.length < 1) {
          throw Error('This attendee doesn\'t have a relationship with the gym');
        }

        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_GYM_RELATIONSHIP, response.data[0]);

        return response;
      });
  },
  async [AttendeesStoreActions.UPDATE_ATTENDEE_GYM_RELATIONSHIP](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params, payload, id }: { params?: JsonApiConfiguration, payload: any, id: string },
  ) {
    return GymAttendeeApiService
      .patch(id, payload, params)
      .then((response) => {
        commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_GYM_RELATIONSHIP, response.data);

        return response;
      });
  },
  async [AttendeesStoreActions.GET_ATTENDEE_NOTES](
    { commit }: { commit: (mutation: AttendeeStoreMutations, payload: any) => void },
    { params }: { params?: JsonApiConfiguration },
  ) {
    return AttendeeNotesApiService
      .list(params)
      .then((response: AxiosResponse<AttendeeNote[]>) => {
        commit(AttendeeStoreMutations.SET_ATTENDEE_NOTES, response.data.reverse());

        return response;
      });
  },
  async [AttendeesStoreActions.CREATE_NEW_NOTE](
    { commit, state }: {
      commit: (mutation: AttendeeStoreMutations, payload: any) => void,
      state: AttendeesStoreState
    },
    { params, payload }: { params?: JsonApiConfiguration, payload: any },
  ) {
    return AttendeeNotesApiService
      .create(payload, params)
      .then((response: AxiosResponse<AttendeeNote>) => {
        commit(AttendeeStoreMutations.SET_ATTENDEE_NOTES, [...state.attendeeNotes, response.data]);

        return response;
      });
  },
  async [AttendeesStoreActions.PAUSE_PLAN](
    { commit, state }: {
      commit: (mutation: AttendeeStoreMutations, payload: any) => void,
      state: AttendeesStoreState
    },
    { params, payload, attendeeGymPlan }: {
      params?: JsonApiConfiguration,
      payload: any,
      attendeeGymPlan: AttendeeGymPlan
    },
  ) {
    return AttendeePlanPauseApiService
      .post(payload, params)
      .then((response) => {
        const attendeeGymPlans: AttendeeGymPlan[] = cloneDeep(state.attendeeGymPlans);
        const replaceIndex = attendeeGymPlans.findIndex(agp => agp.id === attendeeGymPlan.id);

        attendeeGymPlans.splice(
          replaceIndex,
          1,
          { ...attendeeGymPlans[replaceIndex], pause: response.data },
        );

        commit(AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS, attendeeGymPlans);

        return response;
      });
  },
  async [AttendeesStoreActions.UNPAUSE_PLAN](
    { commit, state }: {
      commit: (mutation: AttendeeStoreMutations, payload: any) => void,
      state: AttendeesStoreState
    },
    { params, attendeeGymPlan }: {
      params?: JsonApiConfiguration,
      attendeeGymPlan: AttendeeGymPlan
    },
  ) {
    return AttendeePlanPauseApiService
      .delete(attendeeGymPlan.pause!.id, params)
      .then((response) => {
        const attendeeGymPlans: AttendeeGymPlan[] = cloneDeep(state.attendeeGymPlans);
        const deletePauseIndex = attendeeGymPlans.findIndex(agp => agp.id === attendeeGymPlan.id);

        delete attendeeGymPlans[deletePauseIndex].pause;

        commit(AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS, attendeeGymPlans);

        return response;
      });
  },
  async [AttendeesStoreActions.UPDATE_PHONE_NUMBER](
    { commit, state }: {
      commit: (mutation: AttendeeStoreMutations, payload: any) => void,
      state: AttendeesStoreState
    },
    { params, payload, id }: {
      params?: JsonApiConfiguration,
      payload: any,
      id: string
    },
  ) {
    const response = await PhoneNumberService.edit(id, payload, params);

    commit(AttendeeStoreMutations.SET_ACTIVE_ATTENDEE, {
      ...state.activeAttendee,
      phoneNumbers: [response.data],
    });

    return response;
  },
  async [AttendeesStoreActions.UPDATE__PLAN_EXPIRING_DATE](
    { commit, state }: {
      commit: (mutation: AttendeeStoreMutations, payload: any) => void,
      state: AttendeesStoreState
    },
    { attendeeGymPlan, payload }: { attendeeGymPlan: AttendeeGymPlan, payload: any },
  ) {
    const response = await AttendeeGymPlansApiService.extendGymPlan(attendeeGymPlan.id, payload);
    const gymPlans = [...state.attendeeGymPlans];
    const replaceIndex = gymPlans.findIndex(gymPlan => gymPlan.id === attendeeGymPlan.id);

    gymPlans.splice(
      replaceIndex,
      1,
      attendeeGymPlan,
    );

    commit(
      AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS,
      gymPlans,
    );

    return response;
  },
};
