
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import GymAttendanceFrequencyStatistics
  from '@/typings/backend/customResponses/Statistics/GymAttendanceFrequencyStatistics';
import GoogleChartStyle from '@/views/gyms/configuration/GoogleChartStyle';
import StatisticsApiService from '@/services/api/StatisticsApiService';
import VueMoment from 'vue-moment';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
// @ts-ignore
import { GChart } from 'vue-google-charts';
import Dialogs from '@/typings/Dialogs';
import moment from 'moment';
import { Loaders } from '@/typings/loaders';
import { Watch } from 'vue-property-decorator';

const gymStoreModule = namespace('gyms');
const momentNow = moment();

interface DateRange {
  from: string;
  to: string;
}

@Component({
  components: {
    GChart,
  },
})
export default class StatisticsGymAttendanceFrequency extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @Watch('dateRange', { deep: true })
  public watchDateRange(dateRange: DateRange): void {
    this.getGymAttendanceFrequency(dateRange);
  }

  public gymFrequency: GymAttendanceFrequencyStatistics[] = [];

  public chartOptions = GoogleChartStyle;

  public dateRange: DateRange = {
    from: momentNow.clone().startOf('month').format('YYYY-MM-DD'),
    to: momentNow.endOf('month').format('YYYY-MM-DD'),
  }

  public loaders: Loaders = {
    fetchingStatistics: false,
  }

  public dialogs: Dialogs = {
    fromDate: false,
    toDate: false,
  }

  get chartData() {
    if (this.gymFrequency.length < 1) return [];

    const data: Array<Array<string|number>> = [['Period', 'Total Visits']];

    this.gymFrequency.forEach(gf => data.push([
      `${this.$moment(gf.checkIn).format('hh')}:00`,
      gf.total,
    ]));

    return data;
  }

  public allowedToDated(toDate: string): boolean {
    const fromNow = moment(this.dateRange.from);

    return moment(toDate).isSameOrAfter(fromNow, 'days');
  }

  public allowedFromDate(fromDate: string): boolean {
    const toMoment = moment(this.dateRange.to);

    return moment(fromDate).isSameOrBefore(toMoment, 'days');
  }

  public created(): void {
    this.getGymAttendanceFrequency();
  }

  private getGymAttendanceFrequency(dateRange = this.dateRange): void {
    const params = {
      from: dateRange.from,
      to: dateRange.to,
    };

    this.loaders.fetchingStatistics = true;
    StatisticsApiService
      .gymAttendanceFrequency(this.activeGym.id, params)
      .then((response) => {
        this.gymFrequency = response.data.data;
      })
      .finally(() => {
        this.loaders.fetchingStatistics = false;
      });
  }
}
