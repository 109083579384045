

















import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash.debounce';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import { Loaders } from '@/typings/loaders';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { namespace } from 'vuex-class';

const gymStoreModule = namespace('gyms');

@Component
export default class SearchAttendee extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public attendeeSelected: Attendee | null = null;

  public attendeeSearchInput: string = '';

  public getAttendeesDebounced: Function = debounce(this.getAttendees, 500);

  public attendees: Attendee[] = [];

  public loaders: Loaders = {
    fetchingAttendees: false,
  }

  get attrs() {
    return {
      solo: true,
      ...this.$attrs,
    };
  }

  public onAttendeeSelectedChange(attendee: string | Attendee): void {
    if (typeof attendee !== 'string') {
      this.$emit('attendee:change', attendee);
    }
  }

  private getAttendees(fullTextSearch: string | undefined): void {
    if (fullTextSearch) {
      if (this.attendeeSelected && this.attendeeSelected.fullName === fullTextSearch) return;

      const params: JsonApiConfiguration = {
        include: ['address.country', 'phoneNumbers', 'activityGroups', 'plans'],
        filter: {
          fullTextSearch: encodeURIComponent(fullTextSearch),
          gym: this.activeGym.id,
        },
      };

      this.loaders.fetchingAttendees = true;
      AttendeeApiService
        .list(params)
        .then((response) => {
          this.attendees = response.data;
        })
        .finally(() => {
          this.loaders.fetchingAttendees = false;
        });
    }
  }
}
