import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import '@/assets/scss/main.scss';
import vuetify from './plugins/vuetify';
import './plugins/toasted';
import './plugins/vueValidate/index';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vue-swatches/dist/vue-swatches.min.css';
// eslint-disable-next-line import/order
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = (msg, vm, trace) => {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    msg = null;
    // eslint-disable-next-line no-param-reassign
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    vm = null;
    // eslint-disable-next-line no-param-reassign
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    trace = null;
  }
};

Vue.use(require('vue-moment'));

Vue.use(VueGtag, {
  config: { id: 'G-0R9L846FG1' },
});


new Vue({
  router,
  // @ts-ignore
  store,
  // @ts-ignore
  vuetify,
  render: h => h(App),
}).$mount('#app');
