


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import tableHeaders
  from '@/components/attendee/attendeeRead/configuration/AttendeeLogTableHeader';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import TableHeader from '@/typings/tableHeaders';

@Component({})
export default class AttendeeLogTable extends Vue {
  @Prop({ required: false, type: Boolean })
  loading!: boolean;

  @Prop({ required: true })
  checkIns!: CheckIn[];

  @Prop({ default: 100, type: Number })
  itemsPerPage!: number;

  public tableHeader = tableHeaders;

  public attendeeHeader: TableHeader = { text: 'Attendee Name', value: 'attendee' };

  get tableHeadersAll(): TableHeader[] {
    let attendeeIncluded = false;

    this.checkIns.forEach((a) => {
      if (a.attendee) attendeeIncluded = true;
    });

    return attendeeIncluded ? [...this.tableHeader, this.attendeeHeader] : this.tableHeader;
  }
}
