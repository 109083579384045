











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: true,
})
export default class IconText extends Vue {
  @Prop({ required: true, type: String })
  text!: string;

  @Prop({ required: true, type: String })
  icon!: string;

  @Prop()
  iconProps!: object;

  @Prop()
  buttonProps!: object;
}
