



















































































import { namespace } from 'vuex-class';
import Vue from 'vue';
import Component from 'vue-class-component';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import Company from '@/typings/backend/jsonApiTranslations/company';
import CompanyComponent from '@/components/pages/companyDashboard/ViewCompany.vue';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymComponent from '@/components/gyms/Gym.vue';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import GymStoreMutations from '@/constants/store/modules/gyms/mutations';
import GymEdit from '@/components/gyms/GymEdit.vue';
import CreateGym from '@/components/gyms/GymCreate.vue';
import NewGym from '@/typings/nativeEntities/gyms/newGym';
import CompanyStoreMutations from '@/constants/store/modules/company/mutations';
import GymRepository from '@/repository/Gym';
import ParametersMap from '@/typings/repository/parametersMap';
import store from '@/store';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import axiosGeneric from '@/plugins/axios/generic';
import axiosJsonApi from '@/plugins/axios/jsonApi';

const companyStoreModule = namespace('company');
const gymStoreModule = namespace('gyms');
const userStoreModule = namespace('users');

@Component({
  components: {
    company: CompanyComponent,
    gym: GymComponent,
    'confirmation-dialog': ConfirmationDialog,
    'gym-edit': GymEdit,
    'gym-create': CreateGym,
  },
  beforeRouteEnter(to, from, next) {
    store.commit(`gyms/${GymStoreMutations.SET_ACTIVE_GYM}`, null);

    next();
  },
})
export default class DashboardCompanyView extends Vue {
  @companyStoreModule.Getter(CompanyStoreGetters.GET_ACTIVE_COMPANY) company!: Company;

  @companyStoreModule.Getter(CompanyStoreGetters.ALL_COMPANIES) companies!: Company[];

  @gymStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Function;

  @gymStoreModule.Action [GymsStoreActions.GET_GYMS]: Function;

  @gymStoreModule.Action [GymsStoreActions.DELETE_GYM]: Function;

  @gymStoreModule.Mutation [GymStoreMutations.SET_GYMS]: Function;

  @companyStoreModule.Mutation [CompanyStoreMutations.SET_ACTIVE_COMPANY]: Function;

  @companyStoreModule.Getter(CompanyStoreGetters.GET_ACTIVE_COMPANY) activeCompany!: Company | null;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @gymStoreModule.Action[GymsStoreActions.SET_ACTIVE_GYM]: (
    { gym }: { gym: Gym }
  ) => Promise<void>;

  public deletingGym: Gym = {} as Gym;

  public deletingDialogConfirmation: boolean = false;

  public editingGym: Gym = {} as Gym;

  public editingDialogConfirmation: boolean = false;

  public editingRequest: boolean = false;

  public isCreatingGym: boolean = false;

  public isCreatingGymLoader: boolean = false;

  public async created(): Promise<void> {
    // The Gyms from the store can be populate
    // and do not have included relationships we need.
    // So we clear them first and then make the request
    // that will return all the relationships we will need
    this[GymStoreMutations.SET_GYMS]([]);

    const params: JsonApiConfiguration = {
      include: ['address.country', 'company', 'createdBy'],
      filter: {
        userAssociation: this.activeUser.id,
      },
    };

    await this[GymsStoreActions.GET_GYMS]({ params });

    // Force user to enter his company if he hasn't yet
    // If user does not have any company and does not have any gym
    // we can assume he just got registered and needs to setup his company
    if (this.companies.length === 0 && this.gyms.length === 0) {
      this.$router.push({ name: 'registerCompanyAfterRegister' });
    }
  }

  public handleClickEditGym({ gym }: { gym: Gym }): void {
    this.editingGym = gym;
    this.editingDialogConfirmation = true;
  }

  public handleClickStatsGym({ gym }: { gym: Gym }): void {
    this[GymsStoreActions.SET_ACTIVE_GYM]({ gym })
      .then(() => {
        this.$router.push({
          name: 'gymDashboard',
          params: { gymId: gym.id },
        });
      });
  }

  public handleClickCreateGym(): void {
    this.isCreatingGym = true;
  }

  public handleGymCreate({ gym }: { gym: NewGym }): void {
    this.isCreatingGymLoader = true;

    const paramsMapped: ParametersMap = {
      createGym: {
        include: ['address.country', 'company', 'createdBy'],
      },
    };
    const gymRepository = new GymRepository(paramsMapped);
    gymRepository.create({ gym })
      .then(() => {
        // @ts-ignore
        this.$refs.createGym.resetInput();

        this.isCreatingGym = false;
        this.$toasted.show(`Gym ${gym.name} has been created`);
      })
      .finally(() => {
        this.isCreatingGymLoader = false;
      });
  }

  public handleClickDeleteGym({ gym }: { gym: Gym }): void {
    this.deletingGym = gym;
    this.deletingDialogConfirmation = true;
  }

  public handleDeleteGym(): void {
    this[GymsStoreActions.DELETE_GYM]({ gym: this.deletingGym })
      .then(() => {
        this.$toasted.show('Gym has been deleted');
        this.deletingDialogConfirmation = false;
      });
  }

  public handleEditGym({ gym }: { gym: Gym }): void {
    this.editingRequest = true;

    const params: ParametersMap = {
      editGym: {
        include: ['address.country'],
      },
    };
    const gymRepository: GymRepository = new GymRepository(params);
    gymRepository
      .edit({ gym })
      .then(() => {
        this.$toasted.show(`${gym.name} has been edited successfully`);
        this.editingDialogConfirmation = false;
      })
      .finally(() => {
        this.editingRequest = false;
      });
  }

  public handleEditCompany({ company }: { company: Company }): void {
    this[CompanyStoreMutations.SET_ACTIVE_COMPANY](company);
  }
}
