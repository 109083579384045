import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import PhoneNumber from '@/typings/backend/jsonApiTranslations/phoneNumber';
import Country from '@/typings/backend/jsonApiTranslations/country';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';

class PhoneNumberPayloadBuilderDirector {
  private builder = new PayloadBuilder('phone-numbers');

  public edit(phoneNumber: PhoneNumber) {
    this.builder.setId(phoneNumber.id).setAttributes({ number: phoneNumber.number });

    if (phoneNumber.country) {
      this.builder.setRelationship('owner', { id: phoneNumber.country.id, type: 'country' });
    }

    const payload = this.builder.resolvePayload();

    this.builder.resetPayload();

    return payload;
  }

  public create(phoneNumber: { number: string, country: Country | null }, owner: Attendee) {
    this
      .builder
      .setAttributes({ number: phoneNumber.number })
      .setRelationship('owner', { id: owner.id, type: 'attendees' });

    if (phoneNumber.country) {
      this.builder.setRelationship('country', { id: phoneNumber.country.id, type: 'countries' });
    }

    const payload = this.builder.resolvePayload();

    this.builder.resetPayload();

    return payload;
  }
}

export default PhoneNumberPayloadBuilderDirector;
