import GymDashboardView from '@/views/gyms/GymDashboard.vue';

export default [
  {
    path: 'dashboard',
    name: 'gymDashboard',
    component: GymDashboardView,
    meta: {
      fullWidth: true,
    },
  },
];
