

































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import NewPlan from '@/typings/nativeEntities/plans/newPlan';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import GymPlanRepository from '@/repository/GymPlan';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { namespace } from 'vuex-class';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import TimePeriods from '@/constants/gymPlans/timePeriods';
import cloneDeep from 'lodash.clonedeep';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import { AxiosResponse } from 'axios';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import { Loaders } from '@/typings/loaders';
import GymPlanPriceService from '@/services/gymPlan/GymPlanPriceService';

const gymsStoreModule = namespace('gyms');
const userStoreModule = namespace('users');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreatePlanView extends Vue {
  @gymsStoreModule.Action [GymsStoreActions.GET_GYMS]: Function;

  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  public timePeriods: {[key: string]: string | number}[] = TimePeriods;

  public hasInfinityAttendance: boolean = false;

  public timeRestrictionsModals: {[key: string]: boolean}[] = [];

  public loaders: Loaders = {
    creatingPlan: false,
  }

  public newPlan: NewPlan = {
    name: '',
    planDuration: {
      times: '',
      timePeriod: '',
    },
    timeAttendeeCanAttend: '',
    price: 1,
    timeRestriction: [],
    gym: null,
  };

  public gyms: Gym[] = [];

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  };

  public created(): void {
    this.newPlan.gym = this.activeGym;
    this[GymsStoreActions.GET_GYMS]({ params: { filter: { userAssociation: this.activeUser.id } } })
      .then((response: AxiosResponse<Gym[]>) => {
        this.gyms = response.data;

        if (this.gyms.length < 2) {
          this.newPlan.gym = { ...this.gyms[0] };
        }
      });
  }

  public addNewTimeRestriction(): void {
    const newTimeRestriction = { from: '', to: '' };
    const newPlan: NewPlan = cloneDeep(this.newPlan);

    newPlan.timeRestriction.push(newTimeRestriction);

    this.newPlan = newPlan;

    this.timeRestrictionsModals.push({
      from: false,
      to: false,
    });
  }

  public handleClickCreatePlanButton(): void {
    this
      .$refs
      .validator
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.loaders.creatingPlan = true;
        const gymPlanRepository: GymPlanRepository = new GymPlanRepository();
        const price = GymPlanPriceService.setRealPrice(this.newPlan.price);
        gymPlanRepository
          .create({ newPlan: { ...this.newPlan, price } })
          .then(() => {
            this.$toasted.show('Plan created successfully');
            this.$router.push({
              name: 'planList',
              params: { companyId: this.$route.params.companyId },
            });
          })
          .finally(() => {
            this.loaders.creatingPlan = false;
          });
      });
  }

  public handleChangeAttendanceInfinity(isInfinity: boolean): void {
    if (isInfinity) {
      this.newPlan.timeAttendeeCanAttend = null;
    }
  }

  public removeTimeRestriction(index: number): void {
    const timeRestrictionsModals = cloneDeep(this.timeRestrictionsModals);
    const timeRestrictions = cloneDeep(this.newPlan.timeRestriction);

    timeRestrictionsModals.splice(index, 1);
    timeRestrictions.splice(index, 1);

    this.timeRestrictionsModals = timeRestrictionsModals;
    this.newPlan.timeRestriction = timeRestrictions;
  }
}
