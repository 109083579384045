enum AttendeesStoreActions {
  GET_ATTENDEES = 'GET_ATTENDEES',
  EDIT_ATTENDEE = 'EDIT_ATTENDEE',
  DELETE_ATTENDEE = 'DELETE_ATTENDEE',
  POST_ATTENDEE = 'POST_ATTENDEE',
  DELETE_PLAN_FROM_ATTENDEE = 'DELETE_PLAN_FROM_ATTENDEE',
  GET_ATTENDEE_CHECK_INS = 'GET_ATTENDEE_CHECK_INS',
  DELETE_ATTENDEE_NOTE = 'DELETE_ATTENDEE_NOTE',
  CREATE_NEW_PHONE_NUMBER = 'CREATE_NEW_PHONE_NUMBER',
  DELETE_PHONE_NUMBER = 'DELETE_PHONE_NUMBER',
  UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER',
  ADD_NEW_ACTIVITY_GROUP = 'ADD_NEW_ACTIVITY_GROUP',
  DELETE_ACTIVITY_GROUP = 'DELETE_ACTIVITY_GROUP',
  ADD_ATTENDEE_GYM_PLAN = 'ADD_ATTENDEE_GYM_PLAN',
  GET_ATTENDEE = 'GET_ATTENDEE',
  GET_ATTENDEE_GYM_PLANS = 'GET_ATTENDEE_GYM_PLANS',
  GET_CURRENT_CHECKED_IN_PLAN = 'GET_CURRENT_CHECKED_IN_PLAN',
  CHECK_IN_ATTENDEE = 'CHECK_IN_ATTENDEE',
  CHECK_OUT_ATTENDEE = 'CHECK_OUT_ATTENDEE',
  GET_GYM_ATTENDEE = 'GET_GYM_ATTENDEE',
  UPDATE_ATTENDEE_GYM_RELATIONSHIP = 'UPDATE_ATTENDEE_GYM_RELATIONSHIP',
  GET_ATTENDEE_NOTES = 'GET_ATTENDEE_NOTES',
  CREATE_NEW_NOTE = 'CREATE_NEW_NOTE',
  PAUSE_PLAN = 'PAUSE_PLAN',
  UNPAUSE_PLAN = 'UNPAUSE_PLAN',
  UPDATE__PLAN_EXPIRING_DATE = 'UPDATE__PLAN_EXPIRING_DATE',
}

export default AttendeesStoreActions;
