



















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import AttendanceLog from '@/typings/backend/jsonApiTranslations/attendanceLog';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import { Loaders } from '@/typings/loaders';
import CheckInApiService from '@/services/api/CheckInApiService';
import AdditionalCheckInApiService from '@/services/api/AdditionalCheckInApiService';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';

const attendeeStoreModule = namespace('attendees');
const gymStoreModule = namespace('gyms');
const payloadBuilderService = new PayloadBuilder('check-ins');

@Component({})
export default class PhotoRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Action [AttendeesStoreActions.GET_ATTENDEE_GYM_PLANS]: (
    { params }: { params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.GET_ATTENDEE_GYM_PLANS,
  ) attendeePlans!: AttendeeGymPlan[];

  @attendeeStoreModule.Action [AttendeesStoreActions.GET_CURRENT_CHECKED_IN_PLAN]: (
    { params }: { params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.ACTIVE_ATTENDEE_IS_CHECKED_IN,
  ) isCheckingOut!: null | AttendanceLog;

  @attendeeStoreModule.Action [AttendeesStoreActions.CHECK_IN_ATTENDEE]: (
    { params, payload }: {
      params?: JsonApiConfiguration,
      payload: any,
      service: {
        checkIn: (payload: any, params?: JsonApiConfiguration) => Promise<AxiosResponse<CheckIn>>
      }
    },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.CHECK_OUT_ATTENDEE]: (
    { params, payload, id }: {
      params: JsonApiConfiguration,
      payload: any, id: string,
      service: {
        checkout: (
          id: string, payload: any, params: JsonApiConfiguration
        ) => Promise<AxiosResponse<CheckIn>>
      }
    },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.ACTIVE_ATTENDEE_CHECKED_IN_PLAN,
  ) checkedInPlan!: AttendanceLog;

  public selectedGymPlan: null | AttendanceLog = null;

  public loaders: Loaders = {
    checkingIn: false,
    checkingOut: false,
    extraAttendance: false,
  }

  public created(): void {
    this[AttendeesStoreActions.GET_ATTENDEE_GYM_PLANS]({
      params: {
        include: ['plan', 'pause'],
        filter: { gym: this.activeGym.id, attendee: this.activeAttendee.id },
      },
    });

    this[AttendeesStoreActions.GET_CURRENT_CHECKED_IN_PLAN]({
      params: {
        filter: { gym: this.activeGym.id, attendee: this.activeAttendee.id },
        include: ['plan'],
        sort: '-check-in',
      },
    });
  }

  public async onCheckInUser() {
    const payload = payloadBuilderService
      .resetPayload()
      .setResourceType('check-ins')
      .setRelationship('plan', { id: this.selectedGymPlan!.plan!.id, type: 'gym-plans' })
      .setRelationship('attendee', { id: this.activeAttendee!.id, type: 'attendees' });

    this.loaders.checkingIn = true;
    try {
      await this[AttendeesStoreActions.CHECK_IN_ATTENDEE]({
        params: { include: ['plan'] },
        payload: payload.resolvePayload(),
        service: CheckInApiService,
      });
    } catch (e) {
      this.loaders.checkingIn = false;

      return;
    }
    this.loaders.checkingIn = false;
    this.selectedGymPlan = null;
  }

  public async onExtraAttendance() {
    const payload = payloadBuilderService
      .resetPayload()
      .setResourceType('additional-check-ins')
      .setRelationship('gym', { id: this.activeGym.id, type: 'gyms' })
      .setRelationship('attendee', { id: this.activeAttendee!.id, type: 'attendees' });

    await this[AttendeesStoreActions.CHECK_IN_ATTENDEE]({
      payload: payload.resolvePayload(),
      service: AdditionalCheckInApiService,
    });
    this.loaders.extraAttendance = true;
    this.loaders.extraAttendance = false;
  }

  public async onCheckout() {
    const payload = payloadBuilderService
      .resetPayload()
      .setId(this.checkedInPlan!.id)
      .setRelationship('attendee', { id: this.activeAttendee.id, type: 'attendees' });
    let params: JsonApiConfiguration = {};
    let service;

    if (this.checkedInPlan!.plan) {
      payload
        .setRelationship('plan', { id: this.checkedInPlan!.plan!.id, type: 'gym-plans' })
        .setResourceType('check-ins');
      params = { include: ['plan'] };
      service = CheckInApiService;
    } else {
      payload
        .setRelationship('gym', { id: this.activeGym.id, type: 'gyms' })
        .setResourceType('additional-check-ins');
      service = AdditionalCheckInApiService;
    }

    this.loaders.checkingOut = true;
    try {
      await this[AttendeesStoreActions.CHECK_OUT_ATTENDEE]({
        params,
        id: this.checkedInPlan!.id,
        payload: payload.resolvePayload(),
        service,
      });
    } catch (e) {
      this.loaders.checkingOut = false;

      return;
    }
    this.loaders.checkingOut = false;
  }
}
