var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('ValidationObserver',{ref:"newContactValidatorObserver"},[_c('v-card',[_c('v-card-title',{staticClass:"mb-5"},[_vm._v(" NEW CONTACT "),_c('v-spacer'),_c('v-icon',{attrs:{"large":""},on:{"click":function($event){_vm.isVisible = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"newContact.personName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Person's name for contact","outlined":"","error-messages":errors},model:{value:(_vm.newContact.personName),callback:function ($$v) {_vm.$set(_vm.newContact, "personName", $$v)},expression:"newContact.personName"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newContact.country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"return-object":"","item-text":"name","item-value":"id","outlined":"","label":"Country","items":_vm.countries,"error-messages":errors},model:{value:(_vm.newContact.country),callback:function ($$v) {_vm.$set(_vm.newContact, "country", $$v)},expression:"newContact.country"}})]}}])})],1),_c('v-col',{attrs:{"md":"8","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"newContact.phoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Phone number","outlined":"","error-messages":errors},model:{value:(_vm.newContact.phoneNumber),callback:function ($$v) {_vm.$set(_vm.newContact, "phoneNumber", $$v)},expression:"newContact.phoneNumber"}})]}}])})],1)],1),_c('ValidationProvider',{attrs:{"name":"newContact.email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","outlined":"","error-messages":errors},model:{value:(_vm.newContact.email),callback:function ($$v) {_vm.$set(_vm.newContact, "email", $$v)},expression:"newContact.email"}})]}}])})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleClickAddContact}},[_vm._v(" Add contact ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }