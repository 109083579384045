



































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import Event from '@/typings/backend/jsonApiTranslations/event';
import Dialogs from '@/typings/Dialogs';
import { Loaders } from '@/typings/loaders';
import EditEventDialogComponent from '@/components/events/EditEventDialog.vue';
import DeleteEventDialog from '@/components/events/DeleteEventDialog.vue';
import { Watch } from 'vue-property-decorator';
import EventsStoreActions from '@/constants/store/modules/events/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import EventInvitationStatus from '@/constants/event/EventInvitationStatus';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import PaginationService from '@/services/pagination/PaginationService';
import PaginationNavigation from '@/typings/backend/pagination/PaginationNavigation';
import Page from '@/typings/backend/pagination/Page';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import ManageEventInvitees from '@/components/events/ManageEventInvitees.vue';
import Modals from '@/constants/Modals';
import SendEmailEvent from '@/components/events/SendEmailEvent.vue';
import EventsStoreGetters from '../../constants/store/modules/events/getters';

const eventStoreModule = namespace('event');
const paginationService = new PaginationService(10);

@Component({
  components: {
    EditEventDialogComponent,
    DeleteEventDialog,
    ConfirmationDialog,
    ManageEventInvitees,
    SendEmailEvent,
  },
})
export default class ViewEventDialog extends Vue {
  @eventStoreModule.Getter(EventsStoreGetters.GET_ACTIVE_EVENT) event!: Event | null;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_EVENT]: (
    event: Event | null,
  ) => void;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES]: (
    attendees: Attendee[],
  ) => void;

  @eventStoreModule.Action [EventsStoreActions.GET_ACTIVE_EVENT_ATTENDEES]: (
    { params, status }: { params?: JsonApiConfiguration, status: EventInvitationStatus },
  ) => Promise<AxiosResponse>;

  @eventStoreModule.Getter(EventsStoreGetters.GET_ACTIVE_EVENT_ATTENDEES) attendees!: Attendee[];

  @eventStoreModule.Action [EventsStoreActions.DELETE_EVENT]: (
    { event, params }: { event: Event, params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse<null>>;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_MODAL]: (
    data: { modal: Modals, value: boolean },
  ) => void;

  @eventStoreModule.Action[EventsStoreActions.EDIT_EVENT]: (
    { event, params }: { event: Event, params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @Watch('event')
  private watchEvent(event: Event | null): void {
    this[EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES]([]);

    if (event) {
      paginationService.reset();
      this.getEventAttendees(EventInvitationStatus.PENDING);
    }
  }

  public onSelectStatusChange(selectedStatus: { text: string, id: EventInvitationStatus }): void {
    this[EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES]([]);
    paginationService.reset();
    this.getEventAttendees(selectedStatus.id);
  }

  public modals: Dialogs = {
    confirmDeletion: false,
    editingEvent: false,
  }

  public loaders: Loaders = {
    editingEvent: false,
    gettingAttendees: false,
    deletingEvent: false,
  }

  public filters: { text: string; id: EventInvitationStatus }[] = [
    { text: 'Coming', id: EventInvitationStatus.CONFIRMED },
    { text: 'Declined', id: EventInvitationStatus.DECLINED },
    { text: 'Pending', id: EventInvitationStatus.PENDING },
  ]

  public lastEditEvent: Event | null = null;

  public selectedStatus: { text: string, id: EventInvitationStatus } = { text: 'Pending', id: EventInvitationStatus.PENDING };

  private attendeePagination: { links: PaginationNavigation, page: Page } | null = null;

  get iconRepresentation() {
    if (this.selectedStatus.id === EventInvitationStatus.CONFIRMED) {
      return { icon: 'check', color: 'green' };
    }

    if (this.selectedStatus.id === EventInvitationStatus.DECLINED) {
      return { icon: 'close', color: 'red' };
    }

    return { icon: 'help', color: null };
  }

  get hasNextPage(): boolean {
    if (this.attendeePagination === null) return false;

    return Object.prototype.hasOwnProperty.call(this.attendeePagination.links, 'next');
  }

  get listHeader(): string {
    if (this.attendeePagination === null) return '';

    const baseMessage = `${this.attendeePagination.page.total} attendees`;
    if (this.selectedStatus.id === EventInvitationStatus.PENDING) {
      return `${baseMessage} still didn't answer`;
    }

    if (this.selectedStatus.id === EventInvitationStatus.DECLINED) {
      return `${baseMessage} declined the invitation`;
    }

    return `${baseMessage} confirmed attendance`;
  }

  public destroyed(): void {
    this[EventsStoreMutations.SET_ACTIVE_EVENT](null);
  }

  public onClose(closed: boolean): void {
    if (!closed) {
      this.selectedStatus = { text: 'Pending', id: EventInvitationStatus.PENDING };
      this[EventsStoreMutations.SET_ACTIVE_EVENT](null);
    }
  }

  public async handleEditEvent({ event }: { event: Event }) {
    this.loaders.editingEvent = true;
    try {
      await this[EventsStoreActions.EDIT_EVENT]({
        event,
        params: { include: ['definition', 'activityGroupGuests', 'attendeeGuests'] },
      });

      this.$toasted.show('Event have been successfully edited');
      this.modals.editingEvent = false;
      this.loaders.editingEvent = false;
      // Uncomment this line if you need to show
      // dialog asking if user would like to send email
      // this.lastEditEvent = event;
    } catch (e) {
      this.loaders.editingEvent = false;
    }
  }

  public handleLoadMoreAttendees(): void {
    paginationService.next();
    this.getEventAttendees(this.selectedStatus.id);
  }

  private getEventAttendees(status: EventInvitationStatus): void {
    this.loaders.gettingAttendees = true;
    this[EventsStoreActions.GET_ACTIVE_EVENT_ATTENDEES]({
      status,
      params: { page: paginationService.getPaginationObject() },
    })
      .then((response) => {
        this.attendeePagination = {
          links: (response.links as unknown) as PaginationNavigation,
          page: response.meta!.page!,
        };
      })
      .finally(() => {
        this.loaders.gettingAttendees = false;
      });
  }

  public handleDeleteSingleEvent(): void {
    this.loaders.deletingEvent = true;
    this[EventsStoreActions.DELETE_EVENT]({
      event: this.event!,
    })
      .then(() => {
        this[EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES]([]);
        this[EventsStoreMutations.SET_ACTIVE_EVENT](null);
        this.$toasted.show('Event have been deleted');
      });
  }

  public onManageInvitees() {
    this[EventsStoreMutations.SET_MODAL]({
      modal: Modals.ManageInviteesEvent,
      value: true,
    });
  }
}
