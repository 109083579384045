

























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Event from '@/typings/backend/jsonApiTranslations/event';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
import { ValidationProvider } from 'vee-validate';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import debounce from 'lodash.debounce';

const eventStoreModule = namespace('event');

type IEvent = Pick<Event, 'name' | 'description'>

@Component({
  components: {
    ValidationProvider,
  },
})
export default class EventDialogDescriptionRow extends Vue {
  @eventStoreModule.Getter(
    EventsStoreGetters.GET_EDIT_CREATE_EVENT,
  ) currentEvent!: Event | CreateEventFactory | null;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_EDIT_CREATE_EVENT]: (
    event: Event | CreateEventFactory | null
  ) => void;

  @Watch('currentEvent', { deep: true })
  public watchCurrentEvent(event: Event | CreateEventFactory | null) {
    if (event === null) return;

    this.event.name = event.name;
    this.event.description = event.description;
  }

  public event: IEvent = {
    name: '',
    description: '',
  }

  public created() {
    this.$watch('event', debounce(this.watchEvent, 500), { deep: true });
  }

  public watchEvent(event: IEvent) {
    // @ts-ignore
    this[EventsStoreMutations.SET_EDIT_CREATE_EVENT]({
      ...this.currentEvent,
      ...event,
    });
  }
}
