


























import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import AttendeeNote from '@/typings/backend/jsonApiTranslations/attendeeNote';
import { namespace } from 'vuex-class';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import IconText from '@/components/icons/IconText.vue';

const attendeeStoreModule = namespace('attendees');
@Component({
  components: { IconText },
})
export default class AttendeeNoteMessage extends Vue {
  @attendeeStoreModule.Action [AttendeesStoreActions.DELETE_ATTENDEE_NOTE]: (
    { params, note }: { params?: JsonApiConfiguration, note: AttendeeNote },
  ) => Promise<AxiosResponse<null>>;

  @Prop({ required: true })
  note!: AttendeeNote;

  public onDeleteNote(): void {
    this[AttendeesStoreActions.DELETE_ATTENDEE_NOTE]({
      note: this.note,
    });
  }
}
