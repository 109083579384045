import EFilterRendererType from '@/typings/filters/types/EFilterRendererType';
import FilterAxiosConstructorInput from '@/services/filters/axiosConstructor/FilterAxiosConstructorInput';
import FilterAxiosConstructorSelect from '@/services/filters/axiosConstructor/FilterAxiosConstructorSelect';
import FilterAxiosConstructorNumberRange from '@/services/filters/axiosConstructor/FilterAxiosConstructorNumberRange';

export default {
  [EFilterRendererType.Input]: new FilterAxiosConstructorInput(),
  [EFilterRendererType.Select]: new FilterAxiosConstructorSelect(),
  [EFilterRendererType.NumberRange]: new FilterAxiosConstructorNumberRange(),
};
