






















































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import { namespace } from 'vuex-class';
import ThirdPartyPayingStoreActions from '@/constants/store/modules/thirdPartyPaying/actions';
import { Loaders } from '@/typings/loaders';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import ThirdPartyPayingContactComponent from '@/components/thirdPartyPaying/ContactCard.vue';
import ThirdPartyPayingContact from '@/typings/backend/jsonApiTranslations/thirdPartyPayingContact';
import cloneDeep from 'lodash.clonedeep';
import ThirdPartyPayingContactNewDialog from '@/components/thirdPartyPaying/NewContactDialog.vue';
import NewThirdPartyPayingContact from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPayingContact';
import ThirdPartyPayingContactRepository from '@/repository/ThirdPartyPayingContact';
import ParametersMap from '@/typings/repository/parametersMap';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash.isempty';
import TableHeader from '@/typings/tableHeaders';
import attendeeHeaders from '@/views/thirdPartyPaying/thirdPartyPayingView/configuration/tableHeaders';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import ThirdPartyPayingViewAttendeeFiltersComponent from '@/components/pages/thirdPartyPayingView/AttendeeFilters.vue';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import AddAttendeeToGroup from '@/components/thirdPartyPaying/AddAttendeeToGroupDialog.vue';
import ThirdPartyPayingApiService from '@/services/api/ThirdPartyPayingApiService';

const thirdPartyPayingStoreModule = namespace('thirdPartyPaying');
const attendeeStoreModule = namespace('attendees');

interface AttendeeWithFullName extends Attendee {
  name: string;
}

@Component({
  components: {
    'contact-card': ThirdPartyPayingContactComponent,
    'new-contact': ThirdPartyPayingContactNewDialog,
    'confirmation-dialog': ConfirmationDialog,
    filters: ThirdPartyPayingViewAttendeeFiltersComponent,
    AddAttendeeToGroup,
  },
})
export default class ThirdPartyPayingView extends Vue {
  @thirdPartyPayingStoreModule.Action [
    ThirdPartyPayingStoreActions.GET_THIRD_PARTY_PAYING
  ]: Function;

  @thirdPartyPayingStoreModule.Action [
    ThirdPartyPayingStoreActions.DELETE_THIRD_PARTY_PAYING_ATTENDEE
  ]: Function;

  @attendeeStoreModule.Action [AttendeesStoreActions.GET_ATTENDEES]: Function;

  public thirdPartyPaying: ThirdPartyPaying = {} as ThirdPartyPaying;

  public deletingAttendee: Attendee = {} as Attendee;

  public loaders: Loaders = {
    addingContact: false,
    deletingAttendee: false,
    fetchingAttendees: false,
    addingNewAttendee: false,
  };

  public modals: {[key: string]: boolean} = {
    addingNewContact: false,
    deletingAttendee: false,
    addingNewAttendee: false,
  };

  public params: JsonApiConfiguration = {
    include: ['contacts.contactPhone.country', 'attendees'],
  };

  public $refs!: {
    newContactDialog: InstanceType<typeof ThirdPartyPayingContactNewDialog>,
  }

  public attendeesHeaders: TableHeader[] = attendeeHeaders;

  get isThirdPartyEmpty(): boolean {
    return isEmpty(this.thirdPartyPaying);
  }

  get attendees(): AttendeeWithFullName[] {
    if (this.thirdPartyPaying.attendees) {
      return this.thirdPartyPaying.attendees.map(attendee => ({
        ...attendee,
        name: `${attendee.firstName} ${attendee.lastName}`,
      }));
    }

    return [];
  }

  public async created(): Promise<void> {
    const { data: thirdPartyPaying } = (
      await this[ThirdPartyPayingStoreActions.GET_THIRD_PARTY_PAYING]({
        thirdPartyPayingId: this.$route.params.thirdPartyPayingId,
        params: this.params,
      })
    );

    this.thirdPartyPaying = thirdPartyPaying;
  }

  public handleContactUpdate({ contact }: { contact: ThirdPartyPayingContact }): void {
    const thirdPartyPaying = cloneDeep(this.thirdPartyPaying);

    thirdPartyPaying.contacts.splice(
      thirdPartyPaying.contacts.findIndex(c => c.id === contact.id),
      1,
      contact,
    );

    this.thirdPartyPaying = thirdPartyPaying;
  }

  public handleContactDelete({ contact }: { contact: ThirdPartyPayingContact }): void {
    const thirdPartyPaying = cloneDeep(this.thirdPartyPaying);

    thirdPartyPaying.contacts.splice(
      thirdPartyPaying.contacts.findIndex(c => c.id === contact.id),
      1,
    );

    this.thirdPartyPaying = thirdPartyPaying;
  }

  public handleNewContact({ contact }: { contact: NewThirdPartyPayingContact }): void {
    this.loaders.addingContact = true;

    const params: ParametersMap = {
      createThirdPartyPayingContact: {
        include: ['contactPhone.country'],
      },
      createPhoneNumber: {
        include: ['country'],
      },
    };
    const thirdPartyPayingContactRepository: ThirdPartyPayingContactRepository = (
      new ThirdPartyPayingContactRepository(params)
    );

    thirdPartyPayingContactRepository
      .create({
        contact,
        thirdPartyPaying: this.thirdPartyPaying,
      })
      .then((response: AxiosResponse<ThirdPartyPayingContact>) => {
        const thirdPartyPaying = cloneDeep(this.thirdPartyPaying);

        thirdPartyPaying.contacts.push(response.data);

        this.thirdPartyPaying = thirdPartyPaying;
        this.$toasted.show('Contact created successfully');
        this.modals.addingNewContact = false;
        // @ts-ignore
        this.$refs.newContactDialog.resetFields();
      })
      .finally(() => {
        this.loaders.addingContact = false;
      });
  }

  public handleDeleteAttendee(attendee: Attendee): void {
    this.deletingAttendee = attendee;
    this.modals.deletingAttendee = true;
  }

  public handleDeleteAttendeeConfirmation(): void {
    this.loaders.deletingAttendee = true;
    this[ThirdPartyPayingStoreActions.DELETE_THIRD_PARTY_PAYING_ATTENDEE]({
      attendees: [this.deletingAttendee],
      thirdPartyPaying: this.thirdPartyPaying,
    })
      .then(() => {
        const thirdPartyPaying = cloneDeep(this.thirdPartyPaying);

        thirdPartyPaying.attendees.splice(
          thirdPartyPaying.attendees.findIndex(
            attendee => attendee.id === this.deletingAttendee.id,
          ),
          1,
        );

        this.thirdPartyPaying = thirdPartyPaying;
        this.modals.deletingAttendee = false;
      })
      .finally(() => {
        this.loaders.deletingAttendee = false;
      });
  }

  public handleAttendeeFilterUpdate(filters: {[key: string]: string}): void {
    const params: JsonApiConfiguration = {
      filter: {
        ...filters,
        payingGroup: this.thirdPartyPaying.id,
      },
    };

    this.loaders.fetchingAttendees = true;
    this[AttendeesStoreActions.GET_ATTENDEES]({ params })
      .then((response: AxiosResponse<Attendee[]>) => {
        const thirdPartyPaying = cloneDeep(this.thirdPartyPaying);

        thirdPartyPaying.attendees = response.data;

        this.thirdPartyPaying = thirdPartyPaying;
      })
      .finally(() => {
        this.loaders.fetchingAttendees = false;
      });
  }

  public handleAttendeeAdd(attendee: Attendee): void {
    const payload = { data: [{ type: 'attendees', id: attendee.id }] };

    this.loaders.addingNewAttendee = true;
    ThirdPartyPayingApiService
      .addAttendee(this.thirdPartyPaying.id, payload)
      .then(() => {
        const tpp = cloneDeep(this.thirdPartyPaying);

        if (Array.isArray(tpp.attendees)) {
          tpp.attendees.push(attendee);
        } else {
          tpp.attendees = [attendee];
        }

        this.thirdPartyPaying = tpp;
        this.modals.addingNewAttendee = false;
        this.$toasted.show('Attendee has been added');
      })
      .finally(() => {
        this.loaders.addingNewAttendee = false;
      });
  }
}
