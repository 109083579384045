import { AxiosResponse } from 'axios';
import store from '@/store/index';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AddressRepository from '@/repository/Address';
import BaseRepository from '@/repository/BaseRepository';
import ParametersMap from '@/typings/repository/parametersMap';
import AddressTranslated from '@/typings/backend/jsonApiTranslations/address';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import NewAttendee from '@/typings/nativeEntities/users/Attendee';
import PhoneNumberRepository from '@/repository/PhoneNumber';
import PlansInformation from '@/typings/nativeEntities/users/NewAtendee/PlansInformation';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import AttendeeGymPlansApiService from '@/services/api/AttendeeGymPlansApiService';
import ActivityGroupApiService from '@/services/api/ActivityGroupApiService';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ThirdPartyPayingApiService from '@/services/api/ThirdPartyPayingApiService';
import GymAttendeeStatus from '@/constants/gymAttendee/GymAttendeeStatus';
import GymAttendeeApiService from '@/services/api/GymAttendeeApiService';

class AttendeeRepository extends BaseRepository {
  private addressRepository: AddressRepository;

  private phoneNumberRepository: PhoneNumberRepository;

  private payloadBuilder: PayloadBuilder = new PayloadBuilder('attendee-gym-plans');

  constructor(params: ParametersMap) {
    super(params);

    this.addressRepository = new AddressRepository(params);
    this.phoneNumberRepository = new PhoneNumberRepository(params);
  }

  public async create(
    attendee: NewAttendee,
    plansInformation: PlansInformation,
    activityGroups: ActivityGroup[],
    thirdPartyPaying: ThirdPartyPaying | null = null,
    gym: Gym,
    barcode: string,
  ): Promise<AxiosResponse<Attendee>> {
    const { data: address }: { data: AddressTranslated } = (
      await this.addressRepository.create({ address: attendee.address })
    );

    const attendeeResponse = (
      await store.dispatch(`attendees/${AttendeesStoreActions.POST_ATTENDEE}`, {
        address,
        attendee,
        params: this.getParameters('createAttendee'),
      })
    );

    await this.phoneNumberRepository.create({
      phoneNumber: {
        ...attendee.phoneNumber,
        owner: { ...attendee.phoneNumber.owner, id: attendeeResponse.data.id },
      },
      country: attendee.phoneNumber.country!,
    });

    // Link the attendee to the gym
    let payload: any = this.payloadBuilder
      .resetPayload()
      .setResourceType('gym-attendees')
      .setAttributes({
        'third-party-identifier': barcode,
        status: GymAttendeeStatus.Active,
      })
      .setRelationship(
        'gym',
        { type: 'gyms', id: gym.id },
      )
      .setRelationship(
        'attendee',
        { type: 'attendees', id: attendeeResponse.data.id },
      )
      .resolvePayload();

    await GymAttendeeApiService.create(payload);

    const planPromises = plansInformation.plans.map((plan) => {
      payload = this
        .payloadBuilder
        .resetPayload('attendee-plans')
        .setAttributes({ 'started-at': plansInformation.from })
        .setRelationship(
          'plan',
          { id: plan.id, type: 'gym-plans' },
        )
        .setRelationship(
          'attendee',
          { id: attendeeResponse.data.id, type: 'attendees' },
        )
        .resolvePayload();

      return AttendeeGymPlansApiService.postAttendeeGymPlan(payload);
    });

    const activityGroupPromises = activityGroups.map((ag) => {
      payload = { data: [{ id: attendeeResponse.data.id, type: 'attendees' }] };

      return ActivityGroupApiService.addAttendee(ag.id, payload);
    });

    const promises = [...planPromises, ...activityGroupPromises];

    if (thirdPartyPaying) {
      payload = { data: [{ type: 'attendees', id: attendeeResponse.data.id }] };

      promises.push(ThirdPartyPayingApiService.addAttendee(thirdPartyPaying.id, payload));
    }

    await Promise.all([...planPromises, ...activityGroupPromises]);

    return attendeeResponse;
  }

  public async edit({ attendee }: { attendee: Attendee }): Promise<AxiosResponse> {
    const { data: address }: { data: AddressTranslated } = (
      await this.addressRepository.edit({ address: attendee.address })
    );

    return store.dispatch(`attendees/${AttendeesStoreActions.EDIT_ATTENDEE}`,
      {
        params: this.getParameters('editAttendee'),
        attendee,
        address,
      });
  }
}

export default AttendeeRepository;
