import axios from '@/plugins/axios/generic';
import { AxiosResponse } from 'axios';
import StatisticsResponse from '@/typings/backend/customResponses/Statistics/StatisticsResponse';
import ActiveAttendeesStatistics
  from '@/typings/backend/customResponses/Statistics/ActiveAttendeesStatistics';
import GymCapacityStatistics from '@/typings/backend/customResponses/Statistics/GymCapacityStatistics';
import GymAttendanceFrequencyStatistics
  from '@/typings/backend/customResponses/Statistics/GymAttendanceFrequencyStatistics';
import MembershipDistributionStatistic
  from '@/typings/backend/customResponses/Statistics/MembershipDistributionStatistic';
import ActivityGroupDistributionStatistics
  from '@/typings/backend/customResponses/Statistics/ActivityGroupDistributionStatistics';

const BASE_URL = 'statistics';

export default {
  activeAttendees(
    gymId: string,
    params: any = {},
  ): Promise<AxiosResponse<StatisticsResponse<ActiveAttendeesStatistics[]>>> {
    return axios
      .get(`${BASE_URL}/${gymId}/active-attendees`, { params });
  },
  gymCapacity(
    gymId: string,
    params: any = {},
  ): Promise<AxiosResponse<StatisticsResponse<GymCapacityStatistics>>> {
    return axios
      .get(`${BASE_URL}/${gymId}/gym-capacity`, { params });
  },
  gymAttendanceFrequency(
    gymId: string,
    params: any = {},
  ): Promise<AxiosResponse<StatisticsResponse<GymAttendanceFrequencyStatistics[]>>> {
    return axios
      .get(`${BASE_URL}/${gymId}/gym-frequency`, { params });
  },
  membershipDistribution(
    gymId: string,
    params: any = {},
  ): Promise<AxiosResponse<StatisticsResponse<MembershipDistributionStatistic[]>>> {
    return axios
      .get(`${BASE_URL}/${gymId}/memberships-distribution`, { params });
  },
  activityGroupDistribution(
    gymId: string,
    params: any = {},
  ): Promise<AxiosResponse<StatisticsResponse<ActivityGroupDistributionStatistics[]>>> {
    return axios
      .get(`${BASE_URL}/${gymId}/activity-groups-distribution`, { params });
  },
};
