import PhoneNumber from '@/typings/backend/jsonApiTranslations/phoneNumber';
import Country from '@/typings/backend/jsonApiTranslations/country';

type IPhoneNumber = Omit<Pick<PhoneNumber, 'number' | 'country'>, 'country'> & {
  country: Country | null
}

class PhoneNumberFactory implements IPhoneNumber {
  country: Country | null = null;

  number: string = '';
}

export default PhoneNumberFactory;
