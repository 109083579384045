import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';

const BASE_URL = 'activity-groups';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<ActivityGroup[]>> {
    return axios.get(BASE_URL, { params });
  },
  addAttendee(
    activityGroupId: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ) {
    return axios.post(`${BASE_URL}/${activityGroupId}/relationships/attendees`, payload, { params });
  },
  removeAttendee(
    activityGroupId: string,
    data: any,
    params: JsonApiConfiguration = {},
  ) {
    return axios.delete(`${BASE_URL}/${activityGroupId}/relationships/attendees`, { params, data });
  },
};
