import CoreStoreGetters from '@/constants/store/modules/core/getters';
import CoreStoreState from '@/typings/storeStates/CoreStoreState';
import FilterAxiosConstructorFactory from '@/services/filters/axiosConstructor/FilterAxiosConstructorFactory';
import FilterRenderer from '@/typings/filters/types/FilterRenderer';
import FilterRow from '@/typings/filters/layout/FilterRow';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';

export default {
  // eslint-disable-next-line max-len
  [CoreStoreGetters.FILTERS_LAYOUT_TO_RENDER]: (state: CoreStoreState) => (filterRenderer: FilterRenderers) => state.filtersRender[filterRenderer],
  // eslint-disable-next-line max-len
  [CoreStoreGetters.AXIOS_FILTERS]: (state: CoreStoreState, getters: any) => (filterRenderer: FilterRenderers) => {
    let finalFilters: {[k: string]: string | string[]} = {};

    getters[CoreStoreGetters.FILTERS_TO_RENDER](filterRenderer).forEach((f: FilterRenderer) => {
      // @ts-ignore
      finalFilters = FilterAxiosConstructorFactory[f.type].build(f, finalFilters);
    });

    return finalFilters;
  },
  // eslint-disable-next-line max-len
  [CoreStoreGetters.FILTERS_TO_RENDER]: (state: CoreStoreState) => (filterRenderer: FilterRenderers) => (
    // @ts-ignore
    state.filtersRender[filterRenderer].reduce((filters: FilterRenderer[], row: FilterRow) => [
      ...filters,
      // @ts-ignore
      ...row.columns.reduce((previous, current) => [...previous, ...current.filters], []),
    ], [])
  ),
  [CoreStoreGetters.LISTABLE_ITEMS_ITEMS]: (state: CoreStoreState) => (
    listableItem: ListableItem,
  ) => (state.listableItemsResponse[listableItem]
    ? state.listableItemsResponse[listableItem]!.data
    : []),
  [CoreStoreGetters.LISTABLE_ITEMS_PAGE]: (state: CoreStoreState) => (
    listableItem: ListableItem,
  ) => (state.listableItemsResponse[listableItem]
    ? state.listableItemsResponse[listableItem]!.meta!.page
    : null),
};
