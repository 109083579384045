






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import NewActivityGroup from '@/typings/nativeEntities/activityGroups/newActivityGroup';
import { namespace } from 'vuex-class';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import ActivityGroupRepository from '@/repository/ActivityGroup';
import { Loaders } from '@/typings/loaders';
import UserStoreActions from '@/constants/store/modules/users/actions';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GetUserFullName from '@/services/user/GetUserFullName';

const gymsStoreModule = namespace('gyms');
const usersStoreModule = namespace('users');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ActivityGroupNewView extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @usersStoreModule.Action [UserStoreActions.GET_LIST_USERS]: (
    { params }: { params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse<User[]>>;

  @usersStoreModule.Getter(UsersStoreGetters.ALL_USERS_LIST) users!: User[];

  @usersStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  public newGroupActivity: NewActivityGroup = {
    name: '',
    description: '',
    gyms: [],
    trainers: [],
  };

  public loaders: Loaders = {
    creatingActivityGroup: false,
    fetchingUsers: false,
  };

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  get includeOwnerUsers(): User[] {
    return [...this.users, { ...this.activeUser, fullName: GetUserFullName(this.activeUser) }];
  }

  public created(): void {
    this[UserStoreActions.GET_LIST_USERS]({
      params: {
        include: ['address', 'phoneNumbers'],
        filter: {
          employedAt: this.activeGym.id,
        },
      },
    });
    this.newGroupActivity.gyms.push(this.activeGym);
  }

  public handleClickCreateActivityGroup(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.createActivityGroups();
      });
  }

  private async createActivityGroups(): Promise<void> {
    this.loaders.creatingActivityGroup = true;
    const activityGroupRepository: ActivityGroupRepository = new ActivityGroupRepository();

    const promises = this.newGroupActivity.gyms.map((gym: Gym) => activityGroupRepository.create({
      newGroupActivity: this.newGroupActivity,
      gym,
    }));

    Promise
      .all(promises)
      .then(() => {
        this.$toasted.show('Group activity has been successfully created');
        this.$router.push({ name: 'groupList', params: this.$route.params });
      })
      .finally(() => {
        this.loaders.creatingActivityGroup = false;
      });
  }
}
