import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '@/views/404.vue';
import AuthChildren from './modules/auth';
import DashboardChildren from './modules/dashboard';
import UsersChildren from './modules/users';
import GroupsChildren from './modules/groups';
import MembershipChildren from './modules/gymPlan';
import ManagementChildren from './modules/management';
import ThirdPartyPayingChildren from './modules/thirdPartyPaying';
import GymChildren from './modules/gyms';
import AcceptInvitation from './modules/invitation';
import PrivateTraining from './modules/privateTraining';

Vue.use(VueRouter);

const fullWidthFactory = () => import('../layouts/FullWidth.vue');
const ContainedFactory = () => import('../layouts/Contained.vue');
const routes = [
  {
    path: '/',
    component: fullWidthFactory,
    children: AuthChildren,
  },
  {
    path: '/invitation',
    component: fullWidthFactory,
    children: AcceptInvitation,
  },
  {
    path: '/private-training',
    component: fullWidthFactory,
    children: PrivateTraining,
  },
  {
    path: '/dashboard',
    component: ContainedFactory,
    children: DashboardChildren,
  },
  {
    path: '/:gymId/users',
    component: ContainedFactory,
    children: UsersChildren,
  },
  {
    path: '/:gymId/groups',
    component: ContainedFactory,
    children: GroupsChildren,
  },
  {
    path: '/:gymId/membership',
    component: ContainedFactory,
    children: MembershipChildren,
  },
  {
    path: '/:gymId/management',
    component: ContainedFactory,
    children: ManagementChildren,
  },
  {
    path: '/:gymId/thirdPartyPaying',
    component: ContainedFactory,
    children: ThirdPartyPayingChildren,
  },
  {
    path: '/:gymId',
    component: ContainedFactory,
    children: GymChildren,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
