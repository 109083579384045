import GymStoreState from '@/typings/storeStates/gyms';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: GymStoreState = {
  gyms: [],
  activeGym: null,
  checkIns: [],
  checkInPagination: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
