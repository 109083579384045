import mutations from '@/constants/store/modules/users/mutations';
import UsersStoreState from '@/typings/storeStates/users';
import User from '@/typings/backend/jsonApiTranslations/user';
import Token from '@/typings/backend/jsonApiTranslations/token';
import GlobalSnackbar from '@/typings/nativeEntities/GlobalSnackbar';

export default {
  [mutations.SET_TOKEN](state: UsersStoreState, token: Token | null) {
    state.token = token;
  },
  [mutations.SET_USER](state: UsersStoreState, user: User) {
    state.user = user;
  },
  [mutations.SET_USER_LIST](state: UsersStoreState, users: User[]) {
    state.userList = users;
  },
  [mutations.SET_GLOBAL_SNACKBAR](state: UsersStoreState, gsb: GlobalSnackbar | null) {
    state.globalSnackbar = gsb;
  },
};
