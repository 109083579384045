import actions from '@/store/modules/users/actions';
import getters from '@/store/modules/users/getters';
import mutations from '@/store/modules/users/mutations';
import UsersStoreState from '@/typings/storeStates/users';
import User from '@/typings/backend/jsonApiTranslations/user';

const state: UsersStoreState = {
  user: {} as User,
  token: null,
  userList: [],
  globalSnackbar: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
