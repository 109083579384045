import { AxiosRequestConfig, AxiosResponse } from 'axios';

class FakeAxiosResponseService<T = any> {
  private status: number = 200;

  private readonly data: T;

  private statusText: string = 'OK';

  private headers: any;

  private config: AxiosRequestConfig = {};

  private request: any = {};

  public constructor(data: T) {
    this.data = data;
  }

  public async resolveResponse(): Promise<AxiosResponse<T>> {
    return {
      data: this.data,
      status: this.status,
      statusText: this.statusText,
      headers: this.headers,
      config: this.config,
      request: this.request,
    };
  }
}

export default FakeAxiosResponseService;
