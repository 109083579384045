import AcceptInvitationEmployee from '@/views/invitation/AcceptInvitationAsEmployee.vue';
import CreateNewUser from '@/views/invitation/UserNew.vue';

export default [
  {
    path: ':invitationId/accept',
    name: 'acceptInvitationEmployee',
    component: AcceptInvitationEmployee,
    meta: {
      fullWidth: true,
      needsAuthentication: false,
    },
  },
  {
    path: ':invitationId/new',
    name: 'createNewUserFromInvitation',
    component: CreateNewUser,
    meta: {
      fullWidth: true,
      needsAuthentication: false,
    },
  },
];
