










































import Vue from 'vue';
import Component from 'vue-class-component';
import Company from '@/typings/backend/jsonApiTranslations/company';
import { Prop } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import CompanyRepository from '@/repository/Company';
import ParametersMap from '@/typings/repository/parametersMap';

@Component({
  components: {
    'edit-company': () => import('@/components/company/EditCompany.vue'),
  },
})
export default class CompanyComponent extends Vue {
  @Prop({ required: true })
  company!: Company;

  public editingCompany: boolean = false;

  public loadingEditingCompany: boolean = false;

  public async handleCompanyEdit({ company }: { company: Company }): Promise<void> {
    this.loadingEditingCompany = true;

    const parameters: ParametersMap = {
      editCompany: {
        include: ['address.country'],
      },
    };
    const companyRepository: CompanyRepository = new CompanyRepository(parameters);
    companyRepository
      .edit({ company })
      .then((response: AxiosResponse<Company>) => {
        this.$toasted.show('Company has been successfully edited');
        this.editingCompany = false;

        this.$emit('company:edit', { company: response.data });
      })
      .finally(() => {
        this.loadingEditingCompany = false;
      });
  }

  public handleClickActivateEditingCompany(): void {
    this.editingCompany = true;
  }

  public handleCompanyEditCancel(): void {
    this.editingCompany = false;
  }
}
