






















































import { Prop, Component, Vue } from 'vue-property-decorator';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { namespace } from 'vuex-class';
import GymNotesStoreActions from '@/constants/store/modules/gymNotes/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymNotesStoreGetters from '@/constants/store/modules/gymNotes/getters';
import GymNote from '@/typings/backend/jsonApiTranslations/gymNotes';
import ParametersMap from '@/typings/repository/parametersMap';
import GymNoteRepository from '@/repository/GymNote';
import { Loaders } from '@/typings/loaders';
import GymNotesStoreMutations from '@/constants/store/modules/gymNotes/mutations';

const gymNotesStoreModule = namespace('gymNotes');

@Component({})
export default class GymNotes extends Vue {
  @gymNotesStoreModule.Action [GymNotesStoreActions.GET_NOTES]: Function;

  @gymNotesStoreModule.Getter(GymNotesStoreGetters.GET_GYM_NOTE) gymNotes!: GymNote[];

  @gymNotesStoreModule.Mutation [GymNotesStoreMutations.SET_NOTES]: Function;

  @Prop({ required: true, type: Object as () => Gym })
  gym!: Gym;

  @Prop({ type: String })
  height!: string;

  public newMessage: string = '';

  public loaders: Loaders = {
    sendingMessage: false,
  };

  public $refs!: {
    messagesContainer: HTMLElement,
  };

  get gymNotesStyles(): {[key: string]: string} {
    return {
      height: this.height,
    };
  }

  public created(): void {
    const params: JsonApiConfiguration = {
      filter: {
        gym: this.gym.id,
      },
      include: ['createdBy'],
      sort: '-created-at',
    };

    this.loaders.sendingMessage = true;
    this[GymNotesStoreActions.GET_NOTES]({ params })
      .then(() => {
        this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight;
      })
      .finally(() => {
        this.loaders.sendingMessage = false;
      });
  }

  public destroyed(): void {
    this[GymNotesStoreMutations.SET_NOTES]([]);
  }

  public handleSendMessage(): void {
    const paramsMap: ParametersMap = {
      createGymNotes: {
        include: ['createdBy'],
      },
    };
    const gymNoteRepository: GymNoteRepository = new GymNoteRepository(paramsMap);

    this.loaders.sendingMessage = true;
    gymNoteRepository
      .create({ gym: this.gym, message: this.newMessage })
      .then(() => {
        this.newMessage = '';
        this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight;
      })
      .finally(() => {
        this.loaders.sendingMessage = false;
      });
  }
}
