import CountryStoreActions from '@/constants/store/modules/country/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import Country from '@/typings/backend/jsonApiTranslations/country';
import CountryStoreMutations from '@/constants/store/modules/country/mutations';
import CountryStoreState from '@/typings/storeStates/country';
import FakeAxiosResponseService from '@/services/axios/FakeAxiosResponse';
import CountryApiService from '@/services/api/CountryApiService';
import orderBy from 'lodash.orderby';

export default {
  async [CountryStoreActions.GET_COUNTRIES](
    { commit, state }: { commit: Function, state: CountryStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<Country[]>> {
    if (state.countries.length > 0) {
      return (new FakeAxiosResponseService<Country[]>(state.countries))
        .resolveResponse();
    }

    return CountryApiService
      .list(params)
      .then((response: AxiosResponse<Country[]>) => {
        commit(CountryStoreMutations.SET_COUNTRIES, orderBy(response.data, ['name']));

        return response;
      });
  },
};
