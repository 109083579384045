





























































import { Component, Vue } from 'vue-property-decorator';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ActivityGroupsStoreMutations from '@/constants/store/modules/activityGroups/mutations';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import ActivityGroupTableHeaders from '@/components/attendee/attendeeRead/configuration/ActivityGroupTableHeaders';
import { Loaders } from '@/typings/loaders';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import ActivityGroupsStoreGetters from '@/constants/store/modules/activityGroups/getters';

const activityGroupStoreModule = namespace('activityGroups');
const gymStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({})
export default class ActivityGroupsRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.IS_EDITING_ATTENDEE)
  isEditing!: boolean;

  @activityGroupStoreModule.Action [ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]: (
    { params }: { params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse>;

  @activityGroupStoreModule.Mutation[ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS]: (
    activityGroups: ActivityGroup[]
  ) => void;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Action [AttendeesStoreActions.DELETE_ACTIVITY_GROUP]: (
    { activityGroup, payload }: { payload: any, activityGroup: ActivityGroup },
  ) => Promise<AxiosResponse>;

  @activityGroupStoreModule.Getter(
    ActivityGroupsStoreGetters.GET_ACTIVITY_GROUPS,
  ) activityGroups!: ActivityGroup[];

  @attendeeStoreModule.Action [AttendeesStoreActions.ADD_NEW_ACTIVITY_GROUP]: (
    { activityGroup, payload }: { payload: any, activityGroup: ActivityGroup },
  ) => Promise<AxiosResponse>;

  public tableHeaders = Object.freeze(ActivityGroupTableHeaders);

  public loaders: Loaders = {
    addingActivityGroup: false,
    deletingActivityGroupAttendee: false,
  };

  public selectedActivityGroup: ActivityGroup | null = null;

  get userActivityGroups(): ActivityGroup[] {
    return this.activeAttendee.activityGroups
      ? this.activeAttendee.activityGroups
      : [];
  }

  get activityGroupAttendeeNotIn(): ActivityGroup[] {
    const userActivityGroupIds: string[] = this.activeAttendee.activityGroups
      ? this.activeAttendee.activityGroups.map(a => a.id)
      : [];

    return this.activityGroups.filter(a => !userActivityGroupIds.includes(a.id));
  }

  public created() {
    const params: JsonApiConfiguration = {
      filter: { gym: this.activeGym.id },
    };

    this[ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]({ params });
  }

  public destroyed() {
    this[ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS]([]);
  }

  public async onDeleteActivityGroupAttendee(activityGroup: ActivityGroup): Promise<void> {
    const payload = { data: [{ type: 'attendees', id: this.activeAttendee.id }] };

    this.loaders.deletingActivityGroupAttendee = true;
    await this[AttendeesStoreActions.DELETE_ACTIVITY_GROUP]({ activityGroup, payload });
    this.loaders.deletingActivityGroupAttendee = false;
  }

  public async onAddNewActivityGroup(): Promise<void> {
    const payload = { data: [{ type: 'attendees', id: this.activeAttendee.id }] };

    this.loaders.addingActivityGroup = true;
    await this[AttendeesStoreActions.ADD_NEW_ACTIVITY_GROUP]({
      payload,
      activityGroup: this.selectedActivityGroup!,
    });
    this.selectedActivityGroup = null;
    this.loaders.addingActivityGroup = false;
  }
}
