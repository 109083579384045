





















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import TimePeriod from '@/constants/gymPlans/TimePeriod';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import { namespace } from 'vuex-class';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymPlanTimeRestriction from '@/typings/backend/jsonApiTranslations/gymPlanTimeRestriction';
import deepClone from 'lodash.clonedeep';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import EditGymPlanTimeRestrictionComponent from '@/components/gymPlanTimeRestrictions/EditGymPlanTimeRestriction.vue';
import GymPlanTimeRestrictionRepository from '@/repository/GymPlanTimeRestriction';
import { Loaders } from '@/typings/loaders';
import AddGymPlanTimeRestrictionComponent from '@/components/gymPlanTimeRestrictions/AddGymPlanTimeRestriction.vue';
import TimeRestriction from '@/typings/nativeEntities/plans/timeRestriction';
import { AxiosResponse } from 'axios';
import GymPlanTimeRestrictionService from '@/services/api/GymPlanTimeRestrictionService';
import VueMoment from 'vue-moment';
import Dialogs from '@/typings/Dialogs';
import EditGymPlanDialog from '@/components/gymPlans/EditGymPlanDialog.vue';
import GymPlanPriceService from '@/services/gymPlan/GymPlanPriceService';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';

const gymPlanStoreModule = namespace('gymPlans');

@Component({
  components: {
    'delete-time-restriction-confirmation': ConfirmationDialog,
    'edit-time-restriction': EditGymPlanTimeRestrictionComponent,
    'add-time-restriction': AddGymPlanTimeRestrictionComponent,
    EditGymPlanDialog,
  },
})
export default class GymPlanCard extends Vue {
  @Prop({ required: true })
  gymPlan!: GymPlan;

  @gymPlanStoreModule.Getter(GymPlansStoreGetters.GET_GYM_PLANS) gymPlans!: GymPlan[];

  @gymPlanStoreModule.Mutation [GymPlanStoreMutations.SET_GYM_PLANS]: Function;

  @gymPlanStoreModule.Mutation [GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN]: (
    gymPlan: GymPlan | null,
  ) => void;

  @gymPlanStoreModule.Mutation [GymPlanStoreMutations.SET_ACTIVE_DELETE_PLAN]: (
    gymPlan: GymPlan | null,
  ) => void;

  public timePeriodMapped: {[key: number]: string} = {
    [TimePeriod.DAY]: 'Day',
    [TimePeriod.WEEK]: 'Week',
    [TimePeriod.MONTH]: 'Month',
    [TimePeriod.YEAR]: 'Year',
  };

  public deletingTimeRestriction: GymPlanTimeRestriction = {} as GymPlanTimeRestriction;

  public modals: Dialogs = {
    deletingTimePeriod: false,
    editingTimeRestriction: false,
    addingTimeRestriction: false,
  };

  public loaders: Loaders = {
    editingTimeRestriction: false,
    addingTimeRestriction: false,
  };

  public editingTimeRestriction: GymPlanTimeRestriction = {} as GymPlanTimeRestriction;

  public gymPriceService = GymPlanPriceService;

  public onClickDeleteTimeRestriction(timeRestriction: GymPlanTimeRestriction): void {
    this.deletingTimeRestriction = timeRestriction;
    this.modals.deletingTimePeriod = true;
  }

  public onDeleteTimeRestriction(): void {
    GymPlanTimeRestrictionService
      .deleteGymPlanTimeRestriction(this.deletingTimeRestriction)
      .then(() => {
        const gymPlanToEdit: GymPlan = deepClone(this.gymPlan);

        // Delete the time restriction from this Gym Plan
        gymPlanToEdit.timeRestrictions.splice(
          gymPlanToEdit.timeRestrictions.findIndex(
            timeRestriction => timeRestriction.id === this.deletingTimeRestriction.id,
          ),
          1,
        );

        this.$emit('update', gymPlanToEdit);
        this.modals.deletingTimePeriod = false;
        this.$toasted.show('Time restriction has been successfully deleted');
      });
  }

  public onEditPlanGymTimeRestrictionClick(timeRestriction: GymPlanTimeRestriction): void {
    this.editingTimeRestriction = timeRestriction;
    this.modals.editingTimeRestriction = true;
  }

  public onTimeRestrictionUpdate(
    { plansRestriction }: { plansRestriction: GymPlanTimeRestriction },
  ): void {
    const gymPlanTimeRestrictionRepository: GymPlanTimeRestrictionRepository = (
      new GymPlanTimeRestrictionRepository()
    );

    this.loaders.editingTimeRestriction = true;
    gymPlanTimeRestrictionRepository
      .edit({ timeRestriction: plansRestriction, gymPlan: this.gymPlan })
      .then((response) => {
        const gymPlanToEdit = deepClone(this.gymPlan);

        gymPlanToEdit.timeRestrictions.splice(
          gymPlanToEdit.timeRestrictions.findIndex(
            tr => tr.id === plansRestriction.id,
          ),
          1,
          response.data,
        );

        this.$emit('update', gymPlanToEdit);
        this.$toasted.show('Plan restriction has been successfully edited');
        this.modals.editingTimeRestriction = false;
      })
      .finally(() => {
        this.loaders.editingTimeRestriction = false;
      });
  }

  public formatTime(time: string): string {
    return this.$moment(time, 'HH:mm:ss').format('HH:mm');
  }

  public onClickAddTimeRestrictionButton(): void {
    this.modals.addingTimeRestriction = true;
  }

  public onTimeRestrictionCreate(
    { timeRestriction }: { timeRestriction: TimeRestriction },
  ): void {
    const gymPlanTimeRestrictionRepository: GymPlanTimeRestrictionRepository = (
      new GymPlanTimeRestrictionRepository({})
    );

    this.loaders.addingTimeRestriction = true;
    gymPlanTimeRestrictionRepository
      .create({ gymPlan: this.gymPlan, timeRestriction })
      .then((response: AxiosResponse<GymPlanTimeRestriction>) => {
        const gymPlanToEdit: GymPlan = deepClone(this.gymPlan);

        if (gymPlanToEdit.timeRestrictions) {
          gymPlanToEdit.timeRestrictions.push(response.data);
        } else {
          gymPlanToEdit.timeRestrictions = [response.data];
        }

        this.$emit('update', gymPlanToEdit);

        this.$toasted.show('Time restriction has been added successfully');
        this.modals.addingTimeRestriction = false;
      })
      .finally(() => {
        this.loaders.addingTimeRestriction = false;
      });
  }

  public onClickSelectPlan(): void {
    this[GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN](this.gymPlan);
  }

  public onDeletePlan(): void {
    this[GymPlanStoreMutations.SET_ACTIVE_DELETE_PLAN](this.gymPlan);
  }
}

