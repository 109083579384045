import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import store from '@/store';
import AxiosResponse from '@/typings/backend/AxiosResponse';

const BASE_URL = 'gym-attendee-notes';

export default {
  list(params: JsonApiConfiguration = {}) {
    return axios.get(BASE_URL, { params });
  },
  create(payload: any, params: JsonApiConfiguration = {}) {
    return axios.post(BASE_URL, payload, { params });
  },
  delete(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
};
