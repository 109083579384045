import Address from '@/typings/nativeEntities/adress/Address';
import { AxiosResponse } from 'axios';
import AddressTranslated from '@/typings/backend/jsonApiTranslations/address';
import BaseRepository from '@/repository/BaseRepository';
import AddressApiService from '@/services/api/AddressApiService';

class AddressRepository extends BaseRepository {
  public async create(
    { address }: { address: Address },
  ): Promise<AxiosResponse<AddressTranslated>> {
    return AddressApiService.postAddress(
      address,
      this.getParameters('createAddress'),
    );
  }

  public async edit(
    { address }: { address: AddressTranslated },
  ): Promise<AxiosResponse<AddressTranslated>> {
    return AddressApiService.editAddress(
      address,
      this.getParameters('editAddress'),
    );
  }
}

export default AddressRepository;
