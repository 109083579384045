var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{staticClass:"create-event__date__column",attrs:{"md":"3","sm":"12","cols":"sm"}},[_c('h3',{staticClass:"create-event__date__title"},[_vm._v("From")]),_c('v-menu',{ref:"dateFrom",attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.startDateFormatted,"label":"Date *","readonly":"","outlined":""}},on))]}}]),model:{value:(_vm.modals.dateFrom),callback:function ($$v) {_vm.$set(_vm.modals, "dateFrom", $$v)},expression:"modals.dateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.event.startDate),callback:function ($$v) {_vm.$set(_vm.event, "startDate", $$v)},expression:"event.startDate"}})],1)],1),_c('v-col',{staticClass:"create-event__date__column create-event__date__column--no-title",attrs:{"md":"3","sm":"12","cols":"sm"}},[_c('v-menu',{ref:"timeFrom",attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.startTime},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.event.startTime,"label":"Time *","readonly":"","outlined":""}},on))]}}]),model:{value:(_vm.modals.timeFrom),callback:function ($$v) {_vm.$set(_vm.modals, "timeFrom", $$v)},expression:"modals.timeFrom"}},[(_vm.modals.timeFrom)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","no-title":""},on:{"click:minute":function($event){return _vm.$refs.timeFrom.save(_vm.event.startTime)}},model:{value:(_vm.event.startTime),callback:function ($$v) {_vm.$set(_vm.event, "startTime", $$v)},expression:"event.startTime"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"create-event__date__column",attrs:{"md":"3","sm":"12","cols":"sm"}},[_c('h3',{staticClass:"create-event__date__title"},[_vm._v("To")]),_c('v-menu',{ref:"dateTo",attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.endDateFormatted,"label":"Date *","readonly":"","outlined":"","data-jest-end-date":""}},on))]}}]),model:{value:(_vm.modals.dateTo),callback:function ($$v) {_vm.$set(_vm.modals, "dateTo", $$v)},expression:"modals.dateTo"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedToDates},model:{value:(_vm.event.endDate),callback:function ($$v) {_vm.$set(_vm.event, "endDate", $$v)},expression:"event.endDate"}})],1)],1),_c('v-col',{staticClass:"create-event__date__column create-event__date__column--no-title",attrs:{"md":"3","sm":"12","cols":"sm"}},[_c('v-menu',{ref:"timeTo",attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.endTime},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "endTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "endTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.event.endTime,"label":"Time *","readonly":"","outlined":"","data-jest-end-time":""}},on))]}}]),model:{value:(_vm.modals.timeTo),callback:function ($$v) {_vm.$set(_vm.modals, "timeTo", $$v)},expression:"modals.timeTo"}},[(_vm.modals.timeTo)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","no-title":"","allowed-hours":_vm.timeToAllowedHours,"allowed-minutes":_vm.timeToAllowedMinutes},on:{"click:minute":function($event){return _vm.$refs.timeTo.save(_vm.event.endTime)}},model:{value:(_vm.event.endTime),callback:function ($$v) {_vm.$set(_vm.event, "endTime", $$v)},expression:"event.endTime"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }