























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import IGlobalSnackBar from '@/typings/nativeEntities/GlobalSnackbar';
import UserStoreMutations from '@/constants/store/modules/users/mutations';

const userStoreModule = namespace('users');

@Component({})
export default class GlobalSnackbar extends Vue {
  @userStoreModule.Getter(
    UsersStoreGetters.GET_GLOBAL_SNACKBAR,
  ) globalSnackbar!: IGlobalSnackBar | null;

  @userStoreModule.Mutation [UserStoreMutations.SET_GLOBAL_SNACKBAR]: (
    gsb: IGlobalSnackBar | null
  ) => void;

  get snackbar(): boolean {
    return !!this.globalSnackbar;
  }

  public onSnackbarChange(value: boolean): void {
    if (!value) {
      this[UserStoreMutations.SET_GLOBAL_SNACKBAR](null);
    }
  }
}
