import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import NewPlan from '@/typings/nativeEntities/plans/newPlan';
import GymTranslated from '@/typings/backend/jsonApiTranslations/gym';
import GymsPlanStoreState from '@/typings/storeStates/gymsPlan';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import GymPlanTranslated from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymPlanApiService from '@/services/api/GymPlanApiService';

export default {
  async [GymPlansStoreActions.POST_GYM_PLAN](
    { commit }: { commit: Function },
    { newPlan, params, gym }: {
      newPlan: NewPlan,
      params: JsonApiConfiguration,
      gym: GymTranslated
    },
  ): Promise<AxiosResponse<GymPlanTranslated>> {
    const payload = {
      data: {
        type: 'gym-plans',
        attributes: {
          name: newPlan.name,
          'plan-type': newPlan.planDuration.timePeriod,
          quantity: newPlan.planDuration.times,
          'participation-number': newPlan.timeAttendeeCanAttend,
          price: newPlan.price,
        },
        relationships: {
          gym: {
            data: { type: 'gyms', id: gym.id },
          },
        },
      },
    };

    return GymPlanApiService
      .post(payload, params)
      .then((response: AxiosResponse<GymPlanTranslated>) => response);
  },

  async [GymPlansStoreActions.GET_GYM_PLANS](
    { commit, state }: { commit: Function, state: GymsPlanStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<GymPlanTranslated[]>> {
    return GymPlanApiService
      .list(params)
      .then((response: AxiosResponse<GymPlanTranslated[]>) => {
        commit(GymPlanStoreMutations.SET_GYM_PLANS, response.data);

        return response;
      });
  },

  async [GymPlansStoreActions.EDIT_GYM_PLAN](
    { commit, state }: { commit: Function, state: GymsPlanStoreState },
    { payload, params, gymPlan }: {
      payload: any,
      params: JsonApiConfiguration,
      gymPlan: GymPlanTranslated
    },
  ): Promise<AxiosResponse<GymPlanTranslated>> {
    return GymPlanApiService
      .edit(gymPlan.id, payload, params)
      .then((response) => {
        const gymPlans = [...state.gymPlans];

        gymPlans.splice(
          gymPlans.findIndex(gp => gp.id === gymPlan.id),
          1,
          response.data,
        );

        commit(GymPlanStoreMutations.SET_GYM_PLANS, gymPlans);

        return response;
      });
  },
  async [GymPlansStoreActions.DELETE_GYM_PLAN](
    { commit, state }: { commit: Function, state: GymsPlanStoreState },
    { gymPlan }: { gymPlan: GymPlanTranslated },
  ) {
    return GymPlanApiService
      .delete(gymPlan.id)
      .then((response) => {
        commit(
          GymPlanStoreMutations.SET_GYM_PLANS,
          state.gymPlans.filter(p => p.id !== gymPlan.id),
        );

        return response;
      });
  },
};
