import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import AttendeesStoreState from '@/typings/storeStates/attendees';

export default {
  [AttendeeStoreGetters.GET_ATTENDEE_GYM_PLANS]: (
    state: AttendeesStoreState,
  ) => state.attendeeGymPlans,
  [AttendeeStoreGetters.ACTIVE_ATTENDEE]: (state: AttendeesStoreState) => state.activeAttendee,
  [AttendeeStoreGetters.IS_EDITING_ATTENDEE]: (
    state: AttendeesStoreState,
  ) => state.isEditingActiveAttendee,
  [AttendeeStoreGetters.ATTENDEE_NOTES]: (state: AttendeesStoreState) => state.attendeeNotes,
  [AttendeeStoreGetters.ATTENDEE_GYM_RELATIONSHIP]: (
    state: AttendeesStoreState,
  ) => state.activeAttendeeGymRelationship,
  [AttendeeStoreGetters.GET_ATTENDEE_CHECK_INS]: (
    state: AttendeesStoreState,
  ) => state.attendeeCheckIns,
  [AttendeeStoreGetters.ACTIVE_ATTENDEE_IS_CHECKED_IN]: (
    state: AttendeesStoreState,
  ) => state.activeAttendeeCheckedInPlan !== null,
  [AttendeeStoreGetters.ACTIVE_ATTENDEE_CHECKED_IN_PLAN]: (
    state: AttendeesStoreState,
  ) => state.activeAttendeeCheckedInPlan,
};
