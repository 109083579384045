




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Loaders } from '@/typings/loaders';
import EmployeeInvitationApiService from '@/services/api/EmployeeInvitationApiService';

@Component({})
export default class AcceptInvitationAsEmployee extends Vue {
  public loaders: Loaders = {
    acceptingInvitation: true,
  };

  public async created(): Promise<void> {
    const { data } = (
      await EmployeeInvitationApiService.readEmployeeInvitationsChecks(
        this.$route.params.invitationId,
      )
    );

    if (data.hasPassword) {
      // if the user who is associated with this invitation id has the password set
      // redirect to login page
      await EmployeeInvitationApiService.postAcceptInvitation(this.$route.params.invitationId);
      await this.$router.push({ name: 'login' });

      return;
    }


    // if the user who is associated with this invitation id doesn't have the password set
    // send him to the route that will set up his account
    await this.$router.push({ name: 'createNewUserFromInvitation', params: this.$route.params });
  }
}
