import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import EventsStoreState from '@/typings/storeStates/events';
import Event from '@/typings/backend/jsonApiTranslations/event';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import EventFactory from '@/services/entityFactory/EventFactory';
import Modals from '@/constants/Modals';
import Vue from 'vue';
import PrivateGymTraining from '@/typings/backend/jsonApiTranslations/PrivateGymTraining';

export default {
  [EventsStoreMutations.SET_EVENTS](state: EventsStoreState, events: Event[]) {
    state.events = events;
  },
  [EventsStoreMutations.SET_ACTIVE_PRIVATE_TRAINING](
    state: EventsStoreState, privateTraining: PrivateGymTraining | null,
  ) {
    state.activePrivateTraining = privateTraining;
  },
  [EventsStoreMutations.SET_PRIVATE_GYM_TRAININGS](
    state: EventsStoreState, trainings: PrivateGymTraining[],
  ) {
    state.privateTrainings = trainings;
  },
  [EventsStoreMutations.SET_MODAL](
    state: EventsStoreState,
    data: { modal: Modals, value: boolean },
  ) {
    Vue.set(state.modals, data.modal, data.value);
  },
  [EventsStoreMutations.SET_ACTIVE_EVENT](state: EventsStoreState, event: Event | null) {
    state.activeEvent = event;
  },
  [EventsStoreMutations.SET_EDIT_CREATE_EVENT](
    state: EventsStoreState,
    event: Event | EventFactory | null,
  ) {
    state.editCreateEvent = event;
  },
  [EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES](
    state: EventsStoreState,
    attendees: Attendee[],
  ) {
    state.activeEventAttendees = attendees;
  },
};
