import LoginView from '@/views/auth/Login.vue';
import RegisterView from '@/views/auth/Register.vue';
import CreateCompanyView from '@/views/auth/CreateCompany.vue';
import CheckUserLoggedInService from '@/services/auth/CheckUserLoggedInService';
import ResetPassword from '@/views/auth/ResetPassword.vue';

const checkUserLoggedInService = new CheckUserLoggedInService();
const redirectIfLoggedIn = (to: any, from: any, next: Function) => {
  if (checkUserLoggedInService.isLoggedIn()) {
    next({ name: 'companyDashboard' });
  } else {
    next();
  }
};

export default [
  {
    path: '',
    redirect: 'login',
  },
  {
    path: 'login',
    component: LoginView,
    name: 'login',
    meta: {
      needsAuthentication: false,
    },
    beforeEnter: redirectIfLoggedIn,
  },
  {
    path: 'register',
    component: RegisterView,
    name: 'register',
    meta: {
      needsAuthentication: false,
    },
    beforeEnter: redirectIfLoggedIn,
  },
  {
    path: 'reset-password',
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      needsAuthentication: false,
    },
    beforeEnter: redirectIfLoggedIn,
  },
  {
    path: 'register/create-company',
    name: 'registerCompanyAfterRegister',
    component: CreateCompanyView,
  },
];
