const toCamel = (str: string) => str.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

const isObject = (obj: any) => obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';

// @ts-ignore
const RecursivelyCamelCaseObject = (obj: any) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj)
      .forEach((k) => {
        // @ts-ignore
        n[toCamel(k)] = RecursivelyCamelCaseObject(obj[k]);
      });

    return n;
  } if (Array.isArray(obj)) {
    return obj.map(i => RecursivelyCamelCaseObject(i));
  }

  return obj;
};

export default RecursivelyCamelCaseObject;
