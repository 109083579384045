













































































































import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import NewGym from '@/typings/nativeEntities/gyms/newGym';
import { namespace } from 'vuex-class';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Country from '@/typings/backend/jsonApiTranslations/country';

const countryStoreModule = namespace('countries');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CreateGym extends Vue {
  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public gym: NewGym = {
    name: '',
    email: '',
    address: {
      name: '',
      country: null,
    },
  };

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  };

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public handleClickCreateGymButton(): void {
    this
      .$refs
      .validator
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) {
          return;
        }

        this.$emit('gym:create', { gym: this.gym });
      });
  }

  public resetInput(): void {
    const newGym: NewGym = { ...this.gym };

    newGym.address.name = '';
    newGym.name = '';
    newGym.email = '';
    newGym.address.country = null;

    this.gym = newGym;
    this.$nextTick(() => {
      this.$refs.validator.reset();
    });
  }
}
