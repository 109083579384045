import axios from '@/plugins/axios/jsonApi';
import NewAddress from '@/typings/nativeEntities/adress/Address';
import { AxiosResponse } from 'axios';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import Address from '@/typings/backend/jsonApiTranslations/address';

const BASE_URL = 'addresses';

export default {
  postAddress(address: NewAddress, params?: JsonApiConfiguration) {
    const payload = {
      data: {
        type: 'addresses',
        attributes: {
          name: address.name,
        },
        relationships: {
          country: {
            data: {
              type: 'countries',
              id: address.country!.id,
            },
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params })
      .then((response: AxiosResponse) => response);
  },
  editAddress(address: Address, params?: JsonApiConfiguration) {
    const payload = {
      data: {
        type: 'addresses',
        id: address.id,
        attributes: {
          name: address.name,
        },
        relationships: {
          country: {
            data: {
              type: 'countries',
              id: address.country.id,
            },
          },
        },
      },
    };

    return axios.patch(`${BASE_URL}/${address.id}`, payload, { params });
  },
};
