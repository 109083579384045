export default [
  {
    text: 'First Name',
    value: 'firstName',
  },
  {
    text: 'Last Name',
    value: 'lastName',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Phone Numbers',
    value: 'phoneNumbers',
  },
  {
    text: 'Address',
    value: 'address',
  },
  {
    text: '',
    value: 'actions',
  },
];
