import EventDefinition from '@/typings/nativeEntities/event/eventDefinition';
import EventFrequencyType from '@/constants/event/frequency/EventFrequencyType';
import EventFrequencyEndType from '@/constants/event/frequency/EndType';

interface EventDefinitionWithText extends EventDefinition {
  text: string;
}

const eventRepetitionOptions: EventDefinitionWithText[] = [
  {
    text: 'Do not repeat',
    frequency: 0,
    frequencyType: EventFrequencyType.DAY,
    endType: EventFrequencyEndType.NEVER,
    end: '',
    endedAt: '',
  },
  {
    text: 'Repeat every day',
    frequency: 1,
    frequencyType: EventFrequencyType.DAY,
    endType: EventFrequencyEndType.NEVER,
    // this doesn't matter but its required
    end: '2040-02-31',
    endedAt: '',
  },
  {
    text: 'Repeat every week on this day',
    frequency: 1,
    frequencyType: EventFrequencyType.WEEK,
    endType: EventFrequencyEndType.NEVER,
    // this doesn't matter but its required
    end: '2040-02-31',
    endedAt: '',
  },
  {
    text: 'Repeat every 15 days on this day',
    frequency: 2,
    frequencyType: EventFrequencyType.WEEK,
    endType: EventFrequencyEndType.NEVER,
    // this doesn't matter but its required
    end: '2040-02-31',
    endedAt: '',
  },
  {
    text: 'Repeat every month on this day',
    frequency: 1,
    frequencyType: EventFrequencyType.MONTH,
    endType: EventFrequencyEndType.NEVER,
    // this doesn't matter but its required
    end: '2040-02-31',
    endedAt: '',
  },
  {
    text: 'Repeat every year on this day',
    frequency: 1,
    frequencyType: EventFrequencyType.YEAR,
    endType: EventFrequencyEndType.NEVER,
    // this doesn't matter but its required
    end: '2040-02-31',
    endedAt: '',
  },
];

export default eventRepetitionOptions;
