import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
import EventFrequencyEndType from '@/constants/event/frequency/EndType';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import Event from '@/typings/backend/jsonApiTranslations/event';

class EventPayloadBuilderDirector {
  private builder = new PayloadBuilder('event-definitions');

  public create(event: CreateEventFactory) {
    const cEvent = cloneDeep(event);
    const startDateMoment = moment(cEvent.start);
    const endDateMoment = moment(cEvent.end);

    const payload: any = this
      .builder
      .setAttributes({
        name: cEvent.name,
        'max-attendees': cEvent.maxAttendees,
        'email-notification': cEvent.emailNotification,
        'day-count': cEvent.dayCount,
        'start-date': startDateMoment.format('YYYY-MM-DD'),
        'start-time': startDateMoment.format('HH:mm:ss'),
        'end-time': endDateMoment.format('HH:mm:ss'),
        color: cEvent.color,
        description: cEvent.description,
        repetition: {
          frequency: cEvent.repetition.frequency,
          frequency_type: cEvent.repetition.frequencyType,
          end_type: cEvent.repetition.endType,
          end: cEvent.repetition.end,
          ended_at: cEvent.repetition.endedAt,
        },
      })
      .setRelationship(
        'activityGroupGuests',
        cEvent.activityGroupGuests.map(a => ({ id: a.id, type: 'activity-groups' })),
      )
      .setRelationship(
        'attendeeGuests',
        cEvent.attendeeGuests.map(a => ({ id: a.id, type: 'attendees' })),
      )
      .setRelationship(
        'gym',
        { id: cEvent.gym.id, type: 'gyms' },
      )
      .resolvePayload();

    if (event.repetition.endType === EventFrequencyEndType.NEVER) {
      payload.data.attributes.repetition.end = null;
    }

    if (event.repetition.frequency === 0) {
      // @ts-ignore
      payload.data.attributes.repetition = null;
    }

    this.builder.resetPayload();

    return payload;
  }

  public update(event: Event) {
    this.builder.setResourceType('events');

    let payload: any = this
      .builder
      .setId(event.id)
      .setAttributes({
        name: event.name,
        'max-attendees': event.maxAttendees,
        start: event.start,
        end: event.end,
        color: event.color,
        description: event.description,
      });

    if (event.activityGroupGuests) {
      payload.setRelationship(
        'activityGroupGuests',
        event.activityGroupGuests.map(a => ({ id: a.id, type: 'activity-groups' })),
      );
    }

    if (event.attendeeGuests) {
      payload.setRelationship(
        'attendeeGuests',
        event.attendeeGuests.map(a => ({ id: a.id, type: 'attendees' })),
      );
    }

    payload = payload.resolvePayload();
    this.builder.resetPayload();

    return payload;
  }
}

export default EventPayloadBuilderDirector;
