









































import { Prop, Vue, Component } from 'vue-property-decorator';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import EditActivityGroupCard from '@/components/activityGroup/EditActivityGroupCard.vue';
import { Loaders } from '@/typings/loaders';
import ActivityGroupRepository from '@/repository/ActivityGroup';
import ParametersMap from '@/typings/repository/parametersMap';

@Component({
  components: {
    'edit-activity-group': EditActivityGroupCard,
  },
})
export default class ActivityGroupCard extends Vue {
  @Prop({ required: true, type: Object as () => ActivityGroup })
  activityGroup!: ActivityGroup;

  public menus: {[key: string]: boolean} = {
    editingActivityGroup: false,
  };

  public loaders: Loaders = {
    editingActivityGroup: false,
  };

  public onActivityGroupUpdate({ activityGroup }: { activityGroup: ActivityGroup }): void {
    this.loaders.editingActivityGroup = true;

    const params: ParametersMap = {
      editActivityGroups: {
        include: ['gym', 'createdBy', 'trainers'],
      },
    };
    const activityGroupRepository: ActivityGroupRepository = (
      new ActivityGroupRepository(params)
    );

    activityGroupRepository
      .edit({ activityGroup })
      .then(() => {
        this.$emit('update', activityGroup);
        this.$toasted.show('Activity Group has been updated successfully');
        this.menus.editingActivityGroup = false;
      })
      .finally(() => {
        this.loaders.editingActivityGroup = false;
      });
  }
}
