import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import NewThirdPartyPayingContact
  from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPayingContact';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import { AxiosResponse } from 'axios';
import ThirdPartyPayingContact from '@/typings/backend/jsonApiTranslations/thirdPartyPayingContact';

const BASE_URL = 'paying-group-contacts';

export default {
  postThirdPartyPayingContact(
    contact: NewThirdPartyPayingContact,
    thirdPartyPaying: ThirdPartyPaying,
    params: JsonApiConfiguration = {},
  ) {
    const payload = {
      data: {
        type: 'paying-group-contacts',
        attributes: {
          person: contact.personName,
          email: contact.email,
        },
        relationships: {
          payingGroup: {
            data: { type: 'paying-groups', id: thirdPartyPaying.id },
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params })
      .then((response: AxiosResponse) => response);
  },

  editThirdPartyPayingContact(
    thirdPartyPayingContact: ThirdPartyPayingContact,
    params: JsonApiConfiguration = {},
  ) {
    const payload = {
      data: {
        id: thirdPartyPayingContact.id,
        type: 'paying-group-contacts',
        attributes: {
          person: thirdPartyPayingContact.person,
          email: thirdPartyPayingContact.email,
        },
      },
    };

    return axios
      .patch(`${BASE_URL}/${thirdPartyPayingContact.id}`, payload, { params })
      .then((response: AxiosResponse<ThirdPartyPayingContact>) => response);
  },

  deleteThirdPartyPayingContact(thirdPartyPayingContact: ThirdPartyPayingContact) {
    return axios
      .delete(`${BASE_URL}/${thirdPartyPayingContact.id}`)
      .then((response: AxiosResponse<null>) => response);
  },
};
