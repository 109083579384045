import axios from '@/plugins/axios/jsonApi';
import { AxiosResponse } from 'axios';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import NewActivityGroup from '@/typings/nativeEntities/activityGroups/newActivityGroup';
import GymTranslated from '@/typings/backend/jsonApiTranslations/gym';
import RootStoreState from '@/typings/storeStates/rootState';
import ActivityGroupTranslated from '@/typings/backend/jsonApiTranslations/activityGroup';
import ActivityGroupsStoreMutations from '@/constants/store/modules/activityGroups/mutations';
import ActivityGroupsStoreState from '@/typings/storeStates/activityGroups';
import ActivityGroupApiService from '@/services/api/ActivityGroupApiService';

const BASE_URL = 'activity-groups';

export default {
  async [ActivityGroupsStoreActions.POST_NEW_ACTIVITY_GROUP](
    { commit, rootState }: { commit: Function, rootState: RootStoreState },
    { newGroupActivity, params, gym }: {
      newGroupActivity: NewActivityGroup,
      params: JsonApiConfiguration,
      gym: GymTranslated,
    },
  ): Promise<AxiosResponse<ActivityGroupTranslated>> {
    const payload = {
      data: {
        type: 'activity-groups',
        attributes: {
          name: newGroupActivity.name,
          description: newGroupActivity.description,
        },
        relationships: {
          gym: {
            data: {
              type: 'gyms',
              id: gym.id,
            },
          },
          createdBy: {
            data: {
              type: 'users',
              id: rootState.users.user.id,
            },
          },
          trainers: {
            data: newGroupActivity.trainers.map(user => ({ type: 'users', id: user.id })),
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params });
  },

  async [ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<ActivityGroupTranslated[]>> {
    return ActivityGroupApiService
      .list(params)
      .then((response: AxiosResponse<ActivityGroupTranslated[]>) => {
        commit(ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS, response.data);

        return response;
      });
  },

  async [ActivityGroupsStoreActions.DELETE_ACTIVITY_GROUP](
    { commit, state }: { commit: Function, state: ActivityGroupsStoreState },
    { activityGroup }: { activityGroup: ActivityGroupTranslated },
  ): Promise<AxiosResponse<null>> {
    return axios
      .delete(`${BASE_URL}/${activityGroup.id}`)
      .then((response: AxiosResponse<null>) => {
        const activityGroups: ActivityGroupTranslated[] = [...state.activityGroups];

        activityGroups.splice(
          activityGroups.findIndex(ag => ag.id === activityGroup.id),
          1,
        );

        commit(ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS, activityGroups);

        return response;
      });
  },

  async [ActivityGroupsStoreActions.EDIT_ACTIVITY_GROUP](
    { commit, state }: { commit: Function, state: ActivityGroupsStoreState },
    { activityGroup, params }: {
      activityGroup: ActivityGroupTranslated,
      params: JsonApiConfiguration
    },
  ): Promise<AxiosResponse<ActivityGroupTranslated>> {
    const payload = {
      data: {
        type: 'activity-groups',
        id: activityGroup.id,
        attributes: {
          name: activityGroup.name,
          description: activityGroup.description,
        },
        relationships: {
          gym: { data: { type: 'gyms', id: activityGroup.gym.id } },
        },
      },
    };

    if (activityGroup.trainers) {
      // @ts-ignore
      payload.data.relationships.trainers = {
        data: activityGroup.trainers.map(user => ({ type: 'users', id: user.id })),
      };
    }

    return axios.patch(`${BASE_URL}/${activityGroup.id}`, payload, { params });
  },
};
