export default {
  backgroundColor: 'transparent',
  colors: ['cornflowerblue', 'tomato'],
  fontName: 'Muli',
  focusTarget: 'category',
  chartArea: {
    left: 50,
    top: 10,
    width: '100%',
  },
  hAxis: {
    // showTextEvery: 12,
    textStyle: {
      fontSize: 11,
      fontName: '"Muli", sans-serif',
    },
    baselineColor: 'transparent',
    gridlines: {
      color: 'red',
    },
    textPosition: 'center',
  },
  vAxis: {
    format: '0',
    minValue: 0,
    baselineColor: '#DDD',
    gridlines: {
      color: '#DDD',
      count: 4,
    },
    textStyle: {
      fontSize: 11,
    },
  },
  legend: {
    position: 'bottom',
    textStyle: {
      fontSize: 12,
    },
  },
  animation: {
    duration: 800,
    easing: 'out',
    startup: true,
  },
};
