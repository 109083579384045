















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueMoment from 'vue-moment';
import TimeRestriction from '@/typings/nativeEntities/plans/timeRestriction';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class AddGymPlanTimeRestrictionComponent extends Vue {
  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public modals: { [key: string]: boolean } = {
    from: false,
    to: false,
  };

  public rangeTime: TimeRestriction = {
    from: '',
    to: '',
  };

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  get fromFormatted(): string {
    if (this.rangeTime.from === '') {
      return '';
    }

    return this
      .$moment(this.rangeTime.from, 'HH:mm:ss')
      .format('HH:mm');
  }

  get toFormatted(): string {
    if (this.rangeTime.to === '') {
      return '';
    }

    return this
      .$moment(this.rangeTime.to, 'HH:mm:ss')
      .format('HH:mm');
  }

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
    menuFrom: InstanceType<any>,
  };

  public handleClickSaveButton(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.$emit('time-restriction:create', { timeRestriction: this.rangeTime });
      });
  }
}
