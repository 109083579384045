












































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import cloneDeep from 'lodash.clonedeep';
import VueMoment from 'vue-moment';
import GymPlanTimeRestriction from '../../typings/backend/jsonApiTranslations/gymPlanTimeRestriction';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditGymPlanTimeRestrictionComponent extends Vue {
  @Prop({ required: true, type: Object as () => GymPlanTimeRestriction })
  gymPlanTimeRestriction!: GymPlanTimeRestriction;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public internalGymPlanTimeRestriction: GymPlanTimeRestriction = {} as GymPlanTimeRestriction;

  public modals: {[key: string]: boolean} = {
    from: false,
    to: false,
  };

  @Watch('gymPlanTimeRestriction')
  public watchGymPlanTimeRestriction(value: GymPlanTimeRestriction): void {
    this.internalGymPlanTimeRestriction = cloneDeep(value);
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  get fromFormatted(): string {
    return this
      .$moment(this.internalGymPlanTimeRestriction.from, 'HH:mm:ss')
      .format('HH:mm');
  }

  get toFormatted(): string {
    return this
      .$moment(this.internalGymPlanTimeRestriction.to, 'HH:mm:ss')
      .format('HH:mm');
  }

  public handleClickSaveButton(): void {
    this.$emit('time-restriction:update', {
      plansRestriction: this.internalGymPlanTimeRestriction,
    });
  }
}
