import AxiosConstructorContract from '@/typings/filters/axiosConstructor/AxiosConstructorContract';
import FilterRendererNumberRange from '@/typings/filters/types/FilterRendererNumberRange';
import AxiosFiltersObject from '@/typings/filters/axiosConstructor/AxiosFiltersObject';
import BaseFilterAxiosConstructor from '@/services/filters/axiosConstructor/BaseFilterAxiosConstructor';
import cloneDeep from 'lodash.clonedeep';

class FilterAxiosConstructorNumberRange
  extends BaseFilterAxiosConstructor
  implements AxiosConstructorContract<FilterRendererNumberRange> {
  public build(
    filter: FilterRendererNumberRange,
    axiosObject: AxiosFiltersObject,
  ): AxiosFiltersObject {
    if (this.returnOriginalAxiosObject(filter)) return axiosObject;

    const rangeName = this.getRangeName(filter.name);
    const CAxiosObject = cloneDeep(axiosObject);

    if (filter.data.from) {
      CAxiosObject[rangeName.from] = filter.data.from.toString();
    }

    if (filter.data.to) {
      CAxiosObject[rangeName.to] = filter.data.to.toString();
    }

    return CAxiosObject;
  }

  protected returnOriginalAxiosObject(filter: FilterRendererNumberRange): boolean {
    return !(filter.data.from || filter.data.to);
  }
}

export default FilterAxiosConstructorNumberRange;
