enum AttendeeStoreMutations {
  SET_ATTENDEES = 'SET_ATTENDEES',
  SET_ACTIVE_ATTENDEE = 'SET_ACTIVE_ATTENDEE',
  SET_ACTIVE_ATTENDEE_PROPERTY = 'SET_ACTIVE_ATTENDEE_PROPERTY',
  SET_ACTIVE_ATTENDEE_GYM_RELATIONSHIP = 'SET_ACTIVE_ATTENDEE_GYM_RELATIONSHIP',
  SET_ATTENDEE_CHECK_INS = 'SET_ATTENDEE_CHECK_INS',
  SET_ATTENDEE_CHECK_INS_PAGINATION = 'SET_ATTENDEE_CHECK_INS_PAGINATION',
  SET_ATTENDEE_NOTES = 'SET_ATTENDEE_NOTES',
  SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER',
  SET_ATTENDEE_GYM_PLANS = 'SET_ATTENDEE_GYM_PLANS',
  SET_CURRENT_CHECKED_IN_PLAN = 'SET_CURRENT_CHECKED_IN_PLAN',
  SET_IS_EDITING_ATTENDEE = 'SET_IS_EDITING_ATTENDEE',
}

export default AttendeeStoreMutations;
