var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-img',{staticClass:"mb-2",attrs:{"height":"auto","width":"100px","src":require("@/assets/logo-fitalic.svg")}})],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{staticClass:"elevation-2"},[_c('v-card-text',[_c('form',[_c('ValidationProvider',{attrs:{"name":"newUser.firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name","name":"first_name","type":"text","outlined":"","error-messages":errors},model:{value:(_vm.newUser.firstName),callback:function ($$v) {_vm.$set(_vm.newUser, "firstName", $$v)},expression:"newUser.firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"newUser.lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last name","name":"last_name","type":"text","outlined":"","error-messages":errors},model:{value:(_vm.newUser.lastName),callback:function ($$v) {_vm.$set(_vm.newUser, "lastName", $$v)},expression:"newUser.lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"newUser.email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","name":"email","type":"text","outlined":"","error-messages":errors},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"newUser.password","rules":"required|password:@confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","name":"password","outlined":"","type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm Password","name":"password","outlined":"","type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.newUser.confirmPassword),callback:function ($$v) {_vm.$set(_vm.newUser, "confirmPassword", $$v)},expression:"newUser.confirmPassword"}})]}}],null,true)})],1)]),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"text":"","to":{ name: 'login' },"color":"primary"}},[_vm._v("Login")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!valid,"loading":_vm.loaders.registering},on:{"click":_vm.createNewUser}},[_vm._v(" Register ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }