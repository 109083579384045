import axios from '@/plugins/axios/jsonApi';
import GymNotesStoreActions from '@/constants/store/modules/gymNotes/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymNoteTranslated from '@/typings/backend/jsonApiTranslations/gymNotes';
import GymNotesStoreState from '@/typings/storeStates/GymNotes';
import GymNotesStoreMutations from '@/constants/store/modules/gymNotes/mutations';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import RootStoreState from '@/typings/storeStates/rootState';

const BASE_URL = 'gym-notes';

export default {
  async [GymNotesStoreActions.GET_NOTES](
    { commit, state }: { commit: Function, state: GymNotesStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<GymNoteTranslated[]>> {
    return axios
      .get(BASE_URL, { params })
      .then((response: AxiosResponse<GymNoteTranslated[]>) => {
        commit(GymNotesStoreMutations.SET_NOTES, [
          ...state.gymNotes,
          ...response.data.reverse(),
        ]);

        return response;
      });
  },

  async [GymNotesStoreActions.POST_NOTE](
    { commit, state, rootState }: {
      commit: Function,
      state: GymNotesStoreState,
      rootState: RootStoreState,
    },
    { params, message, gym }: {
      params: JsonApiConfiguration,
      message: string,
      gym: Gym
    },
  ): Promise<AxiosResponse<GymNoteTranslated>> {
    const payload = {
      data: {
        type: 'gym-notes',
        attributes: {
          message,
        },
        relationships: {
          createdBy: {
            data: { type: 'users', id: rootState.users.user.id },
          },
          gym: {
            data: { type: 'gyms', id: gym.id },
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params })
      .then((response: AxiosResponse<GymNoteTranslated>) => {
        commit(GymNotesStoreMutations.SET_NOTES, [
          ...state.gymNotes,
          response.data,
        ]);

        return response;
      });
  },
};
