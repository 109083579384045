import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymAttendee from '@/typings/backend/jsonApiTranslations/GymAttendee';

const BASE_URL = 'gym-attendees';

export default {
  create(payload: any, params: JsonApiConfiguration = {}) {
    return axios.post(BASE_URL, payload, { params });
  },
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<GymAttendee[]>> {
    return axios.get(BASE_URL, { params });
  },
  patch(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<GymAttendee>> {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
};
