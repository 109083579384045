import AxiosConstructorContract from '@/typings/filters/axiosConstructor/AxiosConstructorContract';
import FilterRendererSelect from '@/typings/filters/types/FilterRendererSelect';
import BaseFilterAxiosConstructor from '@/services/filters/axiosConstructor/BaseFilterAxiosConstructor';
import AxiosFiltersObject from '@/typings/filters/axiosConstructor/AxiosFiltersObject';

class FilterAxiosConstructorSelect
  extends BaseFilterAxiosConstructor
  implements AxiosConstructorContract<FilterRendererSelect> {
  public build(filter: FilterRendererSelect, axiosFilters: AxiosFiltersObject) {
    if (this.returnOriginalAxiosObject(filter)) return axiosFilters;

    const filters = this.cloneFilters(axiosFilters);

    if (filter.componentOptions.multiple) {
      filters[filter.name] = (filter.data as any[]).map(item => item[filter.componentOptions['item-value']]);
    } else if (filter.data) {
      // @ts-ignore
      filters[filter.name] = filter.data[filter.componentOptions['item-value']];
    } else {
      filters[filter.name] = null;
    }

    return filters;
  }
}

export default FilterAxiosConstructorSelect;
