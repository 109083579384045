import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import RecursivelyCamelCaseObject from '@/services/object/RecursivelyCamelCaseObject';

const configuration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};

const instance = axios.create(configuration);

instance.interceptors.response.use(response => ({
  ...response,
  data: RecursivelyCamelCaseObject(response.data),
}), (error: AxiosError) => Promise.reject(error));

export default instance;
