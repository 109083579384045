import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';

const BASE_URL = 'private-gym-trainings';

export default {
  list(params: any) {
    return axios.get(`${BASE_URL}`, { params });
  },
  update(id: string, payload: any, params: JsonApiConfiguration) {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
};
