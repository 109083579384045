import StaffMemberListView from '@/views/management/staffMembersList/StaffMembersList.vue';
import StaffMemberNewView from '@/views/management/StaffMemberNew.vue';

export default [
  {
    path: 'staffMembers/list',
    component: StaffMemberListView,
    name: 'staffMembersList',
  },
  {
    path: 'staffMembers/new',
    component: StaffMemberNewView,
    name: 'staffMemberNew',
  },
];
