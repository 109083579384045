enum ListableItem {
  AttendeesMainTable = 'attendee-main-table',
  AttendeePlanLog = 'attendee-plan-log',
  AttendeeAttendanceLogs = 'attendee-attendance-log',
  ActivityGroupsMainTable = 'activity-group-main-table',
  GymPlanMainCards = 'gym-plan-main-cards',
  ThirdPartyPayingMainCards = 'third-party-paying-main-cards',
  EmployeesMainTable = 'employees-main-table',
}

export default ListableItem;
