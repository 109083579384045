


























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import cloneDeep from 'lodash.clonedeep';
import VueMoment from 'vue-moment';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import AttendeeGymPlansApiService from '@/services/api/AttendeeGymPlansApiService';
import { namespace } from 'vuex-class';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import AttendeeGymPlan from '../../typings/backend/jsonApiTranslations/AttendeeGymPlan';

const payloadBuilder = new PayloadBuilder('attendee-plans');
const attendeeStoreModule = namespace('attendees');

@Component({})
export default class EditAttendeeGymPlan extends Vue {
  @Prop({ required: true })
  attendeeGymPlan!: AttendeeGymPlan;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @attendeeStoreModule.Action[AttendeesStoreActions.UPDATE__PLAN_EXPIRING_DATE]: (
    { attendeeGymPlan, payload }: { attendeeGymPlan: AttendeeGymPlan, payload: any },
  ) => Promise<AxiosResponse>;

  public modals: {[k: string]: boolean} = {
    datePicker: false,
  }

  public internalAttendeeGymPlan: AttendeeGymPlan = {} as AttendeeGymPlan;

  get planExpiresInFuture(): boolean {
    if (this.attendeeGymPlan) {
      const momentNow = this.$moment();
      const momentPlanExpires = this.$moment(this.attendeeGymPlan.endedAt);

      return momentNow.isSameOrBefore(momentPlanExpires);
    }

    return false;
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public created(): void {
    let endedAtMoment = this.$moment(this.attendeeGymPlan.endedAt);
    const todayMoment = this.$moment();

    if (endedAtMoment.isBefore(todayMoment)) {
      endedAtMoment = todayMoment.add(1, 'days');
    }

    this.internalAttendeeGymPlan = cloneDeep({
      ...this.attendeeGymPlan,
      endedAt: endedAtMoment.format('YYYY-MM-DD'),
    });
  }

  public allowedToDates(endDate: string): boolean {
    const startDateMoment = this.planExpiresInFuture
      ? this.$moment(this.internalAttendeeGymPlan.endedAt)
      : this.$moment();
    const endDateMoment = this.$moment(endDate);

    return startDateMoment.isSameOrBefore(endDateMoment);
  }

  public async handleExtendPlanClick() {
    this.isVisible = false;
    const payload = payloadBuilder
      .setAttributes({ 'started-at': this.internalAttendeeGymPlan.endedAt })
      .setId(this.internalAttendeeGymPlan.id)
      .resolvePayload();

    try {
      const response = await this[AttendeesStoreActions.UPDATE__PLAN_EXPIRING_DATE]({
        payload,
        attendeeGymPlan: this.internalAttendeeGymPlan,
      });
      // @ts-ignore
      this.$toasted.show(`Plan extended to ${this.$moment(response.data.endedAt).format('LL')}`);
    } catch (e) {
      this.$toasted.error('Error extending plan');
    }
  }
}
