import TimePeriod from '@/constants/gymPlans/TimePeriod';

const TimePeriods = [
  { name: 'Day', value: TimePeriod.DAY },
  { name: 'Week', value: TimePeriod.WEEK },
  { name: 'Month', value: TimePeriod.MONTH },
  { name: 'Year', value: TimePeriod.YEAR },
];

export default TimePeriods;
