




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import Event from '@/typings/backend/jsonApiTranslations/event';
import EmailNotificationType from '@/constants/event/email/EmailNotificationType';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import eventRepetitionOptions from '@/constants/event/frequency/EventRepetitionOptions';
import { namespace } from 'vuex-class';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
// @ts-ignore
import Swatches from 'vue-swatches';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import debounce from 'lodash.debounce';
import EventDefinition from '../../../typings/nativeEntities/event/eventDefinition';

const eventStoreModule = namespace('event');

type IEvent = Pick<Event, 'emailNotification' | 'maxAttendees' | 'color'> & {
  repetition: EventDefinition
};

@Component({
  components: {
    ValidationProvider,
    Swatches,
  },
})
export default class EventDialogOptionsRow extends Vue {
  @eventStoreModule.Getter(
    EventsStoreGetters.GET_EDIT_CREATE_EVENT,
  ) currentEvent!: Event | CreateEventFactory | null;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_EDIT_CREATE_EVENT]: (
    event: Event | CreateEventFactory | null
  ) => void;

  @Watch('currentEvent', { deep: true })
  public watchCurrentEvent(event: Event | CreateEventFactory | null) {
    // eslint-disable-next-line no-useless-return
    if (event === null) return;

    // this.event.repetition = ;
    // this.event.description = event.description;
  }

  public event: IEvent = {
    color: '#e84b3c',
    maxAttendees: 10,
    emailNotification: EmailNotificationType.DONT_SEND,
    repetition: eventRepetitionOptions[0],
  }

  public repeatingOptions = Object.freeze(eventRepetitionOptions);

  public $refs!: {
    validation: InstanceType<typeof ValidationObserver>
  }

  public emailNotificationOptions = [
    { text: 'Do not send email notification', id: 1 },
    { text: 'Send now', id: 2 },
    { text: '2 hours before', id: 3 },
    { text: '24 hours before', id: 4 },
  ];

  public created() {
    this.$watch('event', debounce(this.watchEvent, 500), { deep: true });
  }

  public watchEvent(event: IEvent) {
    // @ts-ignore
    this[EventsStoreMutations.SET_EDIT_CREATE_EVENT]({
      ...this.currentEvent,
      ...event,
    });
  }
}
