import ActivityGroupsStoreMutations from '@/constants/store/modules/activityGroups/mutations';
import ActivityGroupsStoreState from '@/typings/storeStates/activityGroups';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';

export default {
  [ActivityGroupsStoreMutations.SET_ACTIVITY_GROUPS](
    state: ActivityGroupsStoreState, activityGroups: ActivityGroup[],
  ) {
    state.activityGroups = activityGroups;
  },
};
