import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';

const BASE_URL = 'attendee-plans';

export default {
  getAttendeeGymPlans(
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<AttendeeGymPlan[]>> {
    return axios
      .get(BASE_URL, { params });
  },
  postAttendeeGymPlan(
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<AttendeeGymPlan>> {
    return axios
      .post(BASE_URL, payload, { params });
  },
  extendGymPlan(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<AttendeeGymPlan>> {
    return axios
      .patch(`${BASE_URL}/${id}`, payload, { params });
  },
  deleteAttendeeGymPlan(
    id: string,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<null>> {
    return axios
      .delete(`${BASE_URL}/${id}`, { params });
  },
};
