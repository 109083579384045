

















































import {
  Prop, Component, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import Event from '@/typings/backend/jsonApiTranslations/event';
import EventsStoreActions from '@/constants/store/modules/events/actions';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import { Loaders } from '@/typings/loaders';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import RecurringEventDeleteOptions from '@/constants/event/RecurringEventDeleteOptions';

const eventStoreModule = namespace('event');

@Component
export default class DeleteEventDialog extends Vue {
  @eventStoreModule.Getter(EventsStoreGetters.GET_ACTIVE_EVENT) activeEvent!: Event

  @eventStoreModule.Mutation[EventsStoreMutations.SET_ACTIVE_EVENT]: (
    event: Event | null,
  ) => void;

  @eventStoreModule.Action [EventsStoreActions.DELETE_EVENT]: (
    { event, params, deleteType }: {
      event: Event,
      params?: JsonApiConfiguration,
      deleteType: RecurringEventDeleteOptions
    }
  ) => Promise<AxiosResponse<null>>;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  public loaders: Loaders = {
    deletingEvent: false,
  }

  public selectDeleteMode: RecurringEventDeleteOptions | null = null;

  public recurringEventDeleteOptions: typeof RecurringEventDeleteOptions = (
    RecurringEventDeleteOptions
  );

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    if (!newValue) this.selectDeleteMode = null;

    this.$emit('input', newValue);
  }

  public handleDeleteEvent(): void {
    this.loaders.deletingEvent = true;
    this[EventsStoreActions.DELETE_EVENT]({
      event: this.activeEvent!,
      deleteType: this.selectDeleteMode!,
    })
      .then(() => {
        this.$toasted.show('Event have been deleted successfully');
        this[EventsStoreMutations.SET_ACTIVE_EVENT](null);
        this.isVisible = false;
      })
      .finally(() => {
        this.loaders.deletingEvent = false;
      });
  }
}
