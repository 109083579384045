













































import { Component, Prop, Vue } from 'vue-property-decorator';
import FilterRow from '@/components/filters/layout/FilterRow.vue';
import { namespace } from 'vuex-class';
import CoreStoreMutations from '@/constants/store/modules/core/mutations';
import Filters from '@/components/filters/Filters.vue';
import CoreStoreActions from '@/constants/store/modules/core/actions';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import CoreStoreGetters from '@/constants/store/modules/core/getters';
import Page from '@/typings/backend/pagination/Page';
import { Loaders } from '@/typings/loaders';
import PaginationService from '@/services/pagination/PaginationService';
import AxiosFiltersObject from '@/typings/filters/axiosConstructor/AxiosFiltersObject';
import debounce from 'lodash.debounce';
import ListableItem from '@/constants/listableItems/ListableItem';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Location } from 'vue-router/types/router';
import FilterRenderers from '@/constants/filters/FilterRenderers';
import cloneDeep from 'lodash.clonedeep';
import JsonApiConfiguration from '../../typings/jsonApi/configuration/configuration';

const coreStoreModule = namespace('core');
const paginationService = new PaginationService(20, 1);

@Component({
  components: { Filters },
})
export default class ListableItems<I = any> extends Vue {
  @coreStoreModule.Mutation [CoreStoreMutations.SET_FILTERS_RENDERER]: (
    data: { filterRenderer: FilterRenderers, filters: FilterRow[] }
  ) => void;

  @coreStoreModule.Action [CoreStoreActions.GET_ITEMS]: (
    { service, params }: {
      service: { list: (p: JsonApiConfiguration) => AxiosResponse<I[]> },
      params: JsonApiConfiguration,
      listableItem: ListableItem,
    },
  ) => Promise<AxiosResponse<I[]>>;

  @coreStoreModule.Getter(
    CoreStoreGetters.LISTABLE_ITEMS_ITEMS,
  ) getItems!: (listableItem: ListableItem) => I[];

  @coreStoreModule.Getter(
    CoreStoreGetters.LISTABLE_ITEMS_PAGE,
  ) getPage!: (listableItem: ListableItem) => Page | null;

  @coreStoreModule.Mutation[CoreStoreMutations.SET_LISTABLE_ITEMS_RESPONSE]: (
    response: AxiosResponse<I[]> | null
  ) => void;

  @coreStoreModule.Getter(
    CoreStoreGetters.AXIOS_FILTERS,
  ) getActiveFilters!: (listableItem: FilterRenderers) => AxiosFiltersObject;

  @coreStoreModule.Mutation[CoreStoreMutations.RESET_LISTABLE_ITEMS]: (
    listableItem: ListableItem
  ) => void;

  @coreStoreModule.Mutation[CoreStoreMutations.RESET_FILTERS_RENDERER]: (
    rendererType: FilterRenderers,
  ) => void;

  @coreStoreModule.Action[CoreStoreActions.UPDATE_RESOURCE]: (
    { item, listableItem }: { item: I, listableItem: ListableItem, },
  ) => Promise<undefined>;

  @coreStoreModule.Action[CoreStoreActions.DELETE_RESOURCE]: (
    { item, listableItem }: { item: I, listableItem: ListableItem, },
  ) => Promise<undefined>;

  @Prop({ default: () => ({}) })
  jsonApiConfiguration!: JsonApiConfiguration;

  @Prop({ required: true })
  service!: { list: (params: JsonApiConfiguration) => AxiosResponse<I[]> };

  @Prop({ default: () => ([]) })
  filters!: FilterRow[];

  @Prop({ default: 'Page Title' })
  pageTitle!: string;

  @Prop({ required: true })
  listableItem!: ListableItem;

  @Prop({ default: false, type: Boolean })
  hidePageTitle!: boolean;

  @Prop({ default: null })
  createResourceRoute!: Location;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  public loaders: Loaders = {
    fetching: false,
    initial: true,
  }

  public debouncedFetchingItems = debounce(this.fetchList, 800);

  get activeFilters() {
    return this.getActiveFilters(this.filtersRendererType);
  }

  get pageObject(): Page | null {
    return this.getPage(this.listableItem);
  }

  get currentPage(): number | null {
    return this.pageObject ? this.pageObject.currentPage : 1;
  }

  get items(): I[] {
    return this.getItems(this.listableItem);
  }

  get length(): number {
    return this.pageObject
      ? this.pageObject.lastPage
      : 0;
  }

  public async created(): Promise<void> {
    this[CoreStoreMutations.SET_FILTERS_RENDERER]({
      filters: cloneDeep(this.filters),
      filterRenderer: this.filtersRendererType,
    });
    await this[CoreStoreActions.GET_ITEMS]({
      service: this.service,
      params: this.jsonApiConfiguration,
      listableItem: this.listableItem,
    });
    this.$watch('activeFilters', this.watchActiveFilters, { deep: true });

    this.loaders.initial = false;
  }

  public destroyed() {
    this[CoreStoreMutations.RESET_LISTABLE_ITEMS](this.listableItem);
    this[CoreStoreMutations.RESET_FILTERS_RENDERER](this.filtersRendererType);
  }

  public async onPageChange(page: number) {
    if (page === this.currentPage) return;

    paginationService.setPage(page);
    await this.fetchList({
      ...this.jsonApiConfiguration,
      page: paginationService.getPaginationObject(),
    });
  }

  public watchActiveFilters(filters: AxiosFiltersObject): void {
    this.debouncedFetchingItems({
      ...this.jsonApiConfiguration,
      filter: { ...this.jsonApiConfiguration.filter, ...filters },
    });
  }

  public onUpdateResource(item: I) {
    this[CoreStoreActions.UPDATE_RESOURCE]({
      item,
      listableItem: this.listableItem,
    });
  }

  public onDeleteResource(item: I) {
    this[CoreStoreActions.DELETE_RESOURCE]({
      item,
      listableItem: this.listableItem,
    });
  }

  private async fetchList(params: JsonApiConfiguration) {
    this.loaders.fetching = true;

    await this[CoreStoreActions.GET_ITEMS]({
      params,
      service: this.service,
      listableItem: this.listableItem,
    });

    this.loaders.fetching = false;
  }
}
