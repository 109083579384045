import PrivateTrainingRequest from '@/views/privateTrainings/PrivateTrainingRequest.vue';

export default [
  {
    path: 'request',
    name: 'private-training-request',
    component: PrivateTrainingRequest,
    meta: {
      needsAuthentication: false,
      fullWidth: true,
    },
  },
];
