import BaseRepository from '@/repository/BaseRepository';
import GymTranslated from '@/typings/backend/jsonApiTranslations/gym';
import store from '@/store/index';
import GymNotesStoreActions from '@/constants/store/modules/gymNotes/actions';

class GymNoteRepository extends BaseRepository {
  async create(
    { gym, message }: { gym: GymTranslated, message: string },
  ): Promise<any> {
    return store.dispatch(`gymNotes/${GymNotesStoreActions.POST_NOTE}`, {
      params: this.getParameters('createGymNotes'),
      message,
      gym,
    });
  }

  // eslint-disable-next-line no-empty-pattern,class-methods-use-this
  async edit({}: {}): Promise<any> {
    return undefined;
  }
}

export default GymNoteRepository;
