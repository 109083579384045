import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeeFoundByEmail from '@/typings/backend/customResponses/AttendeeFoundByEmail';
import GetFullNameAttendees from '@/services/attendee/GetFullNameAttendees';

const BASE_URL = 'attendees';

export default {
  read(id: string, params?: JsonApiConfiguration): Promise<AxiosResponse<Attendee>> {
    return axios.get(`${BASE_URL}/${id}`, { params });
  },
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<Attendee[]>> {
    return axios
      .get(BASE_URL, { params })
      .then((response: AxiosResponse<Attendee[]>) => ({
        ...response,
        data: response.data.map(a => ({ ...a, fullName: GetFullNameAttendees(a) })),
      }));
  },
  edit(payload: any, id: string, params?: JsonApiConfiguration): Promise<AxiosResponse<Attendee>> {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
  findByEmail(email: string): Promise<AxiosResponse<AttendeeFoundByEmail>> {
    return axios.get(`${BASE_URL}/find-by-email`, { params: { email } });
  },
};
