import GymStoreMutations from '@/constants/store/modules/gyms/mutations';
import GymStoreState from '@/typings/storeStates/gyms';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import Page from '@/typings/backend/pagination/Page';

export default {
  [GymStoreMutations.SET_GYMS](state: GymStoreState, gyms: Gym[]) {
    state.gyms = gyms;
  },
  [GymStoreMutations.SET_ACTIVE_GYM](state: GymStoreState, gym: Gym) {
    state.activeGym = gym;
  },
  [GymStoreMutations.SET_CHECK_INS](state: GymStoreState, checkIns: CheckIn[]) {
    state.checkIns = checkIns;
  },
  [GymStoreMutations.SET_CHECK_IN_PAGINATION](state: GymStoreState, page: Page | null) {
    state.checkInPagination = page;
  },
};
