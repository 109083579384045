















































































































import { Component, Vue } from 'vue-property-decorator';
import AttendeePlanTableHeaders from '@/components/attendee/attendeeRead/configuration/AttendeePlanTableHeaders';
import { namespace } from 'vuex-class';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import { Loaders } from '@/typings/loaders';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import EditAttendeeGymPlan from '@/components/attendeeGymPlan/EditAttendeeGymPlan.vue';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';

const attendeeStoreModule = namespace('attendees');
const gymStoreModule = namespace('gyms');
const payloadBuilder = new PayloadBuilder('attendee-plan-pauses');
const gymPlanStoreModule = namespace('gymPlans');

@Component({
  components: {
    EditAttendeeGymPlan,
    ConfirmationDialog,
  },
})
export default class PlansRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.IS_EDITING_ATTENDEE)
  isEditing!: boolean;

  @attendeeStoreModule.Action [AttendeesStoreActions.GET_ATTENDEE_GYM_PLANS]: (
    { params }: { params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.GET_ATTENDEE_GYM_PLANS,
  ) attendeeGymPlans!: AttendeeGymPlan[];

  @attendeeStoreModule.Mutation [AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS]: (
    attendeeGymPlans: AttendeeGymPlan[],
  ) => void;

  @gymPlanStoreModule.Getter(GymPlansStoreGetters.GET_GYM_PLANS) gymPlans!: GymPlan[];

  @gymPlanStoreModule.Action [GymPlansStoreActions.GET_GYM_PLANS]: (
    { params }: { params: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.PAUSE_PLAN]: (
    { params, payload, attendeeGymPlan }: {
      params?: JsonApiConfiguration,
      payload: any,
      attendeeGymPlan: AttendeeGymPlan
    },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.UNPAUSE_PLAN]: (
    { params, attendeeGymPlan }: {
      params?: JsonApiConfiguration,
      attendeeGymPlan: AttendeeGymPlan
    },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.ADD_ATTENDEE_GYM_PLAN]: (
    { payload, params }: { payload: any, params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.DELETE_PLAN_FROM_ATTENDEE]: (
    { attendeeGymPlan, params }: {
      attendeeGymPlan: AttendeeGymPlan,
      params?: JsonApiConfiguration,
    },
  ) => Promise<AxiosResponse<null>>;

  public headers = AttendeePlanTableHeaders;

  public modals = {
    editing: false,
    gymPlanDate: false,
    confirmDeletePlan: false,
  }

  public loaders: Loaders = {
    fetchingPlans: true,
    deletingPlan: false,
  }

  public selectedPlanToEdit: AttendeeGymPlan | null = null;

  public planToDelete: AttendeeGymPlan | null = null;

  public newAttendeeGymPlan: { plan: GymPlan | null, startedAt: string } = {
    plan: null,
    startedAt: '',
  }

  get gymPlansAttendeeNotIn(): GymPlan[] {
    const gymPlansIds: string[] = this.attendeeGymPlans.map(agp => agp.plan!.id);

    return this.gymPlans.filter(gp => !gymPlansIds.includes(gp.id));
  }

  public async created() {
    await Promise.all([
      this[AttendeesStoreActions.GET_ATTENDEE_GYM_PLANS]({
        params: {
          include: ['plan', 'pause'],
          filter: { gym: this.activeGym.id, attendee: this.activeAttendee.id },
        },
      }),
      this[GymPlansStoreActions.GET_GYM_PLANS]({
        params: { filter: { gym: this.activeGym.id } },
      }),
    ]);

    this.loaders.fetchingPlans = false;
  }

  public handlePauseAttendeePlanClick(attendeeGymPlan: AttendeeGymPlan): void {
    if (attendeeGymPlan.pause) {
      this.unpausePlan(attendeeGymPlan);
    } else {
      this.pausePlan(attendeeGymPlan);
    }
  }

  public handleExtendPlanClick(attendeeGymPlan: AttendeeGymPlan): void {
    this.modals.editing = true;
    this.selectedPlanToEdit = attendeeGymPlan;
  }

  public async onAddNewPlanToAttendee(): Promise<void> {
    const payload = payloadBuilder
      .resetPayload('attendee-plans')
      .setAttributes({ 'started-at': this.newAttendeeGymPlan.startedAt })
      .setRelationship(
        'plan',
        { type: 'gym-plans', id: this.newAttendeeGymPlan.plan!.id },
      )
      .setRelationship(
        'attendee',
        { type: 'attendees', id: this.activeAttendee.id },
      )
      .resolvePayload();

    this.loaders.addingNewGymPlan = true;
    try {
      await this[AttendeesStoreActions.ADD_ATTENDEE_GYM_PLAN]({
        payload,
        params: { include: ['plan'] },
      });
    } catch (e) {
      this.loaders.addingNewGymPlan = false;

      return;
    }
    this.loaders.addingNewGymPlan = false;
    this.newAttendeeGymPlan = { plan: null, startedAt: '' };
  }

  public onDeletePlan(attendeeGymPlan: AttendeeGymPlan): void {
    this.modals.confirmDeletePlan = true;
    this.planToDelete = attendeeGymPlan;
  }

  public async deletePlan() {
    this.loaders.deletingPlan = true;
    await this[AttendeesStoreActions.DELETE_PLAN_FROM_ATTENDEE]({
      attendeeGymPlan: this.planToDelete!,
    });
    this.modals.confirmDeletePlan = false;
    this.loaders.deletingPlan = false;
  }

  private async pausePlan(attendeeGymPlan: AttendeeGymPlan) {
    const payload = payloadBuilder
      .resetPayload('attendee-plan-pauses')
      .setRelationship('plan', { id: attendeeGymPlan.id, type: 'attendee-plans' })
      .resolvePayload();

    await this[AttendeesStoreActions.PAUSE_PLAN]({
      payload,
      attendeeGymPlan,
    });

    this.$toasted.show('Plan have been paused');
  }

  private async unpausePlan(attendeeGymPlan: AttendeeGymPlan) {
    await this[AttendeesStoreActions.UNPAUSE_PLAN]({
      attendeeGymPlan,
    });

    this.$toasted.show('Plan have been un-paused');
  }
}
