




















import { Component, Prop, Vue } from 'vue-property-decorator';
import Event from '@/typings/backend/jsonApiTranslations/event';

@Component({})
export default class SendEmailEvent extends Vue {
  @Prop({ required: true })
  event!: Event | null;

  public onClose() {
    this.$emit('update:event', null);
  }

  public onSendEmail() {
    // console.log('Send Email');
  }
}
