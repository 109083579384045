import Page from '@/typings/jsonApi/configuration/Page';

class PaginationService {
  private currentPage: number;

  private itemsPerPage: number;

  constructor(itemsPerPage: number = 20, currentPage: number = 1) {
    this.currentPage = currentPage;
    this.itemsPerPage = itemsPerPage;
  }

  public next(): Page {
    this.currentPage += 1;

    return this.getPaginationObject();
  }

  public previous(): Page {
    this.currentPage -= 1;

    return this.getPaginationObject();
  }

  public getPaginationObject(): Page {
    return {
      number: this.currentPage,
      size: this.itemsPerPage,
    };
  }

  public setItemsPerPage(itemsPerPage: number): void {
    this.currentPage = 1;
    this.itemsPerPage = itemsPerPage;
  }

  public reset(itemsPerPage: number = this.itemsPerPage): PaginationService {
    this.itemsPerPage = itemsPerPage;
    this.currentPage = 1;

    return this;
  }

  public setPage(page: number = 1): PaginationService {
    this.currentPage = page;

    return this;
  }
}

export default PaginationService;
