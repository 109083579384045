import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import EmailNotificationType from '@/constants/event/email/EmailNotificationType';
import EventDefinition from '@/typings/nativeEntities/event/eventDefinition';
import eventRepetitionOptions from '@/constants/event/frequency/EventRepetitionOptions';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import moment from 'moment';

const momentNow = moment();

class CreateEventFactory {
  name: string = '';

  description: string = '';

  activityGroupGuests: ActivityGroup[] = [];

  attendeeGuests: Attendee[] = [];

  start: string = momentNow.format('YYYY-MM-DD HH:mm:ss');

  end: string = momentNow.add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
  // startDate: string = momentNow.format('YYYY-MM-DD');

  // startTime: string = momentNow.format('HH:mm:ss');

  // endTime: string = momentNow.add(1, 'hour').format('HH:mm:ss');

  dayCount: number = 0;

  emailNotification: EmailNotificationType = EmailNotificationType.DONT_SEND;

  maxAttendees: number = 10;

  color: string = '#e84b3c';

  repetition: EventDefinition = eventRepetitionOptions[0];

  gym!: Gym;
}

export default CreateEventFactory;
