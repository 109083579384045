import AttendeesStoreState from '@/typings/storeStates/attendees';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: AttendeesStoreState = {
  activeAttendee: null,
  activeAttendeeGymRelationship: null,
  attendeeCheckIns: [],
  attendeeNotes: [],
  attendeeGymPlans: [],
  activeAttendeeCheckedInPlan: null,
  isEditingActiveAttendee: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
