import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import AttendeesStoreState from '@/typings/storeStates/attendees';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import GymAttendee from '@/typings/backend/jsonApiTranslations/GymAttendee';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import AttendeeNote from '@/typings/backend/jsonApiTranslations/attendeeNote';
import Vue from 'vue';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';
import AttendanceLog from '@/typings/backend/jsonApiTranslations/attendanceLog';

export default {
  [AttendeeStoreMutations.SET_ATTENDEE_GYM_PLANS](
    state: AttendeesStoreState, agp: AttendeeGymPlan[],
  ) {
    state.attendeeGymPlans = agp;
  },
  [AttendeeStoreMutations.SET_ACTIVE_ATTENDEE](
    state: AttendeesStoreState,
    attendee: Attendee | null,
  ) {
    state.activeAttendee = attendee;
  },
  [AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_PROPERTY](
    state: AttendeesStoreState,
    data: { name: string, value: any },
  ) {
    Vue.set(state.activeAttendee!, data.name, data.value);
  },
  [AttendeeStoreMutations.SET_ACTIVE_ATTENDEE_GYM_RELATIONSHIP](
    state: AttendeesStoreState,
    attendeeGymRelationship: GymAttendee | null,
  ) {
    state.activeAttendeeGymRelationship = attendeeGymRelationship;
  },
  [AttendeeStoreMutations.SET_ATTENDEE_CHECK_INS](state: AttendeesStoreState, checkIns: CheckIn[]) {
    state.attendeeCheckIns = checkIns;
  },
  [AttendeeStoreMutations.SET_ATTENDEE_NOTES](state: AttendeesStoreState, notes: AttendeeNote[]) {
    state.attendeeNotes = notes;
  },
  [AttendeeStoreMutations.SET_CURRENT_CHECKED_IN_PLAN](
    state: AttendeesStoreState,
    activeAttendeeCheckedInPlan: AttendanceLog | null,
  ) {
    state.activeAttendeeCheckedInPlan = activeAttendeeCheckedInPlan;
  },
  [AttendeeStoreMutations.SET_IS_EDITING_ATTENDEE](state: AttendeesStoreState, isEditing: boolean) {
    state.isEditingActiveAttendee = isEditing;
  },
};
