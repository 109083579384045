













import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';

const attendeeStoreModule = namespace('attendees');
const payloadBuilder = new PayloadBuilder('attendees');

@Component({})
export default class MarketingRow extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Action[AttendeesStoreActions.EDIT_ATTENDEE]: (
    { params, attendee, payload }: {
      params?: JsonApiConfiguration,
      attendee: Attendee,
      payload: any
    },
  ) => Promise<AxiosResponse>;

  public internalWantsToReceiveEmail: boolean = false;

  public loaders = {
    receivingEmail: false,
  }

  public created() {
    this.internalWantsToReceiveEmail = !!this.activeAttendee.sendEmails;
  }

  public async onChangeReceiveEmail(sendEmails: boolean) {
    const payload = payloadBuilder
      .resetPayload('attendees')
      .setId(this.activeAttendee.id)
      .setAttributes({
        firstName: this.activeAttendee.firstName,
        lastName: this.activeAttendee.lastName,
        email: this.activeAttendee.email,
        date_of_birth: this.activeAttendee.dateOfBirth,
        locale: this.activeAttendee.locale,
        personal_identification_number: this.activeAttendee.personalIdentificationNumber,
        'send-emails': sendEmails ? 1 : 0,
      })
      .setRelationship('address', {
        type: 'addresses',
        id: this.activeAttendee.address.id,
      })
      .resolvePayload();

    this.loaders.receivingEmail = true;

    await this[AttendeesStoreActions.EDIT_ATTENDEE]({
      payload,
      attendee: this.activeAttendee,
      params: {
        include: ['address.country', 'phoneNumbers', 'activityGroups', 'payingGroups', 'additional', 'plans'],
      },
    });

    this.loaders.receivingEmail = false;
  }
}
