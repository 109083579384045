






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  CardNumber, CardExpiry, CardCvc, createToken,
  // @ts-ignore
} from 'vue-stripe-elements-plus';

@Component({
  components: {
    CardNumber,
    CardExpiry,
    CardCvc,
  },
})
export default class CreateCompanyStep2Components extends Vue {
  public complete: boolean = false;

  public loading: boolean = false;

  public options = {
    classes: {
      base: 'create-company__payment__input',
    },
  };

  public stripe: string = 'pk_test_9fL2L7otixmLLOOrc1buuRbj';

  public number: string = '';

  public expiry: string = '';

  public cvc: string = '';

  public $refs!: {
    cardExpiry: HTMLElement,
    cardCvc: HTMLElement,
    cardNumber: HTMLElement,
  };

  @Watch('number')
  public watchNumber(): void {
    this.update();
  }

  @Watch('expiry')
  public watchExpiry(): void {
    this.update();
  }

  @Watch('cvc')
  public watchCvc(): void {
    this.update();
  }

  public update(): void {
    this.complete = ((this.number && this.expiry && this.cvc) as unknown) as boolean;

    // field completed, find field to focus next
    if (this.number) {
      if (!this.expiry) {
        this.$refs.cardExpiry.focus();
      } else if (!this.cvc) {
        this.$refs.cardCvc.focus();
      }
    } else if (this.expiry) {
      if (!this.cvc) {
        this.$refs.cardCvc.focus();
      } else if (!this.number) {
        this.$refs.cardNumber.focus();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async pay() {
    // createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    // @ts-ignore
    return createToken().then(data => data);
  }

  public handleClickPrevious(): void {
    this.$emit('step:previous');
  }

  public handleClickCreate(): void {
    this.loading = true;
    this.pay()
      .then((response) => {
        this.$emit('step:completed', { stripeToken: response.token });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
