import GymsPlanStoreState from '@/typings/storeStates/gymsPlan';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: GymsPlanStoreState = {
  gymPlans: [],
  activeAttendeeGymPlans: [],
  activeGymPlan: null,
  deletePlan: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
