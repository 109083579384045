





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import TableHeaderInterface from '@/typings/tableHeaders';
import TableHeader from '@/views/attendees/users/configuration/tableHeaders';
import SendMessageUsers from '@/components/users/SendMessage.vue';
import AttendeeStatusIndicator from '@/components/attendee/AttendeeStatusIndicator.vue';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { Loaders } from '@/typings/loaders';
import DateOfBirthService from '@/services/dates/DateOfBirthService';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';

const gymsStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    SendMessageUsers,
    AttendeeStatusIndicator,
  },
})
export default class AttendeeListTable extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE]: (
    attendee: Attendee | null
  ) => void;

  @Prop({ required: true })
  attendees!: Attendee[];

  @Prop({ default: false })
  fetching!: boolean;

  public loading: Loaders = {
    gettingUsers: false,
    editingUser: false,
  };

  public tableHeaders: TableHeaderInterface[] = TableHeader;

  public usersSelected: Attendee[] = [];

  public isSendingMessage: boolean = false;

  public onClickRow(attendee: Attendee): void {
    this[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE](attendee);

    this.$router.push({
      name: 'attendeeView',
      params: { ...this.$route.params, attendeeId: attendee.id },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public getYearsOld(date: string | null): number | string {
    if (date) return DateOfBirthService.getYearsOld(date);

    return '---';
  }

  public handleClickSendMessageButton(): void {
    this.isSendingMessage = true;
  }
}
