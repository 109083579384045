
import ThirdPartyPayingStoreState from '@/typings/storeStates/thirdPartyPaying';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: ThirdPartyPayingStoreState = {
  thirdPartiesPaying: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
