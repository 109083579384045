






























import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';

@Component
export default class ThirdPartyPayingCard extends Vue {
  @Prop({ required: true })
  thirdPartyPaying!: ThirdPartyPaying;

  public handleClickIconView(): void {
    this.$router.push({
      name: 'thirdPartyPayingView',
      params: {
        ...this.$route.params,
        thirdPartyPayingId: this.thirdPartyPaying.id,
      },
    });
  }
}
