import ParametersMap from '@/typings/repository/parametersMap';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';

abstract class BaseRepository {
  protected readonly params: ParametersMap;

  constructor(params?: ParametersMap) {
    this.params = params || {} as ParametersMap;
  }

  protected getParameters(
    key: string,
    parametersMap: ParametersMap = this.params,
  ): JsonApiConfiguration {
    if (Object.prototype.hasOwnProperty.call(parametersMap, key)) {
      return parametersMap[key];
    }

    return {};
  }

  // eslint-disable-next-line no-empty-pattern
  abstract async edit({}): Promise<any>;

  // eslint-disable-next-line no-empty-pattern
  abstract async create({}, ...rest: any[]): Promise<any>;
}

export default BaseRepository;
