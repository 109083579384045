




























































































import { Prop, Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class GymEditDialog extends Vue {
  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ required: true })
  gym!: Gym;

  @Prop({ required: false, type: Boolean })
  loading!: boolean;

  public gymCopy: Gym = { ...this.gym };

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  };

  @Watch('gym')
  public watchGym(gym: Gym): void {
    this.gymCopy = { ...gym };
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public handleClickSaveButton(): void {
    this
      .$refs
      .validator
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) {
          return;
        }

        this.$emit('gym:save', { gym: this.gymCopy });
      });
  }
}
