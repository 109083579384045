import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import axios from '@/plugins/axios/jsonApi';

const BASE_URL = 'paying-groups';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<ThirdPartyPaying>> {
    return axios.get(BASE_URL, { params });
  },
  read(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<ThirdPartyPaying>> {
    return axios.get(`${BASE_URL}/${id}`, { params });
  },
  create(
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<ThirdPartyPaying>> {
    return axios.post(BASE_URL, payload, { params });
  },
  edit(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<ThirdPartyPaying>> {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
  deleteAttendee(id: string, payload: any): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}/relationships/attendees`, { data: payload });
  },
  addAttendee(id: string, payload: any): Promise<AxiosResponse> {
    return axios.post(`${BASE_URL}/${id}/relationships/attendees`, payload);
  },
};
