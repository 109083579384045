import EventsStoreActions from '@/constants/store/modules/events/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import Event from '@/typings/backend/jsonApiTranslations/event';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import EventsStoreState from '@/typings/storeStates/events';
import EventApiService from '@/services/api/EventApiService';
import EventInvitationStatus from '@/constants/event/EventInvitationStatus';
import RecurringEventDeleteOptions from '@/constants/event/RecurringEventDeleteOptions';
import EventDefinitionServiceApi from '@/services/api/EventDefinitionServiceApi';
import moment from 'moment';
import EventPayloadBuilderDirector from '@/services/payloadBuilder/directors/EventPayloadBuilderDirector';
import PrivateGymTrainingsApiService from '@/services/api/PrivateGymTrainingsApiService';
import PrivateGymTraining from '@/typings/backend/jsonApiTranslations/PrivateGymTraining';

export default {
  async [EventsStoreActions.GET_EVENTS](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<Event[]>> {
    return EventApiService
      .list(params)
      .then((response: AxiosResponse<Event[]>) => {
        commit(EventsStoreMutations.SET_EVENTS, response.data);

        return response;
      });
  },

  async [EventsStoreActions.DELETE_EVENT](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { event, params, deleteType = RecurringEventDeleteOptions.THIS_EVENT }: {
      event: Event,
      params: JsonApiConfiguration,
      deleteType: RecurringEventDeleteOptions
    },
  ): Promise<AxiosResponse<null>> {
    if (deleteType === RecurringEventDeleteOptions.THIS_EVENT) {
      return EventApiService
        .delete(event.id, params)
        .then((response: AxiosResponse<null>) => {
          const events: Event[] = [...state.events];

          events.splice(
            events.findIndex(e => e.id === event.id),
            1,
          );

          commit(EventsStoreMutations.SET_EVENTS, events);

          return response;
        });
    }

    if (!Object.prototype.hasOwnProperty.call(event, 'definition')) {
      throw Error('To delete all events from a certain event you need to provide the event definition');
    }

    if (deleteType === RecurringEventDeleteOptions.ALL_EVENTS) {
      return EventDefinitionServiceApi
        .delete(event.definition!.id, params)
        .then((response) => {
          const events = state.events.filter((e: Event) => (
            Object.prototype.hasOwnProperty.call(e, 'definition')
              ? e.definition!.id !== event.definition!.id
              : true
          ));

          commit(EventsStoreMutations.SET_EVENTS, events);

          return response;
        });
    }

    return EventApiService
      .deleteFutureEvents(event.id, params)
      .then((response) => {
        const momentNow = moment(event.start).subtract(1, 'days');
        const events = state.events.filter((e: Event) => {
          // We need to filter all recurring events that has nothing to do
          // with the event that we are trying to delete for now
          if (
            !Object.prototype.hasOwnProperty.call(e, 'definition')
            || e.definition!.id !== event.definition!.id
          ) return true;

          // Now that we only have the events that has anything to do with the
          // event we have deleted we can delete only the future ones
          return momentNow.isSameOrAfter(moment(e.start), 'day');
        });

        commit(EventsStoreMutations.SET_EVENTS, events);

        return response;
      });
  },

  async [EventsStoreActions.EDIT_EVENT](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { event, params }: { event: Event, params: JsonApiConfiguration },
  ): Promise<AxiosResponse<Event>> {
    const builder = new EventPayloadBuilderDirector();
    const payload = builder.update(event);

    return EventApiService
      .edit(event.id, payload, params)
      .then((response: AxiosResponse<Event>) => {
        const events = [...state.events];

        events.splice(
          events.findIndex(e => e.id === event.id),
          1,
          response.data,
        );

        commit(EventsStoreMutations.SET_EVENTS, events);
        commit(EventsStoreMutations.SET_ACTIVE_EVENT, response.data);
        commit(EventsStoreMutations.SET_EDIT_CREATE_EVENT, response.data);

        return response;
      });
  },

  async [EventsStoreActions.GET_ACTIVE_EVENT_ATTENDEES](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { params, status }: { params: JsonApiConfiguration, status: EventInvitationStatus },
  ) {
    if (state.activeEvent === null) {
      throw Error('Make sure the active event is not null when calling this action');
    }

    return EventApiService
      .getAttendees(state.activeEvent.id, status, params)
      .then((response) => {
        commit(
          EventsStoreMutations.SET_ACTIVE_EVENT_ATTENDEES,
          [...state.activeEventAttendees, ...response.data],
        );

        return response;
      });
  },
  async [EventsStoreActions.CREATE_EVENT](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { params, payload }: { params: JsonApiConfiguration, payload: any },
  ) {
    const response = await EventDefinitionServiceApi.create(payload, params);
    const eventResponse = await EventApiService.list({
      filter: {
        definition: response.data.id,
      },
      include: ['definition', 'activityGroupGuests', 'attendeeGuests'],
    });

    commit(
      EventsStoreMutations.SET_EVENTS,
      [...state.events, ...eventResponse.data],
    );

    return response;
  },
  async [EventsStoreActions.GET_PRIVATE_GYM_TRAININGS](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { params }: { params: JsonApiConfiguration },
  ) {
    const response = await PrivateGymTrainingsApiService.list(params);

    commit(EventsStoreMutations.SET_PRIVATE_GYM_TRAININGS, response.data);

    return response;
  },
  async [EventsStoreActions.UPDATE_PRIVATE_TRAINING](
    { commit, state }: { commit: Function, state: EventsStoreState },
    { params, payload, training }: {
      params: JsonApiConfiguration,
      payload: any,
      training: PrivateGymTraining
    },
  ) {
    const response = await PrivateGymTrainingsApiService.update(training.id, payload, params);
    const privateTrainings = [...state.privateTrainings];

    privateTrainings.splice(
      privateTrainings.findIndex(p => p.id === training.id),
      1,
      { ...training, ...response.data },
    );

    commit(EventsStoreMutations.SET_PRIVATE_GYM_TRAININGS, privateTrainings);

    return response;
  },
};
