import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Store modules
import gymPlans from './modules/gymPlans/index';
import users from './modules/users/index';
import company from './modules/company/index';
import gyms from './modules/gyms/index';
import attendees from './modules/attendees/index';
import countries from './modules/country/index';
import activityGroups from './modules/activityGroups/index';
import gymNotes from './modules/gymNotes/index';
import thirdPartyPaying from './modules/thirdPartyPaying/index';
import event from './modules/event/index';
import core from './modules/core';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['users', 'company', 'gyms'],
});

Vue.use(Vuex);

export default new Vuex.Store({
  // @ts-ignore
  modules: {
    users,
    company,
    gyms,
    attendees,
    countries,
    gymPlans,
    activityGroups,
    gymNotes,
    thirdPartyPaying,
    event,
    core,
  },
  // @ts-ignore
  plugins: [vuexLocal.plugin],
});
