













import Vue from 'vue';
import Component from 'vue-class-component';
// @ts-ignore
import { GChart } from 'vue-google-charts';
import GoogleChartStyle from '@/views/gyms/configuration/GoogleChartStyle';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import StatisticsApiService from '@/services/api/StatisticsApiService';
import ActivityGroupDistributionStatistics
  from '@/typings/backend/customResponses/Statistics/ActivityGroupDistributionStatistics';

const gymStoreModule = namespace('gyms');

@Component({
  components: {
    GChart,
  },
})
export default class StatisticsActivityGroupDistribution extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public chartOptions = GoogleChartStyle;

  public membershipDistributionStatistics: ActivityGroupDistributionStatistics[] = [];

  get chartData(): Array<Array<string|number>> {
    const finalResult: Array<Array<string|number>> = [['Task', 'Hours per Day']];

    this.membershipDistributionStatistics.forEach((mds) => {
      finalResult.push([mds.activityGroup.name, mds.attendees]);
    });

    return finalResult;
  }

  public created(): void {
    StatisticsApiService
      .activityGroupDistribution(this.activeGym.id)
      .then((response) => {
        this.membershipDistributionStatistics = response.data.data;
      });
  }
}
