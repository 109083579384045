







































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import ActivityGroupCard from '@/components/activityGroup/ActivityGroupCard.vue';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { Loaders } from '@/typings/loaders';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import ListableItem from '@/constants/listableItems/ListableItem';
import ActivityGroupApiService from '@/services/api/ActivityGroupApiService';
import FilterRenderers from '@/constants/filters/FilterRenderers';
import filters from './configuration/Filters';

const gymStoreModule = namespace('gyms');
const activityGroupStoreModule = namespace('activityGroups');
const userStoreModule = namespace('users');

@Component({
  components: {
    'confirmation-dialog': ConfirmationDialog,
    ActivityGroupCard,
    ListableItems,
  },
})
export default class ActivityGroupList extends Vue {
  @activityGroupStoreModule.Action [ActivityGroupsStoreActions.DELETE_ACTIVITY_GROUP]: Function;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public listableItem = Object.freeze(ListableItem.ActivityGroupsMainTable);

  public service = Object.freeze(ActivityGroupApiService);

  public filterRenderer = Object.freeze(FilterRenderers.ActivityGroupMainTable);

  public filters = filters;

  public loaders: Loaders = {
    fetchingGroups: false,
    deletingGroups: false,
  };

  public menus: {[key: string]: boolean} = {
    deletingGroup: false,
  };

  public activeDeletingGroup: ActivityGroup = {} as ActivityGroup;

  get params(): JsonApiConfiguration {
    return {
      include: ['createdBy', 'gym', 'trainers'],
      filter: {
        gym: this.activeGym.id,
      },
    };
  }

  public onActivityGroupDelete({ activityGroup }: { activityGroup: ActivityGroup }): void {
    this.activeDeletingGroup = activityGroup;
    this.menus.deletingGroup = true;
  }

  public async onActivityGroupDeleteConfirmed(callbackListableItems: Function) {
    this.loaders.deletingGroups = true;

    try {
      await this[ActivityGroupsStoreActions.DELETE_ACTIVITY_GROUP]({
        activityGroup: this.activeDeletingGroup,
      });
    } catch (e) {
      this.loaders.deletingGroups = false;

      return;
    }

    callbackListableItems(this.activeDeletingGroup);
    this.menus.deletingGroup = false;
    this.$toasted.show('Activity group has been successfully deleted');
  }
}
