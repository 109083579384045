import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import AttendanceLog from '@/typings/backend/jsonApiTranslations/attendanceLog';

const BASE_URL = 'attendance-logs';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<AttendanceLog[]>> {
    return axios.get(BASE_URL, { params });
  },
};
