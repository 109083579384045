enum EventsStoreMutations {
  SET_EVENTS = 'SET_EVENTS',
  SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT',
  SET_ACTIVE_EVENT_ATTENDEES = 'SET_ACTIVE_EVENT_ATTENDEES',
  SET_EDIT_CREATE_EVENT = 'SET_EDIT_CREATE_EVENT',
  SET_MODAL = 'SET_MODAL',
  SET_PRIVATE_GYM_TRAININGS = 'SET_PRIVATE_GYM_TRAININGS',
  SET_ACTIVE_PRIVATE_TRAINING = 'SET_ACTIVE_PRIVATE_TRAINING',
}

export default EventsStoreMutations;
