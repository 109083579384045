










































































































































































































































import { Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Attendee from '@/typings/nativeEntities/users/Attendee';
import UserLocale from '@/services/user/userLocale';
import { namespace } from 'vuex-class';
import Country from '@/typings/backend/jsonApiTranslations/country';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Component from 'vue-class-component';
import AttendeeFoundByEmail from '@/typings/backend/customResponses/AttendeeFoundByEmail';
import VueMoment from 'vue-moment';

const countryStoreModule = namespace('countries');
const userStoreModule = namespace('users');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class UsersNewStep2 extends Vue {
  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  attendeeFoundByEmail!: AttendeeFoundByEmail;

  @Watch('dateOfBirthMenu')
  public watchDateOfBirthMenu(val: boolean): void {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions,no-return-assign
    val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
  }

  public dateOfBirthMenu: boolean = false;

  public newAttendee: Attendee = {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    phoneNumber: {
      number: '',
      country: null,
      owner: {
        type: 'attendees',
        id: '',
      },
    },
    email: '',
    locale: UserLocale(),
    address: {
      name: '',
      country: null,
    },
    personalIdentificationNumber: '',
    sendEmails: 1,
  };

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  @Watch('attendeeFoundByEmail', { deep: true })
  public watchAttendeeFoundByEmail(attendeeFoundByEmail: AttendeeFoundByEmail): void {
    if (!attendeeFoundByEmail.wasFound) {
      this.newAttendee.email = attendeeFoundByEmail.email;
    }
  }

  public created(): void {
    this.newAttendee.phoneNumber.owner.id = this.activeUser.id;
    this.newAttendee.phoneNumber.country = this.countries.find(c => c.code === 'HR') as Country;

    if (!this.attendeeFoundByEmail.wasFound) {
      this.newAttendee.email = this.attendeeFoundByEmail.email;
    }
  }

  public handleClickNextButton(): void {
    if (this.attendeeFoundByEmail.wasFound) {
      this.$emit('step:forward', { attendee: null });

      return;
    }

    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.$emit('step:forward', { attendee: this.newAttendee });
      });
  }

  public handleClickPreviousButton(): void {
    this.$emit('step:previous');
  }

  public allowedToDates(endDate: string): boolean {
    const startDateMoment = this.$moment();
    const endDateMoment = this.$moment(endDate);

    return startDateMoment.isSameOrAfter(endDateMoment);
  }
}
