















import { Component, Vue } from 'vue-property-decorator';
import axios from '@/plugins/axios/jsonApi';

@Component({})
export default class PrivateTrainingRequest extends Vue {
  public makingRequest = true;

  get isAccepting() {
    return this.$route.query.status === 'accept';
  }

  get text() {
    return `${this.isAccepting ? 'Accepting' : 'Denying'}`;
  }

  public async created() {
    if (this.isAccepting) {
      await axios.get(`private-gym-training/accept/${this.$route.query.hash}`);
    } else {
      await axios.get(`private-gym-training/decline/${this.$route.query.hash}`);
    }

    this.makingRequest = false;
    setTimeout(() => {
      this.$router.push({ name: 'login' });
    }, 5000);
  }
}
