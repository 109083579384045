
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import headers from '@/views/management/staffMembersList/configuration/tableHeaders';
import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog.vue';
import { Loaders } from '@/typings/loaders';
import Dialogs from '@/typings/Dialogs';
import { namespace } from 'vuex-class';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import User from '../../../typings/backend/jsonApiTranslations/user';
import GymsStoreActions from '../../../constants/store/modules/gyms/actions';

const gymsStoreModule = namespace('gyms');

@Component({
  components: {
    ConfirmationDialog,
  },
})
export default class StaffMemberListTable extends Vue {
  @gymsStoreModule.Action [GymsStoreActions.UNLINK_EMPLOYEE_FROM_GYM]: (
    {
      payload, gym, params, employee,
    }: {
      payload: any, gym: Gym, params?: JsonApiConfiguration, employee: User
    }
  ) => Promise<AxiosResponse>;

  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @Prop({ required: true, type: Array })
  users!: User[];

  @Prop({ required: true, type: Boolean })
  loading!: boolean;

  public headers = headers;

  public loaders: Loaders = {
    gettingUsers: false,
    unlinkingUser: false,
  };

  public activeUser: User | null = null;

  public dialogs: Dialogs = {
    unlinkUser: false,
  }

  public handleUnlinkEmployee(user: User): void {
    this.activeUser = user;
    this.dialogs.unlinkUser = true;
  }

  public handleUnlinkEmployeeConfirmation(): void {
    const payload = {
      data: [{ type: 'users', id: this.activeUser!.id }],
    };

    this.loaders.unlinkingUser = true;
    this[GymsStoreActions.UNLINK_EMPLOYEE_FROM_GYM]({
      payload, gym: this.activeGym, employee: this.activeUser!,
    })
      .then(() => {
        this.$emit('delete', this.activeGym);
        this.activeUser = null;
        this.dialogs.unlinkUser = false;
        this.$toasted.show('The employee have been deleted');
      })
      .finally(() => {
        this.loaders.unlinkingUser = false;
      });
  }
}
