var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-5"},[_c('div',{staticClass:"attendee-read__divider"},[_vm._v("Plans")]),_c('v-card',[(_vm.isEditing)?_c('v-card-text',[_c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',[_c('v-select',{attrs:{"placeholder":"Add attendee to plan","item-text":"name","item-value":"id","return-object":"","items":_vm.gymPlansAttendeeNotIn},model:{value:(_vm.newAttendeeGymPlan.plan),callback:function ($$v) {_vm.$set(_vm.newAttendeeGymPlan, "plan", $$v)},expression:"newAttendeeGymPlan.plan"}})],1),_c('v-col',{staticClass:"align-self-center"},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"This plan starts on","persistent-hint":"","readonly":""},model:{value:(_vm.newAttendeeGymPlan.startedAt),callback:function ($$v) {_vm.$set(_vm.newAttendeeGymPlan, "startedAt", $$v)},expression:"newAttendeeGymPlan.startedAt"}},on))]}}],null,false,3825186263),model:{value:(_vm.modals.gymPlanDate),callback:function ($$v) {_vm.$set(_vm.modals, "gymPlanDate", $$v)},expression:"modals.gymPlanDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.modals.gymPlanDate = false}},model:{value:(_vm.newAttendeeGymPlan.startedAt),callback:function ($$v) {_vm.$set(_vm.newAttendeeGymPlan, "startedAt", $$v)},expression:"newAttendeeGymPlan.startedAt"}})],1)],1),_c('v-col',{staticClass:"align-self-center"},[_c('v-btn',{attrs:{"disabled":_vm.newAttendeeGymPlan.startedAt && _vm.newAttendeeGymPlan.plan === null,"loading":_vm.loaders.addingNewGymPlan},on:{"click":_vm.onAddNewPlanToAttendee}},[_vm._v(" ADD PLAN ")])],1)],1)],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{staticClass:"v-data-table--transparent",attrs:{"hide-default-footer":"","disable-sort":"","items":_vm.attendeeGymPlans,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.endedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.endedAt).format('LL'))+" ")]}},{key:"item.attendance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attendance)+" / "+_vm._s(item.plan.participationNumber ? item.plan.participationNumber : 'Infinity')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"min-width":"300px"}},[_c('v-list-item',{on:{"click":function($event){return _vm.handleExtendPlanClick(item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s("Extend Plan")}})],1),_c('v-list-item',{on:{"click":function($event){return _vm.handlePauseAttendeePlanClick(item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.pause ? 'Unpause Plan' : 'Pause Plan')}})],1),_c('v-list-item',{on:{"click":function($event){return _vm.onDeletePlan(item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s("Remove from plan")}})],1)],1)],1)]}}])})],1)],1),(_vm.selectedPlanToEdit)?_c('edit-attendee-gym-plan',{attrs:{"attendee-gym-plan":_vm.selectedPlanToEdit},model:{value:(_vm.modals.editing),callback:function ($$v) {_vm.$set(_vm.modals, "editing", $$v)},expression:"modals.editing"}}):_vm._e(),_c('confirmation-dialog',{attrs:{"message":"Are you sure you would like to remove this plan?"},on:{"confirm:accept":_vm.deletePlan},model:{value:(_vm.modals.confirmDeletePlan),callback:function ($$v) {_vm.$set(_vm.modals, "confirmDeletePlan", $$v)},expression:"modals.confirmDeletePlan"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }