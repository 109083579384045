




































































































import { Component, Prop } from 'vue-property-decorator';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import NewThirdPartyPayingContact
  from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPayingContact';
import Country from '@/typings/backend/jsonApiTranslations/country';
import { namespace } from 'vuex-class';
import CountryStoreGetters from '@/constants/store/modules/country/getters';

const countryStoreModule = namespace('countries');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ThirdPartyPayingContactNewDialog extends Vue {
  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public newContact: NewThirdPartyPayingContact = {
    personName: '',
    country: null,
    phoneNumber: '',
    email: '',
  };

  public $refs!: {
    newContactValidatorObserver: InstanceType<typeof ValidationObserver>,
  };

  public created(): void {
    this.newContact.country = this.countries.find(c => c.code === 'HR') as Country;
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public handleClickAddContact(): void {
    this
      .$refs
      .newContactValidatorObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.$emit('contact:new', { contact: this.newContact });
      });
  }

  public resetFields(): void {
    this.newContact = {
      personName: '',
      country: this.countries.find(c => c.code === 'HR') as Country,
      phoneNumber: '',
      email: '',
    };

    this.$nextTick(() => this.$refs.newContactValidatorObserver.reset());
  }
}
