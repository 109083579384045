import ActivityGroupsStoreState from '@/typings/storeStates/activityGroups';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: ActivityGroupsStoreState = {
  activityGroups: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
