









































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import { Prop } from 'vue-property-decorator';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import NewAttendeeSubscription
  from '@/typings/nativeEntities/users/NewAtendee/NewAttendeeSubscription';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueMoment from 'vue-moment';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import ActivityGroupsStoreGetters from '@/constants/store/modules/activityGroups/getters';
import ThirdPartyPayingStoreGetters from '@/constants/store/modules/thirdPartyPaying/getters';
import debounce from 'lodash.debounce';
import { Loaders } from '@/typings/loaders';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';

const gymStoreModule = namespace('gyms');
const gymPlansStoreModule = namespace('gymPlans');
const activityGroupStoreModule = namespace('activityGroups');
const thirdPartyPayingStoreModule = namespace('thirdPartyPaying');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class UsersNewStep3 extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @gymPlansStoreModule.Getter(GymPlansStoreGetters.GET_GYM_PLANS) gymPlans!: GymPlan[];

  @activityGroupStoreModule.Getter(
    ActivityGroupsStoreGetters.GET_ACTIVITY_GROUPS,
  ) activityGroups!: ActivityGroup[];

  @thirdPartyPayingStoreModule.Getter(
    ThirdPartyPayingStoreGetters.GET_THIRD_PARTIES_PAYING,
  ) thirdPartiesPaying!: ThirdPartyPaying[];

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE]: (
    attendee: Attendee | null
  ) => void;

  @attendeeStoreModule.Getter(
    AttendeeStoreGetters.ACTIVE_ATTENDEE,
  ) activeAttendee!: Attendee | null;

  @Prop({ required: true })
  loading!: boolean;

  public hasCheckedBarCode: boolean = false;

  public attendeeWithDuplicatedBarCode: Attendee | null = null;

  public membershipDateModal: {[key: string]: boolean} = {
    from: false,
    to: false,
  };

  public loaders: Loaders = {
    requestBarcodeCheck: false,
  }

  public debouncedCheckDuplicatedBarcode: Function = debounce(this.checkDuplicatedBarcode, 400);

  public attendeeSubscription: NewAttendeeSubscription = {
    plansInformation: {
      plans: [],
      from: '',
    },
    groups: [],
    thirdPartyPaying: null,
    barcode: '',
    sendEmails: 1,
  };

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>
  }

  get disabledFinishButton(): boolean {
    if (this.attendeeSubscription.barcode === '') {
      return false;
    }

    if (!this.hasCheckedBarCode) return false;

    return this.attendeeWithDuplicatedBarCode !== null;
  }

  public created(): void {
    this.attendeeSubscription.plansInformation.from = this.$moment().format('YYYY-MM-DD');
    // @ts-ignore
    this.$watch('attendeeSubscription.barcode', this.debouncedCheckDuplicatedBarcode);
    this.$watch('activeAttendee', this.watchActiveAttendee);
  }

  public checkDuplicatedBarcode(): void {
    this.attendeeWithDuplicatedBarCode = null;
    this.hasCheckedBarCode = false;
    this.loaders.requestBarcodeCheck = true;
    const params: JsonApiConfiguration = {
      filter: { gym: this.activeGym.id, thirdPartyIdentifier: this.attendeeSubscription.barcode },
      include: ['address.country', 'phoneNumbers', 'activityGroups', 'payingGroups', 'additional', 'plans'],
    };

    AttendeeApiService
      .list(params)
      .then((response) => {
        this.hasCheckedBarCode = true;
        this.attendeeWithDuplicatedBarCode = response.data.length > 0 ? response.data[0] : null;
      })
      .finally(() => {
        this.loaders.requestBarcodeCheck = false;
      });
  }

  public saveDate(date: string | Date, picker: string): void {
    // @ts-ignore
    this.$refs[picker].save(date);
  }

  public handleClickFinish(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.$emit('create-attendee', this.attendeeSubscription);
      });
  }

  public handleAttendeeBarcodeDuplicated(): void {
    this[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE](this.attendeeWithDuplicatedBarCode);
  }

  private watchActiveAttendee(attendee: Attendee | null): void {
    // if the user fills a barcode that already exists he can
    // open the dialog to be able to see the attendee that is using
    // such bar code. Upon opening the dialog the user can update the
    // already used barcode and upon closing the dialog we need to check
    // if the barcode inserted is being used so that user can proceed with attendee creation
    if (attendee === null) {
      this.checkDuplicatedBarcode();
    }
  }
}
