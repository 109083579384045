import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import GymStoreState from '@/typings/storeStates/gyms';

export default {
  [GymStoreGetters.GET_GYMS]: (state: GymStoreState) => state.gyms,
  [GymStoreGetters.GET_GYMS_FILTERED_BY_ID]: (state: GymStoreState) => (
    // We can't add company to Gym otherwise we will have a circular dependency
    // So we explicitly will ignore the error
    // @ts-ignore
    (gymId: string) => state.gyms.filter(gym => gym.company.id === gymId)
  ),
  [GymStoreGetters.GET_ACTIVE_GYM]: (state: GymStoreState) => state.activeGym,
  [GymStoreGetters.GET_CHECK_INS]: (state: GymStoreState) => state.checkIns,
  [GymStoreGetters.GET_CHECK_INS_PAGINATION]: (state: GymStoreState) => state.checkInPagination,
};
