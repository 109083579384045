import EventsStoreGetters from '@/constants/store/modules/events/getters';
import EventsStoreState from '@/typings/storeStates/events';
import Modals from '@/constants/Modals';

export default {
  [EventsStoreGetters.GET_EVENTS]: (state: EventsStoreState) => state.events,
  [EventsStoreGetters.GET_ACTIVE_PRIVATE_TRAINING]: (
    state: EventsStoreState,
  ) => state.activePrivateTraining,
  [EventsStoreGetters.GET_GYM_PRIVATE_TRAININGS]: (
    state: EventsStoreState,
  ) => state.privateTrainings,
  [EventsStoreGetters.GET_EDIT_CREATE_EVENT]: (state: EventsStoreState) => state.editCreateEvent,
  [EventsStoreGetters.MODAL_IS_OPEN]: (state: EventsStoreState) => (modal: Modals) => (
    state.modals[modal as any]
  ),
  [EventsStoreGetters.GET_ACTIVE_EVENT]: (state: EventsStoreState) => state.activeEvent,
  [EventsStoreGetters.GET_ACTIVE_EVENT_ATTENDEES]: (
    state: EventsStoreState,
  ) => state.activeEventAttendees,
};
