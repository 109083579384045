import BaseErrorHandler from '@/services/errors/BaseErrorHandler';
import JsonApiError from '@/typings/errors/JsonApiError';
import ErrorBag from '@/typings/errors/ErrorBag';
import Vue from 'vue';
import JsonApiSourceError from '@/typings/errors/JsonApiSourceError';

class ToastErrorHandler extends BaseErrorHandler<JsonApiError[]> {
  // eslint-disable-next-line class-methods-use-this
  protected normalizeError(errors: JsonApiError[]): ErrorBag[] {
    const errorsBag: ErrorBag[] = [];

    errors.forEach((error: JsonApiError) => {
      const field: string | undefined = ToastErrorHandler.resolveField(error.source);

      errorsBag.push({
        message: error.detail,
        title: error.title,
        status: error.status,
        field,
      });
    });

    return errorsBag;
  }

  public treatErrors(showOnlyOne?: boolean): void {
    this.errorsBag.forEach((errorBag: ErrorBag) => {
      if (showOnlyOne) Vue.toasted.clear();

      Vue.toasted.show(errorBag.message || errorBag.title);
    });
  }

  private static resolveField(source: JsonApiSourceError | undefined): string | undefined {
    if (source === undefined) {
      return undefined;
    }

    if (typeof source.pointer !== 'undefined') {
      return (source.pointer.split('/')).slice(-1)[0];
    }

    if (typeof source.parameter !== 'undefined') {
      return source.parameter;
    }

    return 'Unknown field';
  }

  private static treatNotFound(errorBag: ErrorBag): void {
    Vue.toasted.show(errorBag.message);
  }
}

export default ToastErrorHandler;
