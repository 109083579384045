import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import User from '@/typings/backend/jsonApiTranslations/user';

const BASE_URL = 'users';

export default {
  list(params: JsonApiConfiguration): Promise<AxiosResponse<User[]>> {
    return axios
      .get(BASE_URL, { params });
  },
};
