import CoreStoreMutations from '@/constants/store/modules/core/mutations';
import CoreStoreState from '@/typings/storeStates/CoreStoreState';
import Vue from 'vue';
import FilterRow from '@/typings/filters/layout/FilterRow';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';

export default {
  [CoreStoreMutations.SET_FILTERS_RENDERER](
    state: CoreStoreState, data: { filterRenderer: FilterRenderers, filters: FilterRow[] },
  ) {
    Vue.set(state.filtersRender, data.filterRenderer, data.filters);
  },
  [CoreStoreMutations.SET_FILTERS_RENDERER_DATA](
    state: CoreStoreState,
    // eslint-disable-next-line max-len
    data: { rowIndex: number, columnIndex: number, filterIndex: number, data: any, filterRenderer: FilterRenderers },
  ) {
    Vue.set(
      // eslint-disable-next-line max-len
      state.filtersRender[data.filterRenderer]![data.rowIndex].columns[data.columnIndex].filters[data.filterIndex],
      'data',
      data.data,
    );
  },
  [CoreStoreMutations.SET_LISTABLE_ITEMS_RESPONSE](
    state: CoreStoreState, data: { response: AxiosResponse, listableItem: ListableItem },
  ) {
    Vue.set(state.listableItemsResponse, data.listableItem, data.response);
  },
  [CoreStoreMutations.RESET_LISTABLE_ITEMS](
    state: CoreStoreState, listableItem: ListableItem,
  ) {
    Vue.delete(state.listableItemsResponse, listableItem);
  },
  [CoreStoreMutations.RESET_FILTERS_RENDERER](
    state: CoreStoreState, rendererType: FilterRenderers,
  ) {
    Vue.delete(state.filtersRender, rendererType);
  },
};
