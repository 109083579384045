

































































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import debounce from 'lodash.debounce';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import AttendeeFoundByEmail from '@/typings/backend/customResponses/AttendeeFoundByEmail';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';

const gymStoreModule = namespace('gyms');
const gymPlansStoreModule = namespace('gymPlans');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Step1 extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @gymPlansStoreModule.Getter(GymPlansStoreGetters.GET_GYM_PLANS) gymPlans!: GymPlan[];

  public email: string = '';

  public findByEmailDebounced: Function = debounce(this.findByEmail, 500);

  public attendeeFoundByEmail: AttendeeFoundByEmail = { wasFound: false };

  public madeFirstRequest: boolean = false;

  public isDisabledNextButton: boolean = true;

  public isAttendeeLinkedToGym: boolean = false;

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>
  }

  get foundByEmailMessage(): string {
    if (this.isAttendeeLinkedToGym) {
      return 'This email is already an attendee in this gym';
    }

    return this.attendeeFoundByEmail.wasFound
      ? 'This attendee already has an account. Please, confirm in the next step this is the correct information'
      : 'This attendee does\'t have an account yet. You will be able to create it in the next step';
  }

  public findByEmail(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid) => {
        if (!isValid) return;

        AttendeeApiService
          .findByEmail(this.email)
          .then((response) => {
            this.attendeeFoundByEmail = {
              ...response.data,
              wasFound: true,
            };
          })
          .catch(() => {
            this.attendeeFoundByEmail = {
              wasFound: false,
            };
          })
          .finally(() => {
            this.madeFirstRequest = true;
            this.isDisabledNextButton = false;
          });

        AttendeeApiService
          .list({ filter: { email: this.email, gym: this.activeGym.id } })
          .then((response) => {
            this.isAttendeeLinkedToGym = response.data.length > 0;
          });
      });
  }

  public handleStepClick(): void {
    this.$emit('step:forward', {
      attendee: { ...this.attendeeFoundByEmail, email: this.email },
    });
  }
}
