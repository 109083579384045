import axios from '@/plugins/axios/jsonApi';
import NewEmail from '@/typings/nativeEntities/emails/NewEmail';
import User from '@/typings/backend/jsonApiTranslations/user';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';

const BASE_URL = 'emails';

export default {
  postNewEmail(newEmail: NewEmail<User | ActivityGroup>, params: JsonApiConfiguration = {}) {
    const payload: any = {
      data: {
        type: 'emails',
        attributes: {
          subject: newEmail.subject,
          body: newEmail.body,
        },
        relationships: {
          gym: {
            data: {
              type: 'gyms',
              id: newEmail.gym!.id,
            },
          },
          recipients: {
            data: newEmail.entities.map((entity: any) => ({
              type: newEmail.entityType,
              id: entity.id,
            })),
          },
        },
      },
    };

    return axios
      .post(BASE_URL, payload, { params });
  },
};
