import ThirdPartyPayingStoreMutations from '@/constants/store/modules/thirdPartyPaying/mutations';
import ThirdPartyPayingStoreState from '@/typings/storeStates/thirdPartyPaying';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';

export default {
  [ThirdPartyPayingStoreMutations.SET_THIRD_PARTIES_PAYING](
    state: ThirdPartyPayingStoreState,
    thirdPartiesPaying: ThirdPartyPaying[],
  ) {
    state.thirdPartiesPaying = thirdPartiesPaying;
  },
};
