var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-1 v-data-table--clickable-row",attrs:{"fixed-header":"","hide-default-footer":"","show-select":"","headers":_vm.tableHeaders,"items":_vm.attendees,"single-select":false,"items-per-page":20,"loading":_vm.fetching,"disable-sort":""},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.additional)?[_c('attendee-status-indicator',{attrs:{"status":item.additional.status}})]:_vm._e()]}},{key:"item.dateOfBirth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getYearsOld(item.dateOfBirth))+" ")]}},{key:"item.activityGroups",fn:function(ref){
var item = ref.item;
return [(item.activityGroups)?[_vm._v(" "+_vm._s(item.activityGroups.map(function (a) { return a.name; }).join(', '))+" ")]:_vm._e()]}},{key:"item.plans",fn:function(ref){
var item = ref.item;
return [(item.plans)?[_vm._v(" "+_vm._s(item.plans.map(function (a) { return a.name; }).join(', '))+" ")]:_vm._e()]}}]),model:{value:(_vm.usersSelected),callback:function ($$v) {_vm.usersSelected=$$v},expression:"usersSelected"}}),_c('v-fade-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.usersSelected.length > 0),expression:"usersSelected.length > 0"}],staticStyle:{"top":"50%","transform":"translateY(-50%)"},attrs:{"fixed":"","dark":"","bottom":"","right":"","large":"","fab":"","color":"primary"},on:{"click":_vm.handleClickSendMessageButton}},[_c('v-icon',[_vm._v("mdi-message")])],1)],1),_c('send-message-users',{attrs:{"users":_vm.usersSelected,"gym":{id: _vm.activeGym.id}},model:{value:(_vm.isSendingMessage),callback:function ($$v) {_vm.isSendingMessage=$$v},expression:"isSendingMessage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }