









































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import usersStoreActions from '@/constants/store/modules/users/actions';
import { Loaders } from '@/typings/loaders';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import Company from '@/typings/backend/jsonApiTranslations/company';
import CompanyStoreMutations from '@/constants/store/modules/company/mutations';

const usersStore = namespace('users');
const companyStore = namespace('company');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class LoginView extends Vue {
  @usersStore.Action [usersStoreActions.POST_LOGIN_USER]: Function;

  @companyStore.Getter [CompanyStoreGetters.ALL_COMPANIES]: Company[];

  @companyStore.Mutation [CompanyStoreMutations.SET_ACTIVE_COMPANY]: Function;

  public showPassword: boolean = false;

  public userLogin: {username: string, password: string} = {
    username: process.env.NODE_ENV === 'development' ? 'demo@demo.com' : '',
    password: process.env.NODE_ENV === 'development' ? 'secretPassword' : '',
  };

  public loaders: Loaders = {
    login: false,
  };

  public hasUpdatedPassword: boolean = false;

  public hasComeFromEmailVerify: boolean = false;

  public created(): void {
    if (this.$route.query['comes-from-password-reset']) {
      this.hasUpdatedPassword = true;
      this.$router.replace({ name: 'login' });
    }

    if (this.$route.query['comes-from-email-verify']) {
      this.hasComeFromEmailVerify = true;
      this.$router.replace({ name: 'login' });
    }
  }

  public loginUser(): void {
    const data = { ...this.userLogin, provider: 'user' };

    this.loaders.login = true;
    this[usersStoreActions.POST_LOGIN_USER]({ data })
      .then(() => {
        this.$router.push({ name: 'companyDashboard' });
      })
      .catch(() => {
        this.$toasted.error('Invalid credentials');
      })
      .finally(() => {
        this.loaders.login = false;
      });
  }
}
