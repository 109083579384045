var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","no-data-text":"No logs available","headers":_vm.tableHeadersAll,"items":_vm.checkIns,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.checkIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.checkIn,'DD/MM/YYYY kk:mm'))+" ")]}},{key:"item.checkOut",fn:function(ref){
var item = ref.item;
return [(item.checkOut)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.checkOut,'DD/MM/YYYY kk:mm'))+" ")]:_vm._e()]}},{key:"item.plan.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan ? item.plan.name : 'Extra attendance')+" ")]}},{key:"item.attendee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.attendee.firstName) + " " + (item.attendee.lastName)))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }