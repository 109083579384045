import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
// @ts-ignore
import { deserialize } from 'deserialize-json-api';
import ToastErrorHandler from '@/services/errors/ToastErrorHandler';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import RecursivelyCamelCaseObject from '@/services/object/RecursivelyCamelCaseObject';
import isEmpty from 'lodash.isempty';

interface Params {
  filter: {[key: string]: [] | string | number}
}

const configuration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 8000,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
  paramsSerializer: (params: any) => {
    const finalParams: Params = { ...params };
    delete finalParams.filter;
    const filters: Params = { filter: {} };

    const ignoreValue: Function = (value: string | null | undefined) => value === undefined || value === null || value === '' || (Array.isArray(value) && value.length < 1);

    if (Object.prototype.hasOwnProperty.call(params, 'filter')) {
      Object.keys(params.filter).forEach((key: string) => {
        if (ignoreValue(params.filter[key])) {
          return;
        }

        filters.filter[key] = params.filter[key];
      });
    }

    let finalString = qs.stringify(finalParams, { arrayFormat: 'comma', encode: false });

    if (!isEmpty(filters.filter)) {
      finalString += `${finalString === '' ? '' : '&'}${qs.stringify(filters, { arrayFormat: 'brackets', encode: false })}`;
    }
    return finalString;
  },
};

const instance = axios.create(configuration);

instance.interceptors.response.use(response => ({
  ...response,
  ...deserialize(response.data, { transformKeys: 'camelCase' }),
  meta: RecursivelyCamelCaseObject(response.data.meta),
  links: RecursivelyCamelCaseObject(response.data.links),
}), (error: AxiosError) => {
  if (
    Object.prototype.hasOwnProperty.call(error, 'response')
    && Object.prototype.hasOwnProperty.call((error.response as AxiosResponse).data, 'errors')
  ) {
    const toastedErrorService: ToastErrorHandler = (
      new ToastErrorHandler((error.response as AxiosResponse).data.errors)
    );

    toastedErrorService.treatErrors(true);
  }

  if (error.response && error.response.status === 401) {
    store.commit(`users/${UserStoreMutations.SET_TOKEN}`, null);
    router.push({ name: 'login' });
  }

  return Promise.reject(error);
});

export default instance;
