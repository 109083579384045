













import { Component, Prop, Vue } from 'vue-property-decorator';
import GymAttendeeStatus from '@/constants/gymAttendee/GymAttendeeStatus';

@Component({})
export default class AttendeeStatusIndicator extends Vue {
  @Prop({ required: true })
  status!: number;

  @Prop({ default: null })
  textColor!: string | null;

  public gymAttendeeStatus = GymAttendeeStatus;
}
