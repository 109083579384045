








































































import {
  Prop, Vue, Component, Watch,
} from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import cloneDeep from 'lodash.clonedeep';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import { Loaders } from '@/typings/loaders';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import UserStoreActions from '@/constants/store/modules/users/actions';
import GetUserFullName from '@/services/user/GetUserFullName';
import ActivityGroup from '../../typings/backend/jsonApiTranslations/activityGroup';

const gymsStoreModule = namespace('gyms');
const usersStoreModule = namespace('users');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditActivityGroupCard extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @usersStoreModule.Mutation [UserStoreMutations.SET_USER_LIST]: Function;

  @usersStoreModule.Getter(UsersStoreGetters.ALL_USERS_LIST) users!: User[];

  @usersStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @usersStoreModule.Action [UserStoreActions.GET_LIST_USERS]: Function;

  @Prop({ required: true, type: Object as () => ActivityGroup })
  activityGroup!: ActivityGroup;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  public internalActivityGroup: ActivityGroup = {} as ActivityGroup;

  public loaders: Loaders = {
    fetchingUsers: false,
  };

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  public created(): void {
    this.internalActivityGroup = cloneDeep(this.activityGroup);
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(value: boolean) {
    this.$emit('input', value);
  }

  get includeOwnerUsers(): User[] {
    return [...this.users, { ...this.activeUser, fullName: GetUserFullName(this.activeUser) }];
  }

  public async handleClickSaveButton(): Promise<void> {
    const isValid: boolean = await this.$refs.validationObserver.validate();

    if (!isValid) return;

    this.$emit('activity-group:update', {
      activityGroup: this.internalActivityGroup,
    });
  }
}
