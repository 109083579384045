









































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ActivityGroupsStoreGetters from '@/constants/store/modules/activityGroups/getters';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import SearchAttendee from '@/components/attendee/SearchAttendee.vue';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';

const activityGroupsStoreModule = namespace('activityGroups');
const gymStoreModule = namespace('gyms');

@Component({
  components: {
    SearchAttendee,
  },
})
export default class EventDialogPeopleRow extends Vue {
  @activityGroupsStoreModule.Action [ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]: (
    { params }: { params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse>;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @activityGroupsStoreModule.Getter(
    ActivityGroupsStoreGetters.GET_ACTIVITY_GROUPS,
  ) activityGroups!: ActivityGroup[];

  @Prop({ required: true })
  activityGroupsGuests!: ActivityGroup[];

  @Prop({ required: true })
  attendeesGuests!: Attendee[];

  public selectedActivityGroup: ActivityGroup | null = null;

  get unselectedActivityGroups() {
    const userActivityGroupIds: string[] = this.activityGroupsGuests.map(a => a.id);

    return this.activityGroups.filter(a => !userActivityGroupIds.includes(a.id));
  }

  public created() {
    this[ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]({
      params: { filter: { gym: this.activeGym.id } },
    });
  }

  public onSelectActivityGroup(activityGroup: ActivityGroup) {
    this.$emit('update:activityGroupsGuests', [...this.activityGroupsGuests, activityGroup]);
  }

  public onSelectAttendee(attendee: Attendee) {
    this.$emit('update:attendeesGuests', [...this.attendeesGuests, attendee]);
  }

  public removeFromList(
    type: 'activityGroupsGuests' | 'attendeesGuests',
    resource: Attendee | ActivityGroup,
  ) {
    this.$emit(
      `update:${type}`,
      // @ts-ignore
      (this[type] as Attendee[] | ActivityGroup[]).filter(r => resource.id !== r.id),
    );
  }
}
