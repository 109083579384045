



































import { Component } from 'vue-property-decorator';
import Vue from 'vue';

@Component({})
export default class ThirdPartyPayingViewAttendeeFiltersComponent extends Vue {
  public inputTimeoutHandler: number | undefined = -1;

  public readonly TIMEOUT_INPUT: number = 400;

  public filters: {[key: string]: string | number | string[] | number[]} = {
    name: '',
    email: '',
  };

  public handleNameInput(input: string, filterName: string): void {
    clearTimeout(this.inputTimeoutHandler);

    this.inputTimeoutHandler = setTimeout(() => {
      if (typeof input === 'undefined') {
        const filters = { ...this.filters };
        delete filters[filterName];
        this.filters = filters;
      } else {
        this.filters[filterName] = input;
      }


      this.$emit('filters:input', this.filters);
    }, this.TIMEOUT_INPUT);
  }
}
