


































import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ default: 'Are you sure about this action?' })
  message!: string;

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }
}
