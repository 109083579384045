import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymsPlanStoreState from '@/typings/storeStates/gymsPlan';
import AttendeeGymPlan from '@/typings/backend/jsonApiTranslations/AttendeeGymPlan';

export default {
  [GymPlanStoreMutations.SET_GYM_PLANS](state: GymsPlanStoreState, gymPlans: GymPlan[]) {
    state.gymPlans = gymPlans;
  },
  [GymPlanStoreMutations.SET_ACTIVE_GYM_PLAN](state: GymsPlanStoreState, gymPlan: GymPlan) {
    state.activeGymPlan = gymPlan;
  },
  [GymPlanStoreMutations.SET_ACTIVE_DELETE_PLAN](
    state: GymsPlanStoreState,
    gymPlan: GymPlan | null,
  ) {
    state.deletePlan = gymPlan;
  },
  [GymPlanStoreMutations.SET_ACTIVE_ATTENDEE_PLANS](
    state: GymsPlanStoreState, gymPlans: AttendeeGymPlan[],
  ) {
    state.activeAttendeeGymPlans = gymPlans;
  },
};
