



















import { Component, Vue } from 'vue-property-decorator';
import PhotoRow from '@/components/attendee/attendeeRead/PhotoRow.vue';
import { namespace } from 'vuex-class';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';
import InformationRow from '@/components/attendee/attendeeRead/informationRow/InformationRow.vue';
import NotesRow from '@/components/attendee/attendeeRead/NotesRow.vue';
import PlansRow from '@/components/attendee/attendeeRead/PlansRow.vue';
import ActivityGroupsRow from '@/components/attendee/attendeeRead/ActivityGroupsRow.vue';
import PlanLogRow from '@/components/attendee/attendeeRead/PlanLogRow.vue';
import AttendanceLogRow from '@/components/attendee/attendeeRead/AttendanceLogRow.vue';

const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    AttendanceLogRow,
    PlanLogRow,
    ActivityGroupsRow,
    NotesRow,
    PhotoRow,
    InformationRow,
    PlansRow,
  },
})
export default class AttendeeView extends Vue {
  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Action [AttendeesStoreActions.GET_ATTENDEE]: (
    { params, id }: { params: JsonApiConfiguration, id: string },
  ) => Promise<AxiosResponse<Attendee>>

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE]: (
    attendee: Attendee | null
  ) => void;

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_IS_EDITING_ATTENDEE]: (
    editing: boolean
  ) => void;

  public created(): void {
    this[AttendeesStoreActions.GET_ATTENDEE]({
      id: this.$route.params.attendeeId,
      params: {
        include: ['address.country', 'phoneNumbers', 'additional', 'activityGroups'],
      },
    });
  }

  public destroyed() {
    this[AttendeeStoreMutations.SET_ACTIVE_ATTENDEE](null);
    this[AttendeeStoreMutations.SET_IS_EDITING_ATTENDEE](false);
  }
}
