enum GymsStoreActions {
  GET_GYMS = 'GET_GYMS',
  DELETE_GYM = 'DELETE_GYM',
  EDIT_GYM = 'EDIT_GYM',
  POST_GYM = 'POST_GYM',
  GET_GYM = 'GET_GYM',
  UNLINK_ATTENDEE_FROM_GYM = 'UNLINK_ATTENDEE_FROM_GYM',
  UNLINK_EMPLOYEE_FROM_GYM = 'UNLINK_EMPLOYEE_FROM_GYM',
  SET_ACTIVE_GYM = 'SET_ACTIVE_GYM',
  GET_CHECK_INS = 'GET_CHECK_INS',
}

export default GymsStoreActions;
