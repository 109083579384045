
































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymPlanCard from '@/components/gymPlans/GymPlanCard.vue';
import { Loaders } from '@/typings/loaders';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymPlanStoreMutations from '@/constants/store/modules/gymPlans/mutations';
import GymPlanDeleteModal from '@/components/gymPlans/GymPlanDeleteModal.vue';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import GymPlanApiService from '@/services/api/GymPlanApiService';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';
import filters from './configuration/Filters';

const gymPlanStoreModule = namespace('gymPlans');
const gymsStoreModule = namespace('gyms');

@Component({
  components: {
    GymPlanCard,
    GymPlanDeleteModal,
    ListableItems,
  },
})
export default class GymPlanTypesView extends Vue {
  @gymPlanStoreModule.Action [GymPlansStoreActions.GET_GYM_PLANS]: Function;

  @gymPlanStoreModule.Getter(GymPlansStoreGetters.GET_GYM_PLANS) gymsPlans!: GymPlan[];

  @gymPlanStoreModule.Mutation [GymPlanStoreMutations.SET_GYM_PLANS]: Function;

  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public loaders: Loaders = {
    fetchingPlans: true,
  };

  public service = Object.freeze(GymPlanApiService);

  public listableItem = Object.freeze(ListableItem);

  public filters = filters;

  public filtersRendererType = Object.freeze(FilterRenderers);

  get params(): JsonApiConfiguration {
    return {
      include: ['timeRestrictions', 'gym'],
      filter: {
        gym: this.activeGym.id,
      },
      sort: 'name',
    };
  }
}
