import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import CheckIn from '@/typings/backend/jsonApiTranslations/checkIn';
import AxiosResponse from '@/typings/backend/AxiosResponse';

const BASE_URL = 'additional-check-ins';

export default {
  checkIn(payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<CheckIn>> {
    return axios.post(BASE_URL, payload, { params });
  },
  checkout(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<CheckIn>> {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
};
