import TableHeader from '@/typings/tableHeaders';

const ActivityGroupTableHeaders: TableHeader[] = [
  {
    value: 'name',
    text: 'Name',
  },
  {
    value: 'actions',
    text: 'Actions',
  },
];

export default ActivityGroupTableHeaders;
