import MembershipType from '@/views/gymPlan/list/GymPlanList.vue';
import MembershipFee from '@/views/gymPlan/CreatePlan.vue';

export default [
  {
    path: 'list',
    name: 'planList',
    component: MembershipType,
  },
  {
    path: 'createPlan',
    name: 'createPlan',
    component: MembershipFee,
  },
];
