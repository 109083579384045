import BaseRepository from '@/repository/BaseRepository';
import GymPlanTimeRestriction
  from '@/typings/backend/jsonApiTranslations/gymPlanTimeRestriction';
import { AxiosResponse } from 'axios';
import TimeRestriction from '@/typings/nativeEntities/plans/timeRestriction';
import GymPlan from '@/typings/backend/jsonApiTranslations/gymPlan';
import GymPlanTimeRestrictionService from '@/services/api/GymPlanTimeRestrictionService';

class GymPlanTimeRestrictionRepository extends BaseRepository {
  async create(
    { timeRestriction, gymPlan }: { timeRestriction: TimeRestriction, gymPlan: GymPlan },
  ): Promise<AxiosResponse<GymPlanTimeRestriction>> {
    return GymPlanTimeRestrictionService.postGymPlanTimeRestriction(
      timeRestriction,
      gymPlan,
      this.getParameters('createGymPlanTimeRestriction'),
    );
  }

  async edit(
    { timeRestriction, gymPlan }: { timeRestriction: GymPlanTimeRestriction, gymPlan: GymPlan },
  ): Promise<AxiosResponse<GymPlanTimeRestriction>> {
    return GymPlanTimeRestrictionService
      .updateGymPlanTimeRestriction(
        timeRestriction,
        gymPlan,
        this.getParameters('editGymPlanTimeRestriction'),
      );
  }
}

export default GymPlanTimeRestrictionRepository;
