import axios from '@/plugins/axios/jsonApi';
import ThirdPartyPayingStoreActions from '@/constants/store/modules/thirdPartyPaying/actions';
import NewThirdPartyPaying
  from '@/typings/nativeEntities/thidPartyPaying/newThirdPartyPaying';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import RootStoreState from '@/typings/storeStates/rootState';
import GymTranslated from '@/typings/backend/jsonApiTranslations/gym';
import ThirdPartyPaying from '@/typings/backend/jsonApiTranslations/thirdPartyPaying';
import ThirdPartyPayingStoreMutations from '@/constants/store/modules/thirdPartyPaying/mutations';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import ThirdPartyPayingApiService from '@/services/api/ThirdPartyPayingApiService';

const BASE_URL = 'paying-groups';

export default {
  async [ThirdPartyPayingStoreActions.GET_THIRD_PARTY_PAYING](
    { commit }: { commit: Function },
    { thirdPartyPayingId, params }: {
      thirdPartyPayingId: string,
      params: JsonApiConfiguration
    },
  ): Promise<AxiosResponse<ThirdPartyPaying>> {
    return ThirdPartyPayingApiService
      .read(thirdPartyPayingId, params)
      .then((response: AxiosResponse<ThirdPartyPaying>) => response);
  },

  async [ThirdPartyPayingStoreActions.GET_THIRD_PARTIES_PAYING](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse<ThirdPartyPaying[]>> {
    return axios
      .get(BASE_URL, { params })
      .then((response: AxiosResponse<ThirdPartyPaying[]>) => {
        commit(ThirdPartyPayingStoreMutations.SET_THIRD_PARTIES_PAYING, response.data);

        return response;
      });
  },

  async [ThirdPartyPayingStoreActions.POST_THIRD_PARTY_PAYING](
    { commit, rootState }: { commit: Function, rootState: RootStoreState },
    { thirdPartyPaying, params, gym }: {
      thirdPartyPaying: NewThirdPartyPaying,
      params: JsonApiConfiguration,
      gym: GymTranslated
    },
  ): Promise<AxiosResponse<ThirdPartyPaying>> {
    const payload = {
      data: {
        type: 'paying-groups',
        attributes: {
          name: thirdPartyPaying.name,
        },
        relationships: {
          createdBy: {
            data: { type: 'users', id: rootState.users.user.id },
          },
          gym: {
            data: { type: 'gyms', id: gym.id },
          },
          attendees: {
            data: [
              // {
              //   type: 'attendees',
              //   id: '01e7d5cf-21e2-4b17-a010-d960f9b726b0',
              // },
            ],
          },
        },
      },
    };
    return ThirdPartyPayingApiService
      .create(payload, params)
      .then((response: AxiosResponse<ThirdPartyPaying>) => response);
  },

  async [ThirdPartyPayingStoreActions.EDIT_THIRD_PARTY_PAYING](
    { commit }: { commit: Function },
    { thirdPartyPaying, params }: {
      thirdPartyPaying: ThirdPartyPaying,
      params: JsonApiConfiguration
    },
  ): Promise<AxiosResponse<ThirdPartyPaying>> {
    const payload = {
      data: {
        id: thirdPartyPaying.id,
        type: 'paying-groups',
        attributes: {
          name: thirdPartyPaying.name,
        },
        relationships: {
          attendees: {
            data: thirdPartyPaying.attendees.map(attendee => ({
              type: 'attendees',
              id: attendee.id,
            })),
          },
        },
      },
    };

    return ThirdPartyPayingApiService
      .edit(thirdPartyPaying.id, payload, params)
      .then((response: AxiosResponse<ThirdPartyPaying>) => response);
  },

  async [ThirdPartyPayingStoreActions.DELETE_THIRD_PARTY_PAYING_ATTENDEE](
    { commit }: { commit: Function },
    { attendees, thirdPartyPaying }: {
      attendees: Attendee[],
      thirdPartyPaying: ThirdPartyPaying
    },
  ): Promise<AxiosResponse<null>> {
    const configuration: AxiosRequestConfig = {
      data: attendees.map(attendee => ({
        type: 'attendees',
        id: attendee.id,
      })),
    };

    return ThirdPartyPayingApiService
      .deleteAttendee(thirdPartyPaying.id, configuration);
  },
};
