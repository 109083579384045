var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h1',{staticClass:"text-center mb-2 mt-5"},[_vm._v(" Create a Company ")]),_c('div',{staticClass:"subtitle-1 text-center mb-8"},[_vm._v(" Having one company allows you to have multiple gyms ")]),_c('ValidationObserver',{ref:"validator"},[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"company.name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company Name *","name":"companyName","outlined":"","error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}])})],1),_c('v-col',{attrs:{"md":"12","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"company.email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company Email *","name":"companyEmail","outlined":"","error-messages":errors},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"name":"OIB","rules":"required|min:11","custom-messages":{ min: 'OIB must be 11 digits' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Personal Identification Number *","name":"companyOib","outlined":"","error-messages":errors},model:{value:(_vm.company.personalIdentificationNumber),callback:function ($$v) {_vm.$set(_vm.company, "personalIdentificationNumber", $$v)},expression:"company.personalIdentificationNumber"}})]}}])})],1),_c('v-col',{attrs:{"md":"12","sm":"12","cols":"sm"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","item-value":"id","return-object":"","outlined":"","label":"Country *","items":_vm.countries,"error-messages":errors},model:{value:(_vm.company.address.country),callback:function ($$v) {_vm.$set(_vm.company.address, "country", $$v)},expression:"company.address.country"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"company.address.name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Company Address *","name":"companyAddress","outlined":"","error-messages":errors},model:{value:(_vm.company.address.name),callback:function ($$v) {_vm.$set(_vm.company.address, "name", $$v)},expression:"company.address.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"text":""},on:{"click":_vm.handleClickCreateCompanyButton}},[_vm._v(" Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }