






















import Vue from 'vue';
import Component from 'vue-class-component';
import TableHeader from '@/typings/tableHeaders';
import { namespace } from 'vuex-class';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import UserApiService from '@/services/api/UserApiService';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';
import StaffMemberListTable from '@/components/pages/staffMemberList/StaffMemberListTable.vue';
import filters from './configuration/Filters';
import tableHeaders from './configuration/tableHeaders';

const gymsStoreModule = namespace('gyms');

@Component({
  components: {
    ListableItems,
    StaffMemberListTable,
  },
})
export default class StaffMemberListView extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public headers: TableHeader[] = tableHeaders;

  public service = Object.freeze(UserApiService);

  public listableItem = Object.freeze(ListableItem);

  public filters = filters;

  public filtersRendererType = Object.freeze(FilterRenderers);

  get params(): JsonApiConfiguration {
    return {
      include: ['address', 'phoneNumbers'],
      filter: {
        employedAt: this.activeGym.id,
      },
    };
  }
}
