












































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import CountryStoreActions from '@/constants/store/modules/country/actions';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Country from '@/typings/backend/jsonApiTranslations/country';
import NewEmployee from '@/typings/nativeEntities/invitation/NewEmployee';
import userLocale from '@/services/user/userLocale';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import { Loaders } from '@/typings/loaders';
import UserStoreActions from '@/constants/store/modules/users/actions';
import User from '@/typings/backend/jsonApiTranslations/user';
import EmployeeInvitationApiService from '@/services/api/EmployeeInvitationApiService';
import PhoneNumberService from '@/services/api/PhoneNumberService';
import NewPhoneNumber from '@/typings/nativeEntities/phoneNumbers/PhoneNumber';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';

const countryStoreModule = namespace('countries');
const userStoreModule = namespace('users');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class StaffMemberNew extends Vue {
  @countryStoreModule.Action [CountryStoreActions.GET_COUNTRIES]: Function;

  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @userStoreModule.Action[UserStoreActions.POST_LOGIN_USER]: (
    { data }: { data: {username: string, password: string} },
  ) => Promise<AxiosResponse>;

  @userStoreModule.Action [UserStoreActions.GET_ME]: (
    { params }: { params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse<User>>;

  public payloadBuilder: PayloadBuilder = new PayloadBuilder('phone-numbers');

  public showPassword: boolean = false;

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  public loader: Loaders = {
    creatingEmployee: false,
  };

  public newMember: NewEmployee = {
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    locale: userLocale(),
    country: null,
    invitationID: '',
  };

  public async handleCreateAccount(): Promise<void> {
    this
      .$refs
      .validationObserver
      .validate()
      .then(async (isValid: boolean) => {
        if (!isValid) return;

        try {
          this.loader.creatingEmployee = true;
          const newEmployee = { ...this.newMember, invitationID: this.$route.params.invitationId };
          const params = { include: ['gym', 'company'] };
          const response = await EmployeeInvitationApiService
            .postAcceptAndCreate(newEmployee, params);

          await this[UserStoreActions.POST_LOGIN_USER]({
            data: {
              username: response.data.email,
              password: this.newMember.password,
            },
          });

          const userAxiosResponse = await this[UserStoreActions.GET_ME]({});
          const payload = this
            .payloadBuilder
            .setAttributes({ number: this.newMember.phoneNumber })
            .setRelationship(
              'owner',
              { type: 'users', id: userAxiosResponse.data.id },
            )
            .setRelationship(
              'country',
              { type: 'countries', id: this.newMember.country!.id },
            )
            .resolvePayload();

          await PhoneNumberService.postPhoneNumber(payload);

          this.loader.creatingEmployee = false;
          await this.$router.push({ name: 'companyDashboard' });
        } catch (e) {
          this.loader.creatingEmployee = false;
        }
      });
  }
}
