



















import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import { AxiosResponse } from 'axios';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import { Loaders } from '@/typings/loaders';
import FilterRow from '@/typings/filters/layout/FilterRow';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import AttendeeApiService from '@/services/api/AttendeeApiService';
import AttendeeListTable from '@/components/pages/attendeesList/AttendeeListTable.vue';
import ListableItem from '@/constants/listableItems/ListableItem';
import FilterRenderers from '@/constants/filters/FilterRenderers';

const gymsStoreModule = namespace('gyms');
const activityGroupStoreModule = namespace('activityGroups');
const gymPlanStoreModule = namespace('gymPlans');

@Component({
  components: {
    AttendeeListTable,
    ListableItems,
  },
})
export default class UsersView extends Vue {
  @gymsStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @activityGroupStoreModule.Action [ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]: (
    { params }: { params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @gymPlanStoreModule.Action [GymPlansStoreActions.GET_GYM_PLANS]: (
    { params }: { params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  public usersSelected = [];

  public loading: Loaders = {
    gettingUsers: false,
    editingUser: false,
  };

  public currentPage: number = 1;

  public attendeeApiService = AttendeeApiService;

  public filtersRendererType = Object.freeze(FilterRenderers.AttendeeMainTable);

  public filters!: FilterRow[];

  public componentsInitialized = false;

  public listableItem = Object.freeze(ListableItem.AttendeesMainTable);

  public async created(): Promise<void> {
    await this.initializedDependencyData();
    this.filters = (await import('./configuration/filters')).default;
    this.componentsInitialized = true;
  }

  get parameters(): JsonApiConfiguration {
    return {
      include: ['address.country', 'phoneNumbers', 'activityGroups', 'payingGroups', 'additional', 'plans'],
      filter: {
        gym: this.activeGym.id,
      },
    };
  }

  private initializedDependencyData() {
    const params = {
      filter: {
        gym: this.activeGym.id,
      },
    };

    return Promise.all([
      this[ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]({ params }),
      this[GymPlansStoreActions.GET_GYM_PLANS]({ params }),
    ]);
  }
}
