

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import GymNotes from '@/components/gyms/GymNotes.vue';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { namespace } from 'vuex-class';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { Loaders } from '@/typings/loaders';
import StatisticsActiveAttendees from '@/components/statistics/ActiveAttendees.vue';
import AttendeeLogTable from '@/components/attendeeLog/AttendeeLogTable.vue';
import GymAttendeeStatus from '@/constants/gymAttendee/GymAttendeeStatus';
import StatisticsApiService from '@/services/api/StatisticsApiService';
import PaginationService from '@/services/pagination/PaginationService';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Page from '@/typings/backend/pagination/Page';
import { Watch } from 'vue-property-decorator';
import StatisticsGymAttendanceFrequency from
  '@/components/statistics/StatisticsGymAttendanceFrequency.vue';
import StatisticsMembershipsDistribution
  from '@/components/statistics/StatisticsMembershipsDistribution.vue';
import StatisticsActivityGroupDistribution
  from '@/components/statistics/StatisticsActivityGroupDistribution.vue';
import AttendanceLog from '@/typings/backend/jsonApiTranslations/attendanceLog';

const gymStoreModule = namespace('gyms');
const paginationService = new PaginationService(10, 1);

@Component({
  components: {
    'gym-notes': GymNotes,
    StatisticsActiveAttendees,
    AttendeeLogTable,
    StatisticsGymAttendanceFrequency,
    StatisticsMembershipsDistribution,
    StatisticsActivityGroupDistribution,
  },
})
export default class GymDashboardView extends Vue {
  @gymStoreModule.Action [GymsStoreActions.GET_GYM]: Function;

  @gymStoreModule.Action [GymsStoreActions.GET_CHECK_INS]: (
    { params }: { params: JsonApiConfiguration }
  ) => Promise<AxiosResponse>;

  @gymStoreModule.Getter(GymStoreGetters.GET_CHECK_INS) checkIns!: AttendanceLog[];

  @gymStoreModule.Getter(GymStoreGetters.GET_CHECK_INS_PAGINATION) checkInPagination!: Page | null;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) gym!: Gym;

  @Watch('checkInCurrentPage')
  private watchCheckInCurrentPage(page: number): void {
    paginationService.setPage(page);
    this.getCheckIns();
  }

  public checkInCurrentPage: number = 1;

  public loaders: Loaders = {
    fetchingGym: false,
    fetchingCheckIns: false,
  };

  public attendeeData = {
    [GymAttendeeStatus.Active]: 0,
    [GymAttendeeStatus.Inactive]: 0,
    [GymAttendeeStatus.Paused]: 0,
  }

  public gymCapacity: number = 0;

  public gymAttendeeStatus = GymAttendeeStatus;

  public async created(): Promise<void> {
    this.getCheckIns();
    this.getAttendeeStats();
    this.getGymCapacity();
  }

  private getAttendeeStats(): void {
    StatisticsApiService
      .activeAttendees(this.gym.id)
      .then((response) => {
        const active = response.data.data.find(d => d.status === GymAttendeeStatus.Active);
        const inactive = response.data.data.find(d => d.status === GymAttendeeStatus.Inactive);
        const paused = response.data.data.find(d => d.status === GymAttendeeStatus.Paused);

        this.attendeeData = {
          [GymAttendeeStatus.Active]: (active && active.total) ? active.total : 0,
          [GymAttendeeStatus.Inactive]: (inactive && inactive.total) ? inactive.total : 0,
          [GymAttendeeStatus.Paused]: (paused && paused.total) ? paused.total : 0,
        };
      });
  }

  private async getCheckIns(): Promise<void> {
    const params = {
      sort: '-check-in',
      include: ['plan', 'attendee'],
      filter: {
        gym: this.gym.id,
      },
      page: paginationService.getPaginationObject(),
    };

    this.loaders.fetchingCheckIns = true;
    await this[GymsStoreActions.GET_CHECK_INS]({ params });
    this.loaders.fetchingCheckIns = false;
  }

  private getGymCapacity(): void {
    StatisticsApiService
      .gymCapacity(this.gym.id)
      .then((response) => {
        this.gymCapacity = response.data.data.capacity;

        return response;
      });
  }
}
