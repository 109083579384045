import BaseRepository from '@/repository/BaseRepository';
import PhoneNumber from '@/typings/nativeEntities/phoneNumbers/PhoneNumber';
import { AxiosResponse } from 'axios';
import PhoneNumberTranslated from '@/typings/backend/jsonApiTranslations/phoneNumber';
import Country from '@/typings/backend/jsonApiTranslations/country';
import PhoneNumberService from '@/services/api/PhoneNumberService';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';

class PhoneNumberRepository extends BaseRepository {
  public payloadBuilder: PayloadBuilder = new PayloadBuilder('phone-numbers');

  public async create(
    { phoneNumber, country }: {phoneNumber: PhoneNumber, country: Country},
  ): Promise<AxiosResponse<PhoneNumberTranslated>> {
    const payload = this
      .payloadBuilder
      .setAttributes({ number: phoneNumber.number })
      .setRelationship(
        'owner',
        { id: phoneNumber.owner.id, type: phoneNumber.owner.type },
      )
      .setRelationship(
        'country',
        { id: country.id, type: 'countries' },
      )
      .resolvePayload();

    return PhoneNumberService
      .postPhoneNumber(
        payload,
        this.getParameters('createPhoneNumber'),
      );
  }

  public async edit(
    { phoneNumber }: { phoneNumber: PhoneNumberTranslated },
  ): Promise<AxiosResponse<PhoneNumberTranslated>> {
    return PhoneNumberService
      .editPhoneNumber(phoneNumber, this.getParameters('editPhoneNumber'));
  }
}

export default PhoneNumberRepository;
