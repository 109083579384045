















































import Vue from 'vue';
import Component from 'vue-class-component';
import Step1 from '@/components/pages/usersNew/Step1.vue';
import Step2 from '@/components/pages/usersNew/Step2.vue';
import Step3 from '@/components/pages/usersNew/Step3.vue';
import NewAttendee from '@/typings/nativeEntities/users/NewAtendee/NewAttendee';
import Attendee from '@/typings/nativeEntities/users/Attendee';
import AttendeeRepository from '@/repository/Attendee';
import ParametersMap from '@/typings/repository/parametersMap';
import { namespace } from 'vuex-class';
import GymPlansStoreActions from '@/constants/store/modules/gymPlans/actions';
import { AxiosResponse } from 'axios';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import ThirdPartyPayingStoreActions from '@/constants/store/modules/thirdPartyPaying/actions';
import { Loaders } from '@/typings/loaders';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import NewAttendeeSubscription
  from '@/typings/nativeEntities/users/NewAtendee/NewAttendeeSubscription';
import AttendeeFoundByEmail from '@/typings/backend/customResponses/AttendeeFoundByEmail';
import AttendeeTranslated from '@/typings/backend/jsonApiTranslations/attendee';
import GymApiService from '@/services/api/GymApiService';
import AttendeeGymPlansApiService from '@/services/api/AttendeeGymPlansApiService';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import ActivityGroupApiService from '@/services/api/ActivityGroupApiService';
import ThirdPartyPayingApiService from '@/services/api/ThirdPartyPayingApiService';

const gymPlansStoreModule = namespace('gymPlans');
const activityGroupStoreModule = namespace('activityGroups');
const thirdPartyPayingStoreModule = namespace('thirdPartyPaying');
const gymStoreModule = namespace('gyms');
const payloadBuilder = new PayloadBuilder('');

@Component({
  components: {
    Step1,
    Step2,
    Step3,
  },
})
export default class UsersNew extends Vue {
  @gymPlansStoreModule.Action [GymPlansStoreActions.GET_GYM_PLANS]: Function;

  @activityGroupStoreModule.Action [ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]: Function;

  @thirdPartyPayingStoreModule.Action [
    ThirdPartyPayingStoreActions.GET_THIRD_PARTIES_PAYING
  ]: Function;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  public step: number = 1;

  public newAttendee: NewAttendee = {} as NewAttendee;

  public attendeeFoundByEmail: AttendeeFoundByEmail = { wasFound: false };

  public loaders: Loaders = {
    fetchingInfoStepTwo: false,
    finish: false,
  };

  public async handleSecondStepUpdate({ attendee }: { attendee: Attendee | null }): Promise<void> {
    if (attendee) this.newAttendee = attendee;

    this.loaders.fetchingInfoStepTwo = true;

    this.loaders.fetchingInfoStepTwo = false;
    this.step = 3;
  }

  public created(): void {
    const params: JsonApiConfiguration = {
      filter: {
        gym: this.activeGym.id,
      },
    };

    this[GymPlansStoreActions.GET_GYM_PLANS]({ params });
    this[ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS]({ params });
    this[ThirdPartyPayingStoreActions.GET_THIRD_PARTIES_PAYING]({ params });
  }

  public handleFirstStepUpdate({ attendee }: { attendee: AttendeeFoundByEmail }): void {
    this.step = 2;

    this.attendeeFoundByEmail = attendee;
  }

  public async finishCreationAttendee(
    attendeeSubscription: NewAttendeeSubscription,
  ): Promise<void> {
    this.loaders.finish = true;
    const attendeeId = this.attendeeFoundByEmail.id!;

    try {
      if (!this.attendeeFoundByEmail.wasFound) {
        await this.createAttendee(attendeeSubscription);
      } else {
        await this.inviteAttendee(attendeeId, attendeeSubscription);
      }

      this.loaders.finish = false;
      await this.$router.push({ name: 'usersList', params: this.$route.params });
      this.$toasted.show('Attendee has been added');
    } catch (e) {
      this.loaders.finish = false;
    }
  }

  private async createAttendee(
    attendeeSubscription: NewAttendeeSubscription,
  ): Promise<AxiosResponse<AttendeeTranslated>> {
    const parameters: ParametersMap = {
      createAttendee: {
        include: ['address.country'],
      },
    };
    const attendeeRepository: AttendeeRepository = new AttendeeRepository(parameters);

    return attendeeRepository
      .create(
        // @ts-ignore
        { ...this.newAttendee, sendEmails: attendeeSubscription.sendEmails ? 1 : 0 },
        attendeeSubscription.plansInformation!,
        attendeeSubscription.groups,
        attendeeSubscription.thirdPartyPaying,
        this.activeGym,
        attendeeSubscription.barcode,
      );
  }

  private async inviteAttendee(
    attendeeId: string,
    attendeeSubscription: NewAttendeeSubscription,
  ): Promise<AxiosResponse> {
    let payload: any;

    const response = await this.addAttendeeToGym(attendeeId);
    const promises: any[] = [];

    for (let i = 0, { length } = attendeeSubscription.plansInformation.plans!; i < length; i += 1) {
      payload = payloadBuilder
        .resetPayload('attendee-plans')
        .setAttributes({ 'started-at': attendeeSubscription.plansInformation.from })
        .setRelationship(
          'plan',
          { id: attendeeSubscription.plansInformation.plans[i].id, type: 'gym-plans' },
        )
        .setRelationship(
          'attendee',
          { id: attendeeId, type: 'attendees' },
        )
        .resolvePayload();

      promises.push(AttendeeGymPlansApiService.postAttendeeGymPlan(payload));
    }

    if (attendeeSubscription.thirdPartyPaying) {
      payload = { data: [{ type: 'attendees', id: attendeeId }] };

      promises.push(
        ThirdPartyPayingApiService.addAttendee(attendeeSubscription.thirdPartyPaying.id, payload),
      );
    }

    for (let i = 0, { length } = attendeeSubscription.groups; i < length; i += 1) {
      payload = { data: [{ id: attendeeId, type: 'attendees' }] };

      promises.push(
        ActivityGroupApiService.addAttendee(attendeeSubscription.groups[i].id, payload),
      );
    }

    await Promise.all(promises);

    return response;
  }

  private async addAttendeeToGym(attendeeId: string): Promise<AxiosResponse> {
    const payload: any = {
      data: [{ type: 'attendees', id: attendeeId }],
    };

    return GymApiService
      .postAttendeeRelationship(this.activeGym.id, payload);
  }
}
