



























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import usersStoreActions from '@/constants/store/modules/users/actions';
import NewUser from '@/typings/nativeEntities/users/newUser';
import { Loaders } from '@/typings/loaders';
import userLocale from '@/services/user/userLocale';

const usersStore = namespace('users');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RegisterView extends Vue {
  @usersStore.Action [usersStoreActions.POST_REGISTER_USER]: Function;

  public showPassword: boolean = false;

  public newUser: NewUser = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    email: '',
    locale: userLocale(),
  };

  public loaders: Loaders = {
    registering: false,
  };

  public createNewUser(): void {
    const data = { ...this.newUser };

    this.loaders.registering = true;
    this[usersStoreActions.POST_REGISTER_USER]({ data })
      .then(() => {
        this.$router.push({ name: 'registerCompanyAfterRegister' });
      })
      .finally(() => {
        this.loaders.registering = false;
      });
  }
}
