import cloneDeep from 'lodash.clonedeep';
import BaseFilterRenderer from '@/typings/filters/types/BaseFilterRenderer';

class BaseFilterAxiosConstructor {
  protected getRangeName(filterName: string): {from: string, to: string} {
    return {
      from: `${filterName}From`,
      to: `${filterName}To`,
    };
  }

  protected cloneFilters<T>(filters: T): T {
    return cloneDeep(filters);
  }

  protected returnOriginalAxiosObject(filter: BaseFilterRenderer<any>): boolean {
    return filter.data && Array.isArray(filter.data) && filter.data.length < 1;
  }
}

export default BaseFilterAxiosConstructor;
