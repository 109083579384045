
























import { Component, Vue } from 'vue-property-decorator';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import ListableItem from '@/constants/listableItems/ListableItem';
import AttendanceLogApiService from '@/services/api/AttendanceLogApiService';
import AttendeeLogTable from '@/components/attendeeLog/AttendeeLogTable.vue';

const gymStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    ListableItems,
    AttendeeLogTable,
  },
})
export default class AttendanceLogRow extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  public listableItem = Object.freeze(ListableItem.AttendeeAttendanceLogs);

  public service = Object.freeze(AttendanceLogApiService);

  get jsonConfiguration(): JsonApiConfiguration {
    return {
      sort: '-check-in',
      include: ['plan'],
      filter: {
        gym: this.activeGym.id,
        attendee: this.activeAttendee.id,
      },
    };
  }
}
