







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import Event from '@/typings/backend/jsonApiTranslations/event';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import EventDialogDescriptionRow from '@/components/events/CreateEventDialog/EventDialogDescriptionRow.vue';
import EventDialogPeopleRow from '@/components/events/CreateEventDialog/EventDialogPeopleRow.vue';
import EventDialogDateRow from '@/components/events/CreateEventDialog/EventDialogDateRow.vue';
import EventDialogOptionsRow from '@/components/events/CreateEventDialog/EventDialogOptionsRow.vue';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
import EventPayloadBuilderDirector from '@/services/payloadBuilder/directors/EventPayloadBuilderDirector';
import EventsStoreActions from '@/constants/store/modules/events/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import Modals from '@/constants/Modals';
import ActivityGroup from '@/typings/backend/jsonApiTranslations/activityGroup';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import { ValidationObserver } from 'vee-validate';

const eventStoreModule = namespace('event');

@Component({
  components: {
    EventDialogDescriptionRow,
    EventDialogPeopleRow,
    EventDialogDateRow,
    EventDialogOptionsRow,
    ValidationObserver,
  },
})
export default class CreateEventDialog extends Vue {
  @eventStoreModule.Getter(
    EventsStoreGetters.GET_EDIT_CREATE_EVENT,
  ) currentEvent!: Event | CreateEventFactory | null;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_EDIT_CREATE_EVENT]: (
    event: Event | CreateEventFactory | null,
  ) => void;

  @eventStoreModule.Action[EventsStoreActions.CREATE_EVENT]: (
    { params, payload }: { params?: JsonApiConfiguration, payload: any },
  ) => Promise<AxiosResponse>;

  @eventStoreModule.Getter(
    EventsStoreGetters.MODAL_IS_OPEN,
  ) isModalOpen!: (modal: Modals) => boolean;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_MODAL]: (
    data: { modal: Modals, value: boolean },
  ) => void;

  @Watch('currentEvent', { deep: true })
  public watchCurrentEvent(event: Event | CreateEventFactory | null) {
    this.internalEvent = event;
  }

  public internalEvent: Event | CreateEventFactory | null = null;

  public eventDirectorPayloadBuilder = Object.freeze(new EventPayloadBuilderDirector());

  public activityGroupsGuests: ActivityGroup[] = [];

  public attendeesGuests: Attendee[] = [];

  public $refs!: {
    validator: InstanceType<typeof ValidationObserver>,
  }

  public readonly modal = Object.freeze(Modals.CreateEvent);

  public loaders = {
    saving: false,
  }

  get isOpen() {
    return this.isModalOpen(this.modal);
  }

  public onClose() {
    this.closeModal();
  }

  public async onSave() {
    if (!await this.$refs.validator.validate()) return;

    const payload = this.eventDirectorPayloadBuilder.create(
      {
        ...this.currentEvent,
        activityGroupGuests: this.activityGroupsGuests,
        attendeeGuests: this.attendeesGuests,
      } as CreateEventFactory,
    );

    this.loaders.saving = true;
    try {
      await this[EventsStoreActions.CREATE_EVENT]({ payload });
      this.loaders.saving = false;
      this.activityGroupsGuests = [];
      this.attendeesGuests = [];
      this.closeModal();
    } catch (e) {
      this.loaders.saving = false;
    }
  }

  private closeModal() {
    this[EventsStoreMutations.SET_EDIT_CREATE_EVENT](null);
    this[EventsStoreMutations.SET_MODAL]({
      modal: this.modal,
      value: false,
    });
  }
}
