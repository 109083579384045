import axios from '@/plugins/axios/jsonApi';
import NewInvitation from '@/typings/nativeEntities/employeeInvitation/NewInvitation';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import NewEmployee from '@/typings/nativeEntities/invitation/NewEmployee';

const BASE_URL = 'employee-invitations';

export default {
  postNewInvite(newInvite: NewInvitation, params: JsonApiConfiguration = {}) {
    const payload = {
      data: {
        type: 'employee-invitations',
        attributes: {
          email: newInvite.email,
        },
        relationships: {
          gym: {
            data: { id: newInvite.gym!.id, type: 'gyms' },
          },
        },
      },
    };

    return axios.post(BASE_URL, payload, { params });
  },
  postAcceptAndCreate(newEmployee: NewEmployee, params: JsonApiConfiguration = {}) {
    const payload = {
      data: {
        type: 'invited-employee-registrations',
        attributes: {
          'first-name': newEmployee.firstName,
          'last-name': newEmployee.lastName,
          password: newEmployee.password,
          locale: newEmployee.locale,
        },
        relationships: {
          invitation: {
            data: { id: newEmployee.invitationID, type: 'employee-invitations' },
          },
        },
      },
    };

    return axios.post('invited-employee-registrations', payload, { params });
  },
  postAcceptInvitation(invitationId: string, params: JsonApiConfiguration = {}) {
    const payload = {
      data: {
        type: 'employee-invitation-accepts',
        relationships: {
          invitation: {
            data: { id: invitationId, type: 'employee-invitations' },
          },
        },
      },
    };

    return axios.post('employee-invitation-accepts', payload, { params });
  },
  readEmployeeInvitationsChecks(id: string) {
    return axios.get(`employee-invitation-checks/${id}`);
  },
};
