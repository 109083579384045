import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';

const BASE_URL = 'password-resets';

export default {
  resetPassword(payload: any, params: JsonApiConfiguration = {}) {
    return axios
      .post(BASE_URL, payload, { params });
  },
};
