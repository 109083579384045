import EventsStoreState from '@/typings/storeStates/events';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: EventsStoreState = {
  events: [],
  activeEvent: null,
  activeEventAttendees: [],
  editCreateEvent: null,
  modals: {},
  privateTrainings: [],
  activePrivateTraining: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
