





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { namespace } from 'vuex-class';
import EventsStoreGetters from '@/constants/store/modules/events/getters';
import Event from '@/typings/backend/jsonApiTranslations/event';
import CreateEventFactory from '@/services/entityFactory/CreateEventFactory';
import EventsStoreMutations from '@/constants/store/modules/events/mutations';
import debounce from 'lodash.debounce';
import EventMinimumTimeRange from '@/constants/event/EventMinimumTimeRange';

const eventStoreModule = namespace('event');

type IEvent = {
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  dayCount: number,
}

@Component({})
export default class EventDialogDateRow extends Vue {
  @eventStoreModule.Getter(
    EventsStoreGetters.GET_EDIT_CREATE_EVENT,
  ) currentEvent!: Event | CreateEventFactory | null;

  @eventStoreModule.Mutation[EventsStoreMutations.SET_EDIT_CREATE_EVENT]: (
    event: Event | CreateEventFactory | null
  ) => void;

  @Watch('event.endDate')
  public watchEndDate(endDate: string): void {
    const startDateMoment = moment(this.event.startDate);
    const endDateMoment = moment(endDate);
    const timeDelta = endDateMoment.diff(startDateMoment, 'days');

    this.event.dayCount = timeDelta > 0 ? timeDelta : 0;
  }

  @Watch('event.startDate')
  public watchStartDate(startDate: string): void {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(this.event.endDate);

    if (startDateMoment.isSameOrAfter(endDateMoment)) {
      this.event.endDate = startDateMoment.format('YYYY-MM-DD');
    }
  }

  @Watch('event.startTime')
  public watchStartTime(startTime: string): void {
    const momentStartTime = moment(startTime, 'HH:mm');
    const momentEndTime = moment(this.event.endTime, 'HH:mm');

    if (momentStartTime.isAfter(momentEndTime)) {
      this.event.endTime = (
        momentStartTime.add(EventMinimumTimeRange.MinimumMinutes, 'minutes').format('HH:mm')
      );
    }
  }

  public nowMoment = moment();

  public event: IEvent = {
    startDate: this.nowMoment.format('YYYY-MM-DD'),
    startTime: this.nowMoment.format('HH:mm'),
    endDate: this.nowMoment.format('YYYY-MM-DD'),
    endTime: this.nowMoment.clone().add(1, 'hour').format('HH:mm'),
    dayCount: 0,
  }

  public modals = {
    dateFrom: false,
    dateTo: false,
    timeFrom: false,
    timeTo: false,
  };

  get startDateFormatted(): string {
    return moment(this.event.startDate).format('YYYY-MM-DD');
  }

  get endDateFormatted(): string {
    return moment(this.event.endDate as string).format('YYYY-MM-DD');
  }

  public created() {
    this.$watch('event', debounce(this.watchEvent, 500), { deep: true });
  }

  public allowedToDates(endDate: string): boolean {
    const startDateMoment = moment(this.event.startDate);
    const endDateMoment = moment(endDate);

    return startDateMoment.isSameOrBefore(endDateMoment);
  }

  public timeToAllowedHours(hour: number): boolean {
    // if from and to date are different than just allow any hour
    if (this.event.startDate !== this.event.endDate) {
      return true;
    }

    return moment(this.event.startTime, 'HH:mm').hour() <= hour;
  }

  public timeToAllowedMinutes(minute: number): boolean {
    // if from and to date are different than just allow any hour
    if (this.event.startDate !== this.event.endDate) {
      return true;
    }

    const startTimeMoment = moment(this.event.startTime, 'HH:mm');
    const endTimeMoment = moment(this.event.endTime, 'HH:mm');

    if (startTimeMoment.hour() === endTimeMoment.hour()) {
      return moment(this.event.startTime, 'HH:mm').minute() <= minute;
    }

    return true;
  }

  public watchEvent(event: IEvent) {
    // @ts-ignore
    this[EventsStoreMutations.SET_EDIT_CREATE_EVENT]({
      ...this.currentEvent,
      ...event,
      start: `${event.startDate} ${event.startTime}:00`,
      end: `${event.endDate} ${event.endTime}:00`,
    });
  }
}
