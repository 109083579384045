enum EventsStoreActions {
  GET_EVENTS = 'GET_EVENTS',
  DELETE_EVENT = 'DELETE_EVENT',
  EDIT_EVENT = 'EDIT_EVENT',
  GET_ACTIVE_EVENT_ATTENDEES = 'GET_ACTIVE_EVENT_ATTENDEES',
  CREATE_EVENT = 'CREATE_EVENT',
  GET_PRIVATE_GYM_TRAININGS = 'GET_PRIVATE_GYM_TRAININGS',
  UPDATE_PRIVATE_TRAINING = 'UPDATE_PRIVATE_TRAINING',
}

export default EventsStoreActions;
