import TrainerGroups from '@/views/activityGroups/activityGroupList/ActivityGroupList.vue';
import ScheduleGroups from '@/views/activityGroups/GroupCalendar.vue';
import NewActivityGroup from '@/views/activityGroups/ActivityGroupNew.vue';

export default [
  {
    path: 'create',
    name: 'activityGroupNew',
    component: NewActivityGroup,
  },
  {
    path: 'groupList',
    name: 'groupList',
    component: TrainerGroups,
  },
  {
    path: 'calendar',
    name: 'groupsCalendar',
    component: ScheduleGroups,
    meta: {
      fullWidth: true,
    },
  },
];
