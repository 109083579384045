import axios from '@/plugins/axios/jsonApi';
import NewEvent from '@/typings/nativeEntities/event/newEvent';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import EventDefinition from '@/typings/backend/jsonApiTranslations/eventDefinition';
import EventFrequencyEndType from '@/constants/event/frequency/EndType';

const BASE_URL = 'event-definitions';

export default {
  create(payload: any, params: JsonApiConfiguration = {}) {
    return axios.post(BASE_URL, payload, { params });
  },
  delete(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
};
