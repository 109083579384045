











































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import AttendeeNoteMessage from '@/components/attendeeNotes/AttendeeNoteMessage.vue';
import AttendeeNote from '@/typings/backend/jsonApiTranslations/attendeeNote';
import { Loaders } from '@/typings/loaders';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import User from '@/typings/backend/jsonApiTranslations/user';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import { VSheet } from 'vuetify/lib';
import AttendeeStoreMutations from '@/constants/store/modules/attendees/mutations';

const attendeeStoreModule = namespace('attendees');
const payloadBuilder = new PayloadBuilder('gym-attendee-notes');
const userStoreModule = namespace('users');
const gymStoreModule = namespace('gyms');

@Component({
  components: {
    AttendeeNoteMessage,
  },
})
export default class NotesRow extends Vue {
  @attendeeStoreModule.Action [AttendeesStoreActions.GET_ATTENDEE_NOTES]: (
    { params }: { params?: JsonApiConfiguration },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ATTENDEE_NOTES) notes!: AttendeeNote[];

  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_USER) activeUser!: User;

  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Action [AttendeesStoreActions.CREATE_NEW_NOTE]: (
    { params, payload }: { params?: JsonApiConfiguration, payload: any },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Mutation[AttendeeStoreMutations.SET_ATTENDEE_NOTES]: (
    notes: AttendeeNote[]
  ) => void;

  public loaders: Loaders = {
    fetchingNotes: true,
    creatingNote: false,
  }

  public newNote: string = '';

  public $refs!: {
    notes: InstanceType<typeof VSheet>,
  }

  public async created() {
    await this[AttendeesStoreActions.GET_ATTENDEE_NOTES]({
      params: {
        sort: '-created-at',
        filter: {
          attendee: this.activeAttendee.id,
        },
      },
    });

    this.loaders.fetchingNotes = false;
    this.scrollNotesToBottom();
  }

  public destroyed() {
    this[AttendeeStoreMutations.SET_ATTENDEE_NOTES]([]);
  }

  public async onCreateNewNote(): Promise<void> {
    if (this.newNote === '') return;

    const payload = payloadBuilder
      .resetPayload()
      .setAttributes({
        message: this.newNote,
      })
      .setRelationship('createdBy', { type: 'users', id: this.activeUser.id })
      .setRelationship('gym', { type: 'gyms', id: this.activeGym.id })
      .setRelationship('attendee', { type: 'attendees', id: this.activeAttendee.id });

    this.loaders.creatingNote = true;
    await this[AttendeesStoreActions.CREATE_NEW_NOTE]({
      payload: payload.resolvePayload(),
    });
    this.loaders.creatingNote = false;
    this.newNote = '';
    this.scrollNotesToBottom();
  }

  private scrollNotesToBottom(): void {
    this.$nextTick(() => {
      this.$refs.notes.$el.scrollTop = this.$refs.notes.$el.scrollHeight;
    });
  }
}
