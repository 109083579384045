import axios from '@/plugins/axios/jsonApi';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import PhoneNumberTranslated from '@/typings/backend/jsonApiTranslations/phoneNumber';

const BASE_URL = 'phone-numbers';

export default {
  postPhoneNumber(
    payload: any,
    params: JsonApiConfiguration = {},
  ) {
    return axios.post(BASE_URL, payload, { params });
  },
  editPhoneNumber(phoneNumber: PhoneNumberTranslated, params: JsonApiConfiguration = {}) {
    const payload = {
      data: {
        id: phoneNumber.id,
        type: 'phone-numbers',
        attributes: {
          number: phoneNumber.number,
        },
        relationships: {
          country: { data: { id: phoneNumber.country.id, type: 'countries' } },
        },
      },
    };

    return axios.patch(`${BASE_URL}/${phoneNumber.id}`, payload, { params });
  },
  deletePhoneNumber(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios.delete(`${BASE_URL}/${id}`, { params });
  },
  edit(id: string, payload: any, params: JsonApiConfiguration = {}) {
    return axios.patch(`${BASE_URL}/${id}`, payload, { params });
  },
};
