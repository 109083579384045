





































import { Component, Vue } from 'vue-property-decorator';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import ListableItems from '@/components/listableItems/ListableItems.vue';
import AttendeePlanLogApiService from '@/services/api/AttendeePlanLogApiService';
import ListableItem from '@/constants/listableItems/ListableItem';
import PlanLogTableHeaders from '@/components/attendee/attendeeRead/configuration/PlanLogTableHeaders';
import EnumerationForHumans from '@/services/enumerations/EnumerationForHumans';
import AttendeePlanLogTranslation from '@/constants/attendeePlanLog/AttendeePlanLogTranslation';
import GymPlanPriceService from '@/services/gymPlan/GymPlanPriceService';

const gymStoreModule = namespace('gyms');
const attendeeStoreModule = namespace('attendees');

@Component({
  components: {
    ListableItems,
  },
})
export default class PlanLogRow extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_ACTIVE_GYM) activeGym!: Gym;

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  public service = AttendeePlanLogApiService;

  public listableItem = Object.freeze(ListableItem.AttendeePlanLog);

  public headers = Object.freeze(PlanLogTableHeaders);

  public attendeePlanLogTranslation = (
    Object.freeze(EnumerationForHumans(AttendeePlanLogTranslation))
  );

  public gymPlanPriceService = Object.freeze(GymPlanPriceService);

  get jsonParameters(): JsonApiConfiguration {
    return {
      include: ['plan'],
      filter: { gym: this.activeGym.id, attendee: this.activeAttendee.id },
    };
  }
}
