import UserStoreActions from '@/constants/store/modules/users/actions';
import NewUser from '@/typings/nativeEntities/users/newUser';
import axiosJsonApi from '@/plugins/axios/jsonApi';
import axiosGeneric from '@/plugins/axios/generic';
import { AxiosResponse } from 'axios';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import CompanyStoreActions from '@/constants/store/modules/company/actions';
import UserJsonApiTranslated from '@/typings/backend/jsonApiTranslations/user';
import UserStoreMutations from '@/constants/store/modules/users/mutations';
import UsersStoreState from '@/typings/storeStates/users';
import UserApiService from '@/services/api/UserApiService';
import orderBy from 'lodash.orderby';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { Route } from 'vue-router/types/router';
import GymApiService from '@/services/api/GymApiService';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import GetUserFullName from '@/services/user/GetUserFullName';

export default {
  async [UserStoreActions.POST_REGISTER_USER](
    { commit, dispatch }: { commit: Function, dispatch: Function },
    { data }: { data: NewUser },
  ): Promise<AxiosResponse<UserJsonApiTranslated>> {
    const payload = {
      data: {
        type: 'user-registers',
        attributes: {
          'last-name': data.lastName,
          'first-name': data.firstName,
          ...data,
        },
      },
    };

    return axiosJsonApi.post('user-registers', payload)
      .then(async (response: AxiosResponse<UserJsonApiTranslated>) => {
        await dispatch(UserStoreActions.POST_LOGIN_USER, {
          data: { username: data.email, password: data.password },
        });

        await commit(UserStoreMutations.SET_USER, response.data);

        return response;
      });
  },

  async [UserStoreActions.POST_LOGIN_USER](
    { commit, dispatch }: { commit: Function, dispatch: Function },
    { data }: { data: {username: string, password: string} },
  ): Promise<AxiosResponse> {
    const responseLogin: AxiosResponse = await axiosGeneric.post('token', {
      username: data.username,
      password: data.password,
    })
      .then((response: AxiosResponse) => {
        axiosJsonApi.defaults.headers.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;
        axiosGeneric.defaults.headers.Authorization = `${response.data.tokenType} ${response.data.accessToken}`;

        commit(UserStoreMutations.SET_TOKEN, {
          tokenType: response.data.tokenType,
          expiresIn: response.data.expiresIn,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          createdAt: (new Date()).toString(),
        });

        return response;
      });

    const response = await dispatch(UserStoreActions.GET_ME, {});

    commit(UserStoreMutations.SET_USER, response.data);

    await dispatch(
      `company/${CompanyStoreActions.GET_COMPANIES}`,
      { params: { include: 'address.country', filter: { owner: response.data.id } } },
      { root: true },
    );

    return responseLogin;
  },

  async [UserStoreActions.GET_ME](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return axiosJsonApi
      .get('users/me', { params })
      .then((response: AxiosResponse) => response);
  },

  async [UserStoreActions.GET_LIST_USERS](
    { commit }: { commit: Function },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return UserApiService
      .list(params)
      .then((response) => {
        commit(
          UserStoreMutations.SET_USER_LIST,
          orderBy(
            response.data.map(u => ({ ...u, fullName: GetUserFullName(u) })),
            ['fullName'],
          ),
        );

        return response;
      });
  },

  async [UserStoreActions.DELETE_USER](
    { commit, state }: { commit: Function, state: UsersStoreState },
    { user }: { user: UserJsonApiTranslated },
  ): Promise<AxiosResponse<null>> {
    return axiosJsonApi
      .delete(`users/${user.id}`)
      .then((response: AxiosResponse<null>) => {
        const users = [...state.userList];

        users.splice(
          users.findIndex(user_ => user_.id === user.id),
          1,
        );

        commit(UserStoreMutations.SET_USER_LIST, users);

        return response;
      });
  },

  async [UserStoreActions.EDIT_USER](
    { commit, state }: { commit: Function, state: UsersStoreState },
    { user, params }: { user: UserJsonApiTranslated, params: JsonApiConfiguration },
  ): Promise<AxiosResponse<UserJsonApiTranslated>> {
    const payload = {
      data: {
        type: 'users',
        id: user.id,
        attributes: {
          email: user.email,
        },
      },
    };

    return axiosJsonApi
      .patch(`users/${user.id}`, payload, { params })
      .then((response: AxiosResponse<UserJsonApiTranslated>) => {
        const users = [...state.userList];

        users.splice(
          users.findIndex(user_ => user_.id === user.id),
          1,
          response.data,
        );

        commit(UserStoreMutations.SET_USER_LIST, users);

        return response;
      });
  },

  async [UserStoreActions.INITIALIZE_NECESSARY_DATA](
    { commit, state, dispatch }: { commit: Function, state: UsersStoreState, dispatch: Function },
    { route }: { route: Route },
  ): Promise<void> {
    // There are several data dependency that our app needs to
    // be able to function properly. In this action we will fetch
    // the companies owned by the logged in user, the logged user
    // info and if the router that he is visiting right now has
    // an active gym we need to get such active gym
    const response = await dispatch(UserStoreActions.GET_ME, {});

    commit(UserStoreMutations.SET_USER, response.data);

    await dispatch(
      `company/${CompanyStoreActions.GET_COMPANIES}`,
      { params: { include: 'address.country', filter: { owner: response.data.id } } },
      { root: true },
    );

    if (route.params.gymId) {
      await GymApiService
        .read(route.params.gymId)
        .then(async (r) => {
          await dispatch(
            `gyms/${GymsStoreActions.SET_ACTIVE_GYM}`,
            { gym: r.data },
            { root: true },
          );
        });
    }

    return Promise.resolve();
  },

};
