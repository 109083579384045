





























































































































































































































































import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Prop, Component, Watch } from 'vue-property-decorator';
import VueMoment from 'vue-moment';
import { namespace } from 'vuex-class';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import cloneDeep from 'lodash.clonedeep';
// @ts-ignore
import Swatches from 'vue-swatches';
import EventMinimumTimeRange from '@/constants/event/EventMinimumTimeRange';
import Event from '../../typings/backend/jsonApiTranslations/event';

const gymStoreModule = namespace('gyms');

interface EventWithDateAndTimeSeparate extends Event {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    Swatches,
  },
})
export default class EditEventDialogComponent extends Vue {
  @gymStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ required: true })
  event!: Event;

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>
    timeTo: any,
    timeFrom: any,
  };

  public internalEvent: EventWithDateAndTimeSeparate = {} as EventWithDateAndTimeSeparate;

  public modals: {[key: string]: boolean} = {
    dateFrom: false,
    dateTo: false,
    timeFrom: false,
    timeTo: false,
  };

  @Watch('internalEvent.startDate')
  public watchStartDate(startDate: string): void {
    const startDateMoment = this.$moment(startDate);
    const endDateMoment = this.$moment(this.internalEvent.endDate);

    if (startDateMoment.isSameOrAfter(endDateMoment)) {
      this.internalEvent.endDate = startDateMoment.format('YYYY-MM-DD');
    }
  }

  @Watch('event')
  public watchEvent(event: Event): void {
    this.internalEvent = this.eventToInternalEvent(event);
  }

  @Watch('internalEvent.startTime')
  public watchStartTime(startTime: string): void {
    const momentStartTime = this.$moment(startTime, 'HH:mm');
    const momentEndTime = this.$moment(this.internalEvent.endTime, 'HH:mm');

    if (momentStartTime.isAfter(momentEndTime)) {
      this.internalEvent.endTime = momentStartTime.add(EventMinimumTimeRange.MinimumMinutes, 'minutes').format('HH:mm');
    }
  }

  public created(): void {
    this.internalEvent = this.eventToInternalEvent(this.event);
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  get startDateFormatted(): string {
    return this.$moment(this.internalEvent.startDate).format('YYYY-MM-DD');
  }

  get endDateFormatted(): string {
    return this.$moment(this.internalEvent.endDate as string).format('YYYY-MM-DD');
  }

  public async handleCreateEvent() {
    const isValid: boolean = await this.$refs.validationObserver.validate();

    if (!isValid) return;

    this.$emit('event:edit', {
      event: EditEventDialogComponent.normalizeEvent(this.internalEvent),
    });
  }

  public allowedToDates(endDate: string): boolean {
    const startDateMoment = this.$moment(this.internalEvent.startDate);
    const endDateMoment = this.$moment(endDate);

    return startDateMoment.isSameOrBefore(endDateMoment);
  }

  public timeToAllowedHours(hour: number): boolean {
    // if from and to date are different than just allow any hour
    if (this.internalEvent.startDate !== this.internalEvent.endDate) {
      return true;
    }

    return this.$moment(this.internalEvent.startTime, 'HH:mm').hour() <= hour;
  }

  public timeToAllowedMinutes(minute: number): boolean {
    // if from and to date are different than just allow any hour
    if (this.internalEvent.startDate !== this.internalEvent.endDate) {
      return true;
    }

    const startTimeMoment = this.$moment(this.internalEvent.startTime, 'HH:mm');
    const endTimeMoment = this.$moment(this.internalEvent.endTime, 'HH:mm');

    if (startTimeMoment.hour() === endTimeMoment.hour()) {
      return this.$moment(this.internalEvent.startTime, 'HH:mm').minute() <= minute;
    }

    return true;
  }

  private static normalizeEvent(event: EventWithDateAndTimeSeparate): Event {
    return {
      ...event,
      start: `${event.startDate} ${event.startTime}:00`,
      end: `${event.endDate} ${event.endTime}:00`,
    };
  }

  private eventToInternalEvent(event: Event): EventWithDateAndTimeSeparate {
    const clonedEvent: Event = cloneDeep(event);
    const startEvent = this.$moment(clonedEvent.start);
    const endEvent = this.$moment(clonedEvent.end);

    return {
      ...clonedEvent,
      startDate: startEvent.format('YYYY-MM-DD'),
      startTime: startEvent.format('HH:mm'),
      endDate: endEvent.format('YYYY-MM-DD'),
      endTime: endEvent.format('HH:mm'),
    };
  }
}
