




































































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import ThirdPartyPayingContact from '@/typings/backend/jsonApiTranslations/thirdPartyPayingContact';
import cloneDeep from 'lodash.clonedeep';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import CountryStoreActions from '@/constants/store/modules/country/actions';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Country from '@/typings/backend/jsonApiTranslations/country';

const countryStoreModule = namespace('countries');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditContactDialogComponent extends Vue {
  @countryStoreModule.Action [CountryStoreActions.GET_COUNTRIES]: Function;

  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @Prop({ required: true, type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ required: true, type: Object as () => ThirdPartyPayingContact })
  contact!: ThirdPartyPayingContact;

  public internalContact: ThirdPartyPayingContact = {} as ThirdPartyPayingContact;

  public $refs!: {
    validationObserver: InstanceType<typeof ValidationObserver>,
  };

  public created(): void {
    this[CountryStoreActions.GET_COUNTRIES]({});
    this.internalContact = cloneDeep(this.contact);
  }

  get isVisible(): boolean {
    return this.value;
  }

  set isVisible(newValue: boolean) {
    this.$emit('input', newValue);
  }

  public handleClickSaveButton(): void {
    this
      .$refs
      .validationObserver
      .validate()
      .then((isValid: boolean) => {
        if (!isValid) return;

        this.$emit('contact:edit', { contact: this.internalContact });
      });
  }
}
