export default [
  {
    text: 'Name',
    value: 'firstName',
  },
  {
    text: 'Last Name',
    value: 'lastName',
  },
  {
    text: 'Status',
    value: 'status',
  },
  {
    text: 'Age',
    value: 'dateOfBirth',
  },
  {
    text: 'Address',
    value: 'address.name',
  },
  {
    text: 'Activity Groups',
    value: 'activityGroups',
  },
  {
    text: 'Plans',
    value: 'plans',
  },
];
