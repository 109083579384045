















































import { Prop, Component } from 'vue-property-decorator';
import Vue from 'vue';
import Gym from '@/typings/backend/jsonApiTranslations/gym';

@Component
export default class GymComponent extends Vue {
  @Prop({ required: true })
  gym!: Gym;

  public handleClickEdit(): void {
    this.$emit('edit:click', { gym: this.gym });
  }

  public handleClickDelete(): void {
    this.$emit('delete:click', { gym: this.gym });
  }

  public handleSelectGymClick(): void {
    this.$emit('stats:click', { gym: this.gym });
  }
}
