








import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import GlobalSnackbar from '@/components/Snackbar.vue';

@Component({
  components: {
    GlobalSnackbar,
  },
})
export default class App extends Vue {
  public refreshing: boolean = false;

  public registration: any = null;

  public updateExists: boolean = false;

  public created(): void {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private updateAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
    this.refreshApp();
  }

  // Called when the user accepts the update
  public refreshApp(): void {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}
