











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import IconText from '@/components/icons/IconText.vue';
import Attendee from '@/typings/backend/jsonApiTranslations/attendee';
import Address from '@/typings/backend/jsonApiTranslations/address';
import { namespace } from 'vuex-class';
import AttendeeStoreGetters from '@/constants/store/modules/attendees/getters';
import cloneDeep from 'lodash.clonedeep';
import PayloadBuilder from '@/services/payloadBuilder/PayloadBuilder';
import AddressApiService from '@/services/api/AddressApiService';
import AttendeesStoreActions from '@/constants/store/modules/attendees/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import AxiosResponse from '@/typings/backend/AxiosResponse';
import Country from '@/typings/backend/jsonApiTranslations/country';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import PhoneNumber from '@/typings/backend/jsonApiTranslations/phoneNumber';
import PhoneNumberPayloadBuilderDirector from '@/services/payloadBuilder/directors/PhoneNumberPayloadBuilderDirector';
import PhoneNumberFactory from '@/services/entityFactory/PhoneNumberFactory';
import moment from 'moment';
import DateFormats from '@/constants/dates/DateFormats';
import MarketingRow from '@/components/attendee/attendeeRead/MarketingRow.vue';

type IInternalAttendee = Omit<Pick<Attendee, 'address' | 'email' | 'phoneNumbers' | 'additional' | 'dateOfBirth' | 'personalIdentificationNumber'>, 'phoneNumbers'> & {
  phoneNumbers: PhoneNumber[] | PhoneNumberFactory[]
};

const attendeeStoreModule = namespace('attendees');
const payloadBuilder = new PayloadBuilder('attendees');
const countryStoreModule = namespace('countries');

@Component({
  components: {
    IconText,
    MarketingRow,
  },
})
export default class EditInformationRow extends Vue {
  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @attendeeStoreModule.Getter(AttendeeStoreGetters.ACTIVE_ATTENDEE) activeAttendee!: Attendee;

  @attendeeStoreModule.Action[AttendeesStoreActions.EDIT_ATTENDEE]: (
    { params, attendee, payload }: {
      params?: JsonApiConfiguration,
      attendee: Attendee,
      payload: any
    },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.CREATE_NEW_PHONE_NUMBER]: (
    { params, payload }: { params?: JsonApiConfiguration, payload: any }
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.DELETE_PHONE_NUMBER]: (
    { params, phoneNumber }: { params?: JsonApiConfiguration, phoneNumber: PhoneNumber }
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action [AttendeesStoreActions.UPDATE_ATTENDEE_GYM_RELATIONSHIP]: (
    { params, payload, id }: { params?: JsonApiConfiguration, payload: any, id: string },
  ) => Promise<AxiosResponse>;

  @attendeeStoreModule.Action[AttendeesStoreActions.UPDATE_PHONE_NUMBER]: (
    { params, payload, id }: {
      params?: JsonApiConfiguration,
      payload: any,
      id: string
    },
  ) => Promise<AxiosResponse>;

  @Prop({ required: true, type: Boolean })
  isEditing!: boolean;

  public phoneNumberPayloadBuilder = Object.freeze(new PhoneNumberPayloadBuilderDirector());

  public loaders = {
    savingAttendee: false,
    creatingPhoneNumber: false,
  }

  public menus = {
    dateOfBirth: false,
  }

  public internalAttendee: IInternalAttendee = {
    address: {} as Address,
    email: '',
    phoneNumbers: [],
    dateOfBirth: '',
    personalIdentificationNumber: '',
  };

  get countryCroatia() {
    return this.countries.find(c => c.code === 'HR')!;
  }

  get dateOfBirthFormatted() {
    return this.internalAttendee.dateOfBirth
      ? moment(this.internalAttendee.dateOfBirth).format(DateFormats.frontendDate)
      : '';
  }

  public created() {
    this.internalAttendee = {
      dateOfBirth: this.activeAttendee.dateOfBirth,
      address: cloneDeep(this.activeAttendee.address),
      email: this.activeAttendee.email,
      phoneNumbers: this.activeAttendee.phoneNumbers
        ? cloneDeep(this.activeAttendee.phoneNumbers)
        : [{ ...new PhoneNumberFactory(), country: this.countryCroatia }],
      additional: cloneDeep(this.activeAttendee.additional),
      personalIdentificationNumber: this.activeAttendee.personalIdentificationNumber,
    };
  }

  public async onSave() {
    const payload = payloadBuilder
      .resetPayload('attendees')
      .setId(this.activeAttendee.id)
      .setAttributes({
        firstName: this.activeAttendee.firstName,
        lastName: this.activeAttendee.lastName,
        email: this.internalAttendee.email,
        date_of_birth: this.internalAttendee.dateOfBirth,
        locale: this.activeAttendee.locale,
        personal_identification_number: this.internalAttendee.personalIdentificationNumber,
      })
      .setRelationship('address', {
        type: 'addresses',
        id: this.internalAttendee.address.id,
      })
      .resolvePayload();

    this.loaders.savingAttendee = true;

    await this.editAddress();

    await this[AttendeesStoreActions.EDIT_ATTENDEE]({
      payload,
      attendee: this.activeAttendee,
      params: {
        include: ['address.country', 'phoneNumbers', 'activityGroups', 'payingGroups', 'additional', 'plans'],
      },
    });

    this.loaders.savingAttendee = true;
    this.$emit('update:isEditing', false);
  }

  public async onCreatePhoneNumber() {
    const payload = this
      .phoneNumberPayloadBuilder
      .create(this.internalAttendee.phoneNumbers[0], this.activeAttendee);

    await this[AttendeesStoreActions.CREATE_NEW_PHONE_NUMBER]({
      payload,
      params: { include: ['country'] },
    });

    this.updatePhoneNumbersFromActiveAttendee();
  }

  public onThirdPartyIdentifierBlur() {
    const payload = payloadBuilder
      .resetPayload('gym-attendees')
      .setId(this.internalAttendee.additional!.id)
      .setAttributes({
        'third-party-identifier': this.internalAttendee.additional!.thirdPartyIdentifier!,
        status: this.internalAttendee.additional!.status,
      });

    this[AttendeesStoreActions.UPDATE_ATTENDEE_GYM_RELATIONSHIP]({
      payload: payload.resolvePayload(),
      id: this.internalAttendee.additional!.id,
    });
  }

  public onPhoneNumberBlur() {
    if (Object.prototype.hasOwnProperty.call(this.internalAttendee.phoneNumbers[0], 'id')) {
      this.updatePhoneNumber();

      return;
    }

    this.onCreatePhoneNumber();
  }

  public updatePhoneNumber() {
    const payload = this
      .phoneNumberPayloadBuilder
      .edit(this.internalAttendee.phoneNumbers[0] as PhoneNumber);

    this[AttendeesStoreActions.UPDATE_PHONE_NUMBER]({
      payload,
      params: { include: ['country'] },
      id: (this.internalAttendee.phoneNumbers[0] as PhoneNumber).id,
    });
  }

  private async editAddress() {
    return AddressApiService.editAddress(this.internalAttendee.address);
  }

  private updatePhoneNumbersFromActiveAttendee() {
    this.internalAttendee.phoneNumbers = cloneDeep(this.activeAttendee.phoneNumbers);
  }
}
