import NewGym from '@/typings/nativeEntities/gyms/newGym';
import { AxiosResponse } from 'axios';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import store from '@/store/index';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import AddressTranslated from '@/typings/backend/jsonApiTranslations/address';
import AddressRepository from '@/repository/Address';
import BaseRepository from '@/repository/BaseRepository';
import ParametersMap from '@/typings/repository/parametersMap';

class GymRepository extends BaseRepository {
  private addressRepository: AddressRepository;

  constructor(params?: ParametersMap) {
    super(params);

    this.addressRepository = new AddressRepository();
  }

  public async create({ gym }: { gym: NewGym }): Promise<AxiosResponse<Gym>> {
    const { data: address }: { data: AddressTranslated } = (
      await this.addressRepository.create({ address: gym.address })
    );

    return store.dispatch(`gyms/${GymsStoreActions.POST_GYM}`, {
      gym,
      company: store.state.company.activeCompany,
      params: this.getParameters('createGym'),
      address,
    });
  }

  public async edit({ gym }: { gym: Gym }): Promise<AxiosResponse<Gym>> {
    await this.addressRepository.edit({ address: gym.address });

    return store.dispatch(`gyms/${GymsStoreActions.EDIT_GYM}`, {
      gym,
      params: this.getParameters('editGym'),
    });
  }
}

export default GymRepository;
