import axios from '@/plugins/axios/jsonApi';
import { AxiosResponse } from 'axios';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';

const BASE_URL = 'gyms';

export default {
  list(params: JsonApiConfiguration = {}): Promise<AxiosResponse<Gym[]>> {
    return axios
      .get(BASE_URL, { params });
  },
  delete(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<null>> {
    return axios
      .delete(`${BASE_URL}/${id}`, { params });
  },
  edit(id: string, payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<Gym>> {
    return axios
      .patch(`${BASE_URL}/${id}`, payload, { params });
  },
  create(payload: any, params: JsonApiConfiguration = {}): Promise<AxiosResponse<Gym>> {
    return axios
      .post(BASE_URL, payload, { params });
  },
  read(id: string, params: JsonApiConfiguration = {}): Promise<AxiosResponse<Gym>> {
    // The left navigation bar needs to have the relationship `createdBy`
    // always included in the gym
    const finalParameters: JsonApiConfiguration = {
      ...params,
      include: params.include ? [...params.include, 'createdBy'] : 'createdBy',
    };

    return axios
      .get(`${BASE_URL}/${id}`, { params: finalParameters });
  },
  postAttendeeRelationship(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse> {
    return axios
      .post(`${BASE_URL}/${id}/relationships/attendees`, payload, { params });
  },
  deleteAttendeeRelationship(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse> {
    return axios
      .delete(`${BASE_URL}/${id}/relationships/attendees`, { data: payload, params });
  },
  deleteEmployeeRelationship(
    id: string,
    payload: any,
    params: JsonApiConfiguration = {},
  ): Promise<AxiosResponse<null>> {
    return axios
      .delete(`${BASE_URL}/${id}/relationships/employees`, { data: payload, params });
  },
};
