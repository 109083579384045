

































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CreateCompanyComponent from '@/components/company/createCompany/CreateCompany.vue';
import Company from '@/typings/nativeEntities/company/company';
import CompanyStoreActions from '@/constants/store/modules/company/actions';
import { namespace } from 'vuex-class';
import CompanyStoreMutations from '@/constants/store/modules/company/mutations';
import { AxiosResponse } from 'axios';
import CompanyStoreGetters from '@/constants/store/modules/company/getters';
import CompanyJsonApiTranslated from '@/typings/backend/jsonApiTranslations/company';
import Address from '@/typings/backend/jsonApiTranslations/address';
import CompanyRepository from '@/repository/Company';
import ParametersMap from '@/typings/repository/parametersMap';

const companyStoreModule = namespace('company');

@Component({
  components: {
    'create-company': CreateCompanyComponent,
  },
})
export default class CreateCompanyView extends Vue {
  @companyStoreModule.Action [CompanyStoreActions.POST_NEW_COMPANY]: Function;

  @companyStoreModule.Mutation [CompanyStoreMutations.SET_ACTIVE_COMPANY]: Function;

  @companyStoreModule.Mutation [CompanyStoreMutations.SET_COMPANIES]: Function;

  @companyStoreModule.Getter(CompanyStoreGetters.ALL_COMPANIES) companies!: Company[];

  public loading: boolean = false;

  public handleCompanyCreate(
    { company }: { company: Company, address: Address },
  ): void {
    const params: ParametersMap = {
      createCompany: {
        include: ['address.country'],
      },
    };

    this.loading = true;
    const companyRepository = new CompanyRepository(params);
    companyRepository.create({ company })
      .then((response: AxiosResponse<CompanyJsonApiTranslated>) => {
        this[CompanyStoreMutations.SET_ACTIVE_COMPANY](response.data);
        this.$toasted.show('Company created successfully');

        this.$router.push({
          name: 'companyDashboard',
          params: { companyId: response.data.id },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
