enum UserStoreActions {
  POST_REGISTER_USER = 'postRegisterUser',
  POST_LOGIN_USER = 'postLoginUser',
  GET_LIST_USERS = 'getListUsers',
  GET_ME = 'getMe',
  DELETE_USER = 'DELETE_USER',
  EDIT_USER = 'EDIT_USER',
  INITIALIZE_NECESSARY_DATA = 'INITIALIZE_NECESSARY_DATA'
}

export default UserStoreActions;
