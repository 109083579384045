import axiosJsonApi from '@/plugins/axios/jsonApi';
import CompanyStoreActions from '@/constants/store/modules/company/actions';
import CompanyStoreState from '@/typings/storeStates/company';
import Company from '@/typings/nativeEntities/company/company';
import { AxiosResponse } from 'axios';
import CompanyStoreMutations from '@/constants/store/modules/company/mutations';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import CompanyJsonApiTranslated from '@/typings/backend/jsonApiTranslations/company';
import Address from '@/typings/backend/jsonApiTranslations/address';
import RootStoreState from '@/typings/storeStates/rootState';

export default {
  async [CompanyStoreActions.POST_NEW_COMPANY](
    { commit, state, rootState }: {
      commit: Function, state: CompanyStoreState, rootState: RootStoreState
    },
    { company, params, address }: {
      company: Company, address: Address, params: JsonApiConfiguration
    },
  ): Promise<AxiosResponse> {
    const payload = {
      data: {
        type: 'companies',
        attributes: {
          name: company.name,
          'personal-identification-number': company.personalIdentificationNumber,
          email: company.email,
        },
        relationships: {
          owner: {
            data: {
              type: 'users',
              id: rootState.users.user.id,
            },
          },
          address: {
            data: {
              type: 'addresses',
              id: address.id,
            },
          },
        },
      },
    };

    return axiosJsonApi
      .post('companies', payload, { params })
      .then((response: AxiosResponse<CompanyJsonApiTranslated>) => {
        commit(
          CompanyStoreMutations.SET_COMPANIES,
          [...state.companies, response.data],
        );

        return response;
      });
  },

  async [CompanyStoreActions.GET_COMPANIES](
    { commit, state }: { commit: Function, state: CompanyStoreState },
    { params }: { params: JsonApiConfiguration },
  ): Promise<AxiosResponse> {
    return axiosJsonApi.get('companies', { params })
      .then((response: AxiosResponse) => {
        const companies: CompanyJsonApiTranslated[] = response.data;

        commit(CompanyStoreMutations.SET_COMPANIES, companies);

        const company: Company | null = response.data.length > 0
          ? response.data[0]
          : null;

        commit(CompanyStoreMutations.SET_ACTIVE_COMPANY, company);

        return response;
      });
  },

  async [CompanyStoreActions.EDIT_COMPANY](
    { commit, state }: { commit: Function, state: CompanyStoreState },
    { params, company }: { params: JsonApiConfiguration, company: CompanyJsonApiTranslated },
  ): Promise<AxiosResponse> {
    const payload = {
      data: {
        type: 'companies',
        id: company.id,
        attributes: {
          name: company.name,
          'personal-identification-number': company.personalIdentificationNumber,
          email: company.email,
        },
      },
    };

    return axiosJsonApi
      .patch(`companies/${company.id}`, payload, { params })
      .then((response: AxiosResponse) => {
        const companies = [...state.companies];

        companies.splice(
          companies.findIndex(company_ => company_.id === company.id),
          1,
          response.data,
        );

        commit(CompanyStoreMutations.SET_COMPANIES, companies);

        return response;
      });
  },
};
